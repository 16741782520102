import React, {Component} from 'react'
import {Form, Select, Button} from 'semantic-ui-react';
import axios from 'axios';

class CreateCourse extends Component{
    state = {
        facilitator: '',
        facilitatorId: '',
        courseDate: '',
        courseMonth: '',
        courseYear: '',
    }


    addCourse = () => {
        const sitesUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course'
        const startTime = new Date(this.state.courseYear, parseInt(this.state.courseMonth) - 1, this.state.courseDate)
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        const payload = {
            siteId: this.props.currentSite,
            courseStartDate: startTime.getTime(),
            prettyStartDate: `${this.state.courseMonth}-${this.state.courseDate}-${this.state.courseYear}`,
            facilitator: `${this.props.facilitators[this.state.facilitator].FirstName} ${this.props.facilitators[this.state.facilitator].LastName}`,
            facilitatorId: this.props.facilitators[this.state.facilitator].Id
        }
        axios.put(sitesUrl, payload, {headers: headers}).then( (res) => {
            this.props.closeModal();
        });
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    render(){
        const list = this.props.facilitators.sort( (a,b) => {
            if (a.FirstName.trim().toUpperCase() < b.FirstName.trim().toUpperCase()) {
                return -1
            } else if (a.FirstName.trim().toUpperCase() > b.FirstName.trim().toUpperCase()) {
                return 1
            } else {
                return 0
            }
        }).map( (entry, index) => {
            const facilitator = {
                value: index,
                text: `${entry.FirstName} ${entry.LastName}`
            }
            return facilitator;
        }) 
        const formValid = this.state.courseDate && this.state.courseMonth && this.state.courseYear && (this.state.facilitator >= 0)
        return (
            <Form onSubmit={this.addCourse}>
                <Form.Field>
                    <label>Choose Facilitator</label>
                    <Select options={list} name="facilitator" onChange={this.handleChange}/>
                </Form.Field>
                <Form.Group>
                    <Form.Field>
                        <label>Session 1 Start Date</label>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Month" name="courseMonth" value={this.state.courseMonth} placeholder="mm" onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Day" name="courseDate" value={this.state.courseDate} placeholder="dd" onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Year" name="courseYear" value={this.state.courseYear} placeholder="yyyy" onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button type="submit" content="Add Program" disabled={!formValid}/>
                </Form.Field>
            </Form>
        )
    }
}

export default CreateCourse