import React, {Component} from 'react'
import {Form, Button, Select} from 'semantic-ui-react';
import axios from 'axios';
import StateList from './StateList';

class UpdateOrg extends Component{
    state = {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipcode: '',
        email: '',
        name: '',
        tel: '',
        website: '',
        loading: true
    }

    updateOrg = () => {
        const orgUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organization/${this.props.orgId}`;
        const payload = {
            name: this.state.name,
            email: this.state.email,
            website: this.state.website,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode,
            tel: this.state.tel
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.put(orgUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal();
        }).catch( e => console.log(e));
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    getOrganization = (orgId) => {
        const orgUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organization/${orgId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        axios.get(orgUrl, config).then( res => {
            const orgDetail = res.data.Item;
            this.setState({
                addressLine1: orgDetail.addressLine1 || '',
                addressLine2: orgDetail.addressLine2 || '',
                city: orgDetail.city || '',
                name: orgDetail.name || '',
                state: orgDetail.state || '',
                zipcode: orgDetail.zipcode || '',
                website: orgDetail.website || '',
                email: orgDetail.email || '',
                tel: orgDetail.tel || '',
                loading: false
            })
        })
    }
    componentDidMount(){
        this.getOrganization(this.props.orgId);
    }
    render(){
        return (
            <Form onSubmit={this.updateOrg}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Organization Name (required)" name="name" value={this.state.name} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Email Address" name="email" value={this.state.email} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Website" name="website" value={this.state.website} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Telephone Number" name="tel" value={this.state.tel} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 1" name="addressLine1" value={this.state.addressLine1} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 2" name="addressLine2" value={this.state.addressLine2} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="City" name="city" value={this.state.city} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>State</label>
                        <Select options={StateList} fluid name="state" value={this.state.state} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Zipcode" name="zipcode" value={this.state.zipcode} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button type="submit" content="Update Organization" disabled={!this.state.name}/>
                </Form.Field>
            </Form>
        )
    }
}

export default UpdateOrg