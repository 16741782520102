import React, {Component} from 'react';
import {Form, Header, Button} from 'semantic-ui-react';

class MultipleChoiceMultipleAnswerQuestion extends Component {
    state = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    };

    componentDidUpdate(prevProps){
        if (this.props.questionNumber !== prevProps.questionNumber){
            this.setState({0: false, 1: false, 2: false, 3: false, 4: false});
        }
    }
    submitHandler = (event) => {
        event.preventDefault();
        let answerArray = []
        for (let key in this.state) {
            if (this.state[key]) {
                answerArray.push(key)
            }
        }

        this.props.answerQuestion(this.props.id, answerArray.join(','));
    }

    buttonHandler = (index, event) => {
        event.target.blur();
        this.setState({[index]: !this.state[index]});
    }


    render(){
        const formValidator = Object.keys(this.state).reduce((prev, current) => {
            if ( this.state[current] ) {
                return prev + 1
            } else {
                return prev
            }
        }, 0)
        return(
        <Form size="big" onSubmit={this.submitHandler}>
        <Header as="h3" className="question-prompt">{this.props.language === 'en' ? 'Question ' : 'Pregunta '}{this.props.questionNumber}. &nbsp;{this.props.prompt[this.props.language]}</Header>
        {
            this.props.responses.map( (response, index) => {
                return (
                    <Form.Field key={index}>
                        <Form.Button basic type="button" active={this.state[index]} fluid size="large" onClick={(e) => this.buttonHandler(index, e)} content={response.response[this.props.language]}/>
                    </Form.Field>
                )
            })
        }
        <Form.Field>
            <Button basic primary fluid size="large" content={this.props.language === 'en' ? 'Submit Answers' : 'Enviar Respuestas'} disabled={!formValidator}/>
        </Form.Field>
      </Form>
        )
    }
}

export default MultipleChoiceMultipleAnswerQuestion;