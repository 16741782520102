const preSurvey = {
    "testId": "PreSurveyV4",
    "questions": [
        {
            "id": "V3Q1",
            "type": "multipleChoice",
            "section": "Parenting",
            "prompt": {
                "en": "A child's learning starts:",
                "es": "El aprendizaje de un niño empieza:"
            },
            "responses": [
                {
                    "response": {
                        "en": "prenatally",
                        "es": "durante el período prenatal"
                    }
                },
                {
                    "response": {
                        "en": "during her/his first year",
                        "es": "durante su primer año de vida"
                    }
                },
                {"response": {
                    "en": "when she/he starts talking",
                    "es": "cuando empieza a hablar"}
                },
                {"response": {
                    "en": "when she/he goes to preschool",
                    "es": "cuando entra a preescolar"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}
                }
            ]
        },
        {
            "id": "V3Q2",
            "type": "multipleChoice",
            "section": "Parenting",
            "prompt": {
                "en": "Parents can improve their child's school success by:",
                "es": "Los padres pueden mejorar el éxito escolar de sus hijos al:"
            },
            "responses": [
                {"response": {
                    "en": "getting involved in their child's school",
                    "es": "involucrarse en la escuela de su hijo"}
                },
                {"response": {
                    "en": "talking regularly with their child's teacher",
                    "es": "hablar regularmente con el maestro de su hijo"
                }},
                {"response": {
                    "en": "reading books with their child everyday",
                    "es": "leerle diariamente a sus hijos"}},
                {"response": {
                    "en": "all the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q3",
            "type": "multipleChoice",
            "section": "Parenting",
            "prompt": {
                "en": "In order to make your hopes and dreams a reality for your children:",
                "es": "Para hacer realidad sus esperanzas y sueños para sus hijos usted:"
            },
            "responses": [
                {"response": {
                    "en": "you need to establish clear goals and action plans",
                    "es": "debe establecer objetivos y planes de acción claros"}},
                {"response": {
                    "en": "you should let your children's school show them the way",
                    "es": "debe dejar que la escuela de sus hijos les muestre el camino"}
                },
                {"response": {
                    "en": "you should let them figure it out by themselves",
                    "es": "debe dejar que ellos lo definan solos por su cuenta"}},
                {"response": {
                    "en": "you should wait until they are in high school to figure out what is next",
                    "es": "debe esperar hasta que estén en la escuela secundaria para averiguar el camino a seguir"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q4",
            "type": "trueOrFalse",
            "section": "Parenting",
            "prompt": {
                "en": "Once children get into elementary school, the \"opportunity gap\" disappears and those who started school behind their peers catch up.",
                "es": "Una vez que los niños entran a la escuela primaria, la “brecha de oportunidades” desaparece y los que comenzaron la escuela con retraso en comparación con sus compañeros se ponen al día."
            }
        },
        {
            "id": "V3Q5",
            "type": "multipleChoice",
            "section": "Early learning",
            "prompt": {
                "en": "Which period is most important for your child's brain development?",
                "es": "¿Cuál de los siguientes períodos es el más importante para el desarrollo del cerebro de su hijo?"
            },
            "responses": [
                {"response": {
                    "en": "prenatal to age 3",
                    "es": "prenatal a 3 años"}},
                {"response": {
                    "en": "preschool (age 3 to 5)",
                    "es": "preescolar (de 3 a 5 años)"
                }},
                {"response": {
                    "en": "kindergarten (age 5 to 6)",
                    "es": "kínder (de 5 a 6 años)"}},
                {"response": {
                    "en": "first grade and on (starting at age 6)",
                    "es": "primer grado en adelante (comenzando a los 6 años)"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q6",
            "type": "multipleChoice",
            "section": "Early learning",
            "prompt": {
                "en": "Which area is most important in your child's development?",
                "es": "¿Cuál de las siguientes áreas es la más importante en el desarrollo de su hijo?"
            },
            "responses": [
                {"response": {
                    "en": "physical",
                    "es": "desarrollo físico"}},
                {"response": {
                    "en": "cognitive",
                    "es": "desarrollo cognitivo"}},
                {"response": {
                    "en": "socioemotional",
                    "es": "desarrollo socioemocional"}},
                {"response": {
                    "en": "all the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q7",
            "type": "multipleChoice",
            "section": "Early learning",
            "prompt": {
                "en": "What is the most prominent consequence of toxic stress on young children's development?",
                "es": "¿Cuál es la consecuencia más importante del estrés tóxico en el desarrollo de niños pequeños?"
            },
            "responses": [
                {"response": {
                    "en": "it makes them more vulnerable to illness",
                    "es": "hace que las personas sean más vulnerables a enfermarse"}},
                {"response": {
                    "en": "it starts to break down connections in the brain",
                    "es": "rompe las conexiones a nivel cerebral"}},
                {"response": {
                    "en": "it affects sleeping patterns",
                    "es": "afecta los patrones de sueño"}},
                {"response": {
                    "en": "it does not have any consequences",
                    "es": "no tiene ninguna consecuencia"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q8",
            "type": "trueOrFalse",
            "section": "Early learning",
            "prompt": {
                "en": "Children usually can say \"mama\" and \"papa\" or \"dada\" when they are 12 months old.",
                "es": "Los niños usualmente pueden decir “mamá”, “papá” o “dada” cuando tienen 12 meses."
            }
        },
        {
            "id": "V3Q9",
            "type": "multipleChoice",
            "section": "Language and literacy development",
            "prompt": {
                "en": "Parents can promote their baby's language skills by:",
                "es": "los padres pueden promover el desarrollo del lenguaje de su hijo al:"
            },
            "responses": [
                {"response": {
                    "en": "singing lullabies and songs",
                    "es": "cantarle nanas y canciones"}},
                {"response": {
                    "en": "reciting poems and telling them jokes or sayings",
                    "es": "recitarle poemas y contarle chistes o dichos"}},
                {"response": {
                    "en": "reading or telling stories to him/her",
                    "es": "leer o contarle historias"}},
                {"response": {
                    "en": "talking about family memories",
                    "es": "hablarle de recuerdos familiares"}},
                {"response": {
                    "en": "all of the above",
                    "es": "todas las anteriores"}}
            ]
        },
        {
            "id": "V3Q10",
            "type": "multipleChoice",
            "section": "Language and literacy development",
            "prompt": {
                "en": "I think that a child who uses two languages:",
                "es": "Un niño que usa dos idiomas:"
            },
            "responses": [
                {"response": {
                    "en": "will get confused",
                    "es": "se confundirá"}},
                {"response": {
                    "en": "will have educational and economic advantages",
                    "es": "tendrá ventajas educativas y económicas"}},
                {"response": {
                    "en": "will be unable to communicate fluently in both of them",
                    "es": "no podrá comunicarse con fluidez en ambos idiomas"}},
                {"response": {
                    "en": "will have a hard time learning in school",
                    "es": "tendrá dificultades para aprender en la escuela"
                }},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q11",
            "type": "multipleChoice",
            "section": "Language and literacy development",
            "prompt": {
                "en": "Reading to my child will:",
                "es": "Leerle a mi hijo:"
            },
            "responses": [
                {"response": {
                    "en": "help him/her become a better reader in the future",
                    "es": "le ayudará a convertirse en un mejor lector en el futuro"
                }},
                {"response": {
                    "en": "be most helpful once my child learns to read",
                    "es": "le será más útil una vez que mi hijo aprenda a leer"}},
                {"response": {
                    "en": "only help if I can read in English",
                    "es": "sólo sirve si puedo leer en inglés"}},
                {"response": {
                    "en": "only help if I am an expert reader",
                    "es": "sólo sirve si soy un buen lector"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q12",
            "type": "trueOrFalse",
            "section": "Language and literacy development",
            "prompt": {
                "en": "Children who learn their home language well will have an easier time learning a second language.",
                "es": "Los niños que aprenden bien su lengua materna tendrán más facilidades para aprender un segundo idioma."
            }
        },
        {
            "id": "V3Q13",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Nutrition and physical activity",
            "prompt": {
                "en": "A possible consequence of children spending too much time in front of the TV, tablet, or phone screen is: (check all that apply)",
                "es": "¿Cuál de las siguientes es una consecuencia de que los niños pasen demasiado tiempo frente al televisor, la computadora, o el teléfono celular? (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "a higher risk for the child to be overweight or obese",
                    "es": "aumenta el riesgo de que el niño tenga sobrepeso u obesidad"}},
                {"response": {
                    "en": "greater difficulties with falling asleep at bedtime",
                    "es": "dificulta la conciliación del sueño antes de acostarse"}},
                {"response": {
                    "en": "better socialization skills",
                    "es": "mejora las habilidades de socialización"}},
                {"response": {
                    "en": "fewer words learned",
                    "es": "disminuye la cantidad de palabras aprendidas"}},
                {"response": {
                    "en": "a decrease in future cognitive test scores",
                    "es": "disminuye los puntajes de pruebas cognitivas futuras"}}
            ]
        },
        {
            "id": "V3Q14",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Nutrition and physical activity",
            "prompt": {
                "en": "To help my child grow up and be healthy, it is important to: (check all that apply)",
                "es": "Para ayudar a mi hijo a crecer y a mantenerse saludable, es importante: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "model healthy diet and exercise habits",
                    "es": "modelar hábitos saludables en términos de dieta y ejercicio"}},
                {"response": {
                    "en": "provide her/him with juice to drink throughout the day",
                    "es": "proporcionar al niño jugos para beber durante el día"}},
                {"response": {
                    "en": "feed her/him 4 to 6 servings of fruit and vegetables per day",
                    "es": "proporcionar al niño 4 a 6 porciones de frutas y vegetales por día"}},
                {"response": {
                    "en": "limit the amount of sugar she/he eats",
                    "es": "limitar la cantidad de azúcar que el niño ingiere"}},
                {"response": {
                    "en": "make sure she/he brushes her/his teeth twice a day",
                    "es": "asegurarse de que el niño se cepille sus dientes dos veces al día"}}
            ]
        },
        {
            "id": "V3Q15",
            "type": "multipleChoice",
            "section": "Nutrition and physical activity",
            "prompt": {
                "en": "Which of the following statements about playtime is TRUE?",
                "es": "¿Cuál de las siguientes afirmaciones sobre el tiempo de juego es VERDADERA?"
            },
            "responses": [
                {"response": {
                    "en": "it supports children's learning",
                    "es": "favorece el aprendizaje de los niños"}},
                {"response": {
                    "en": "it supports children's motor development",
                    "es": "favorece el desarrollo motor de los niños"}},
                {"response": {
                    "en": "it can prevent chronic diseases",
                    "es": "puede prevenir enfermedades crónicas"}},
                {"response": {
                    "en": "all of the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q16",
            "type": "trueOrFalse",
            "section": "Nutrition and physical activity",
            "prompt": {
                "en": "If a child is healthy, she/he should not get vaccinated.",
                "es": "Si el niño está sano, no debería vacunarse."
            }
        },
        {
            "id": "V3Q17",
            "type": "multipleChoice",
            "section": "Socioemotional development",
            "prompt": {
                "en": "Developing positive socioemotional skills includes learning to:",
                "es": "El desarrollo positivo de las habilidades socioemocionales del niño incluye aprender a:"
            },
            "responses": [
                {"response": {
                    "en": "hide your feelings well",
                    "es": "ocultar las emociones"}},
                {"response": {
                    "en": "express your thoughts and feelings constructively",
                    "es": "expresar pensamientos y sentimientos constructivamente"}},
                {"response": {
                    "en": "express only your positive feelings",
                    "es": "expresar solo los sentimientos positivos"}},
                {"response": {
                    "en": "express your feelings through behaviors (and not words)",
                    "es": "usar solo emociones para expresar los sentimientos"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q18",
            "type": "multipleChoice",
            "section": "Socioemotional development",
            "prompt": {
                "en": "How can you help your child express and regulate her/his thoughts and feelings effectively?",
                "es": "¿Cómo puede ayudar a su hijo a expresar y regular sus pensamientos y sentimientos de manera efectiva?"
            },
            "responses": [
                {"response": {
                    "en": "teaching my child to suppress emotions",
                    "es": "enseñándole a suprimir sus emociones"}},
                {"response": {
                    "en": "ignoring my child",
                    "es": "ignorándolo"}},
                {"response": {
                    "en": "punishing my child",
                    "es": "castigándolo"}},
                {"response": {
                    "en": "listening, and validating my child's emotions",
                    "es": "escuchando y validando sus emociones"}},
                {"response": {
                    "en": "I don't know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q19",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Socioemotional development",
            "prompt": {
                "en": "Which of the following is a sign of your child’s being the victim of bullying? (check all that apply)",
                "es": "¿Cuál de los siguientes es un signo de que su hijo es víctima de bullying? (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "complaining of stomachaches or headaches before going to daycare or preschool",
                    "es": "se queja de dolor de estómago o de cabeza antes de ir a la guardería o al jardín de infantes"}},
                {"response": {
                    "en": "becoming excited about going to daycare or preschool",
                    "es": "se muestra entusiasmado por ir a la guardería o a al jardín de infantes"}},
                {"response": {
                    "en": "complaining of another child “bothering” or \"bugging\" her/him, or \"being mean\"",
                    "es": "se queja de que otro niño lo \"molesta\" o \"es malo\""}},
                {"response": {
                    "en": "saying things like: \"I’m stupid\" or \"No one likes me\"",
                    "es": "dice cosas como: \"soy estúpido\" o \"no le caigo bien a nadie\""}},
                {"response": {
                    "en": "becoming withdrawn, depressed, fearful or clingy",
                    "es": "se ha vuelto retraído, temeroso, triste, y le cuesta separarse de sus padres"}}
            ]
        },
        {
            "id": "V3Q20",
            "type": "trueOrFalse",
            "section": "Socioemotional development",
            "prompt": {
                "en": "My socioemotional health affects the socioemotional development of my child.",
                "es": "Mi salud socioemocional afecta el desarrollo socioemocional de mi hijo."
            }
        },
        {
            "id": "V3Q21",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Use of technology",
            "prompt": {
                "en": "Parents can support their child's technology experience by: (check all that apply)",
                "es": "Los padres pueden apoyar la experiencia tecnológica de sus hijos: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "letting them explore freely, without supervision",
                    "es": "permitiéndoles explorar libremente, sin supervisión"}},
                {"response": {
                    "en": "providing a balance of online and offline activities",
                    "es": "equilibrando el tiempo que pasan realizando actividades en línea y fuera de línea"}},
                {"response": {
                    "en": "exposing them to educational apps",
                    "es": "mostrándoles aplicaciones educativas"}},
                {"response": {
                    "en": "co-engaging with them during screen time",
                    "es": "interactuando con ellos cuando están frente a una pantalla"}},
                {"response": {
                    "en": "prohibiting the use of all technological devices for children between 4 and 16 years of age",
                    "es": "prohibiendo el uso de todo tipo de dispositivos tecnológicos a niños entre los 4 y los 16 años de edad"}}
            ]
        },
        {
            "id": "V3Q22",
            "type": "multipleChoice",
            "section": "Use of technology",
            "prompt": {
                "en": "Good practices for children’s use of technology include:",
                "es": "Buenas prácticas en relación al uso de tecnología por parte de los niños son:"
            },
            "responses": [
                {"response": {
                    "en": "not having TVs, videogames, and other devices in the child’s room",
                    "es": "no tener televisores, videojuegos y otros dispositivos tecnológicos en la habitación del niño"}},
                {"response": {
                    "en": "turning off the TV when is not being used",
                    "es": "apagar el televisor cuando no se está utilizando"}},
                {"response": {
                    "en": "avoiding screen exposure one hour before bed time",
                    "es": "evitar la exposición a pantallas una hora antes de acostarse"}},
                {"response": {
                    "en": "all of the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don’t know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q23",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Use of technology",
            "prompt": {
                "en": "What should you do when choosing shows and apps with your child? (check all that apply)",
                "es": "¿Qué acciones debe realizar al momento de elegir un programa de televisión o una aplicación con su hijo? (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "pick those that promote conversations with your child",
                    "es": "elegir aquellos programas o aplicaciones que promuevan conversaciones con su hijo"}},
                {"response": {
                    "en": "choose this activity for no more than one hour per day",
                    "es": "limitar esta actividad a máximo una hora por día"}},
                {"response": {
                    "en": "choose interactive shows (e.g. Sesame Street)",
                    "es": "elegir programas interactivos (por ejemplo, Plaza Sésamo)"}},
                {"response": {
                    "en": "let your child watch what she/he likes best",
                    "es": "dejar que su hijo vea lo que más le gusta"}},
                {"response": {
                    "en": "avoid violent content for children age 7 and younger",
                    "es": "evitar exponer a contenidos violentos a niños menores de 7 años"}}
            ]
        },
        {
            "id": "V4Q24",
            "type": "trueOrFalse",
            "section": "Use of technology",
            "prompt": {
                "en": "Video-chatting with family members is the only recommended use of media for children younger than 18 months.",
                "es": "Hablar por videollamada con miembros de la familia es el único uso de los medios de comunicación recomendado para niños menores de 18 meses."
            }
        },
        {
            "id": "V3Q25",
            "type": "multipleChoice",
            "section": "Mathematical development",
            "prompt": {
                "en": "A child starts to develop mathematical abilities:",
                "es": "Un niño comienza a desarrollar habilidades matemáticas:"
            },
            "responses": [
                {"response": {
                    "en": "at birth",
                    "es": "al nacer"
                }},
                {"response": {
                    "en": "when she/he starts talking",
                    "es": "cuando comienza a hablar"}},
                {"response": {
                    "en": "when she/he starts counting",
                    "es": "cuando comienza a contar"}},
                {"response": {
                    "en": "when she/he starts formal education (e.g. preschool or kindergarten)",
                    "es": "cuando comienza su educación formal (por ejemplo, en preescolar o en kínder)"}},
                {"response": {
                    "en": "I don’t know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q26",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Mathematical development",
            "prompt": {
                "en": "Parents can support their child's development of mathematical skills by: (check all that apply)",
                "es": "Los padres pueden apoyar el desarrollo de habilidades matemáticas en sus hijos: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "describing how many eyes, fingers, and toes she/he has",
                    "es": "contándole cuántos ojos, dedos y pies tiene"}},
                {"response": {
                    "en": "teaching her/him concepts like \"more\", \"less\", and \"equal\"",
                    "es": "enseñándole conceptos como “más”, “menos” e “igual”"}},
                {"response": {
                    "en": "inviting her/him to do free drawing",
                    "es": "invitándole a realizar dibujo libre"}},
                {"response": {
                    "en": "exposing her/him to the world of numbers, patterns, colors, shapes, sizes, and weights",
                    "es": "exponiéndole al mundo de los números, patrones, colores, formas, tamaños y pesos"}},
                {"response": {
                    "en": "prompting her/him to do exercises for gross motor development such as running or jumping",
                    "es": "incitándole a hacer ejercicios para el desarrollo motor grueso, como correr o saltar"}}
            ]
        },
        {
            "id": "V3Q27",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Mathematical development",
            "prompt": {
                "en": "Which of the following are essential math skills: (check all that apply)",
                "es": "¿Cuáles de las siguientes son habilidades matemáticas esenciales que todos los niños deben desarrollar? (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "numbers (counting, using one-to-one correlation, and comparing the number of objects)",
                    "es": "uso de números (contar, usar correlaciones uno a uno, comparar número de objetos)"}},
                {"response": {
                    "en": "measurement (small, large, etc.)",
                    "es": "uso de medidas (pequeño, grande)"}},
                {"response": {
                    "en": "spatial sense (learning about direction and location)",
                    "es": "orientación espacial (dirección y ubicación)"}},
                {"response": {
                    "en": "geometry (recognizing shapes)",
                    "es": "geometría (reconocimiento de formas)"}},
                {"response": {
                    "en": "understanding sequence, patterns, colors, and textures",
                    "es": "comprensión de secuencia, patrones, colores y texturas"}}
            ]
        },
        {
            "id": "V3Q28",
            "type": "trueOrFalse",
            "section": "Mathematical development",
            "prompt": {
                "en": "Math is not necessary for most jobs in life.",
                "es": "Las matemáticas no son necesarias para la mayoría de los puestos de trabajo."
            }
        },
        {
            "id": "V3Q29",
            "type": "multipleChoiceMultipleAnswer",
            "section": "School readiness",
            "prompt": {
                "en": "I think my child's opportunities to do well in school improve if I: (check all that apply)",
                "es": "Creo que las oportunidades de que a mi hijo le vaya bien en la escuela aumentan si yo: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "am involved in her/his education from the start",
                    "es": "estoy involucrado en su educación desde el principio"}},
                {"response": {
                    "en": "communicate regularly with her/his teachers",
                    "es": "me comunico regularmente con sus profesores"}},
                {"response": {
                    "en": "promote reading activities at home",
                    "es": "promuevo actividades de lectura en nuestra casa"}},
                {"response": {
                    "en": "do not get involved, since English is not my first language",
                    "es": "no participo en su educación, ya que el inglés no es mi primer idioma"}},
                {"response": {
                    "en": "only speak to my child in English",
                    "es": "solo le hablo en inglés a mi hijo"}}
            ]
        },
        {
            "id": "V3Q30",
            "type": "multipleChoiceMultipleAnswer",
            "section": "School readiness",
            "prompt": {
                "en": "I think that strong parental involvement in my young child’s pre-/school includes: (check all that apply)",
                "es": "Creo que una participación sólida en el preescolar o en el colegio de mi hijo incluye: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "knowing about my child’s homework and making sure it gets done",
                    "es": "saber sobre las tareas de mi hijo y asegurarse de que las haga"}},
                {"response": {
                    "en": "knowing what is being learned in class and checking on my child’s progress",
                    "es": "saber qué está aprendiendo mi hijo en clase y verificar que esté progresando"}},
                {"response": {
                    "en": "reviewing my child’s progress report or report card",
                    "es": "revisar su reporte de progreso o boleta de calificaciones"}},
                {"response": {
                    "en": "developing a plan with the teacher to help my child improve in any needed area",
                    "es": "desarrollar un plan con el maestro para ayudar a mi hijo a mejorar en áreas que necesite ayuda"}},
                {"response": {
                    "en": "going to pre-/school meetings",
                    "es": "ir a las reuniones escolares"}}
            ]
        },
        {
            "id": "V3Q31",
            "type": "multipleChoice",
            "section": "School readiness",
            "prompt": {
                "en": "I think that getting children ready for kindergarten includes her/his learning:",
                "es": "Creo que la preparación para entrar a kínder incluye aprender a:"
            },
            "responses": [
                {"response": {
                    "en": "to identify letters and sounds",
                    "es": "identificar letras y sonidos"}},
                {"response": {
                    "en": "to work and play with others",
                    "es": "trabajar y jugar con otros"}},
                {"response": {
                    "en": "daily eating, bathroom, and going-to-bed routines",
                    "es": "seguir rutinas diarias de comida, baño y sueño"}},
                {"response": {
                    "en": "all of the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don’t know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V4Q32",
            "type": "multipleChoiceMultipleAnswer",
            "section": "School readiness",
            "prompt": {
                "en": "Children that attend quality preschool programs are LESS likely to: (check all that apply)",
                "es": "Los niños que asisten a programas preescolares de calidad tienen MENOS probabilidades de: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "repeat a grade level",
                    "es": "repetir un nivel de año escolar"
                }},
                {"response": {
                    "en": "go to college",
                    "es": "ir a la universidad"
                }},
                {"response": {
                    "en": "dropout of high school",
                    "es": "abandonar la escuela secundaria"
                }},
                {"response": {
                    "en": "need special help",
                    "es": "necesitar ayuda especial"
                }},
                {"response": {
                    "en": "engage in delinquent activity",
                    "es": "participar en actividades delictivas"
                }}
            ]
        },
        {
            "id": "V3Q33",
            "type": "multipleChoice",
            "section": "Advocacy",
            "prompt": {
                "en": "Which of the following is your right as a parent in the U.S?",
                "es": "¿Cuál de las siguientes opciones refleja su(s) derecho(s) como padre en los EE.UU.?"
            },
            "responses": [
                {"response": {
                    "en": "the right to review your child’s records",
                    "es": "el derecho de revisar los registros de su hijo"}},
                {"response": {
                    "en": "the right to be involved in decision making at your child’s school",
                    "es": "el derecho a participar en la toma de decisiones en la escuela de su hijo"}},
                {"response": {
                    "en": "the right to visit your child’s classroom",
                    "es": "el derecho a visitar el aula de su hijo"}},
                {"response": {
                    "en": "all of the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don’t know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V3Q34",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Advocacy",
            "prompt": {
                "en": "Which of the following practices can help you advocate for yourself and your family: (check all that apply)",
                "es": "¿Cuál de las siguientes prácticas pueden ayudarlo a abogar por usted y su familia? (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "stay calm and be persistent",
                    "es": "mantener la calma y ser persistente"}},
                {"response": {
                    "en": "be demanding, and yell if it is necessary to convey your ideas",
                    "es": "ser exigente y gritar, si es necesario, para transmitir sus ideas"}},
                {"response": {
                    "en": "keep the meeting focused on the needs of your child and possible solutions to address them",
                    "es": "mantener la reunión enfocada en las necesidades de su hijo y las posibles soluciones para satisfacerlas"}},
                {"response": {
                    "en": "quickly accept the situation if you are told that there is nothing that can be done",
                    "es": "aceptar rápidamente la situación si le dicen que no se puede hacer nada"}},
                {"response": {
                    "en": "find out more about the problem, rules, and laws—and how the system works",
                    "es": "descubrir más sobre el problema, las regulaciones y las leyes, y sobre cómo funciona el sistema"}}
            ]
        },
        {
            "id": "V3Q35",
            "type": "multipleChoice",
            "section": "Advocacy",
            "prompt": {
                "en": "When informed parents take action and advocate for solutions, they:",
                "es": "Cuando los padres que se encuentran bien informados actúan y abogan por soluciones, ellos:"
            },
            "responses": [
                {"response": {
                    "en": "are automatically designated as troublemakers",
                    "es": "son automáticamente designados como alborotadores"}},
                {"response": {
                    "en": "make themselves ineligible for services like housing or food support",
                    "es": "se vuelven inelegibles para servicios como vivienda o ayuda alimentaria"}},
                {"response": {
                    "en": "help schools, agencies, and community programs improve their service delivery",
                    "es": "ayudan a las escuelas, agencias y programas comunitarios a mejorar su prestación de servicios"}},
                {"response": {
                    "en": "all the above",
                    "es": "todas las anteriores"}},
                {"response": {
                    "en": "I don’t know",
                    "es": "no lo sé"}}
            ]
        },
        {
            "id": "V4Q36",
            "type": "multipleChoiceMultipleAnswer",
            "section": "Advocacy",
            "prompt": {
                "en": "If you need to speak to your child’s school in a language other than English: (check all that apply)",
                "es": "Si necesita hablar con la escuela de su hijo en un idioma que no sea el inglés: (marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "you need to bring your own interpreter to parent-teacher conferences",
                    "es": "necesita traer su propio intérprete a las conferencias de padres y maestros"
                }},
                {"response": {
                    "en": "you will need to learn English to participate in your child’s school",
                    "es": "tendrá que aprender inglés para participar en la escuela de su hijo"
                }},
                {"response": {
                    "en": "you won’t be able to effectively advocate for your child",
                    "es": "no podrá abogar efectivamente por su hijo"
                }},
                {"response": {
                    "en": "you have the right to request effective language assistance during school meetings",
                    "es": "tiene el derecho a solicitar asistencia efectiva en su idioma durante las reuniones escolares"
                }},
                {"response": {
                    "en": "you won’t be able to file written complaints",
                    "es": "no podrá presentar quejas por escrito"
                }}
            ]
        },
        {
            "id": "V3Q37",
            "type": "multipleChoice",
            "section": "Confidence",
            "prompt": {
                "en": "How confident are you in your parenting skills?",
                "es": "¿Qué tan seguro se siente de sus habilidades parentales?"
            },
            "responses": [
                {"response": {
                    "en": "not confident",
                    "es": "nada seguro"}},
                {"response": {
                    "en": "somewhat confident",
                    "es": "algo seguro"}},
                {"response": {
                    "en": "confident",
                    "es": "seguro"}},
                {"response": {
                    "en": "very confident",
                    "es": "muy seguro"}}
            ]
        },
        {
            "id": "V3Q38",
            "type": "multipleChoice",
            "section": "Confidence",
            "prompt": {
                "en": "How confident are you in your ability to help your child learn your home language?",
                "es": "¿Qué tan seguro se siente de su capacidad de ayudar a su hijo a aprender su idioma nativo?"
            },
            "responses": [
                {"response": {
                    "en": "not confident",
                    "es": "nada seguro"}},
                {"response": {
                    "en": "somewhat confident",
                    "es": "algo seguro"}},
                {"response": {
                    "en": "confident",
                    "es": "seguro"}},
                {"response": {
                    "en": "very confident",
                    "es": "muy seguro"}}
            ]
        },
        {
            "id": "V3Q39",
            "type": "multipleChoice",
            "section": "Confidence",
            "prompt": {
                "en": "How confident are you in your ability to teach your child basic skills to be ready for school—like counting or learning colors or letters?",
                "es": "¿Qué tan seguro se siente de su habilidad de enseñar a su hijo habilidades básicas –como contar, o aprenderse los colores y las letras- para que esté listo para el ingreso a la escuela?"
            },
            "responses": [
                {"response": {
                    "en": "not confident",
                    "es": "nada seguro"}},
                {"response": {
                    "en": "somewhat confident",
                    "es": "algo seguro"}},
                {"response": {
                    "en": "confident",
                    "es": "seguro"}},
                {"response": {
                    "en": "very confident",
                    "es": "muy seguro"}}
            ]
        },
        {
            "id": "V3Q40",
            "type": "multipleChoice",
            "section": "Confidence",
            "prompt": {
                "en": "How confident are you about being an advocate for your child?",
                "es": "¿Qué tan seguro está de su capacidad de abogar por su hijo?"
            },
            "responses": [
                {"response": {
                    "en": "not confident",
                    "es": "nada seguro"}},
                {"response": {
                    "en": "somewhat confident",
                    "es": "algo seguro"}},
                {"response": {
                    "en": "confident",
                    "es": "seguro"}},
                {"response": {
                    "en": "very confident",
                    "es": "muy seguro"}}
            ]
        }
    ]
}

export default preSurvey;