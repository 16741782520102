import React, {Component} from 'react'
import {Form, Button, Select} from 'semantic-ui-react';
import uuid from 'uuid/v4';
import axios from 'axios';
import StateList from './StateList';

class CreateOrg extends Component{
    state = {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipcode: '',
        email: '',
        name: '',
        tel: '',
        website: ''
    }

    addOrg = () => {
        const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations';
        const payload = {
            name: this.state.name,
            email: this.state.email,
            website: this.state.website,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode,
            tel: this.state.tel,
            id: uuid()
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.post(orgUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal();
        }).catch( e => console.log(e));
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    render(){
        return (
            <Form onSubmit={this.addOrg}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Organization Name (required)" name="name" value={this.state.name} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Email Address" name="email" value={this.state.email} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Website" name="website" value={this.state.website} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Telephone Number" name="tel" value={this.state.tel} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 1" name="addressLine1" value={this.state.addressLine1} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 2" name="addressLine2" value={this.state.addressLine2} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="City" name="city" value={this.state.city} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>State</label>
                        <Select options={StateList} fluid name="state" value={this.state.state} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Zipcode" name="zipcode" value={this.state.zipcode} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button type="submit" content="Add Organization" disabled={!this.state.name}/>
                </Form.Field>
            </Form>
        )
    }
}

export default CreateOrg