import React, {Component, Fragment} from 'react';
import {Header, Button, Segment, Form, Message} from 'semantic-ui-react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Logo from './apLogo.svg';

class AdminLogin extends Component {
    state = {
        username: '',
        password: '',
        loginError: false,
        changePassword: false,
        changeSuccess: false,
        newPassword: '',
        newPasswordConfirmation: '',
        forgottenPassword: false,
        confirmPasswordSuccess: false,
        noUsernameError: false
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    setForgottenPassword = () => {
        this.setState({noUsernameError: false, loginError: false, changeSuccess: false, confirmPasswordSuccess: false, forgottenPassword: false});
        if (!this.state.username) {
            this.setState({noUsernameError: true});
        } else {
            this.forgotPassword().then( result => {
            }).catch( e => {
                if (e.code === 'InvalidParameterException') {
                    this.setState({noUsernameError: true});
                }
            });
        }
    }

    confirmPasswordReset = () => {
        const poolData = {
            UserPoolId : 'us-east-1_q9pKicKIe', // Your user pool id here
            ClientId : '2sn1glkrcrem4rvsq41np6g5q' // Your client id here
        }
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : this.state.username,
            Pool : userPool
        };
        var cognitoUser = new CognitoUser(userData);
        const that = this;
        return new Promise( (resolve, reject) => {
            cognitoUser.confirmPassword(this.state.confirmationCode, this.state.newPassword, {
                onSuccess() {
                    return resolve(that.setState({changeSuccess: true, changePassword: false, forgottenPassword: false, loginError: false, noUsernameError: false, password: ''}));
                },
                onFailure(err) {
                    if (err) {
                        console.log(err)
                        return resolve(that.setState({changeSuccess: true, changePassword: false, forgottenPassword: false, loginError: false, noUsernameError: false, password: ''}))
                    } else {
                        return resolve(that.setState({changeSuccess: true, changePassword: false, forgottenPassword: false, loginError: false, noUsernameError: false, password: ''}))
                    }
                }
            });
        })
    }
    
    forgotPassword = () => {
        const poolData = {
            UserPoolId : 'us-east-1_q9pKicKIe', // Your user pool id here
            ClientId : '2sn1glkrcrem4rvsq41np6g5q' // Your client id here
        }
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : this.state.username,
            Pool : userPool
        };
        var cognitoUser = new CognitoUser(userData);
        return new Promise( (resolve,reject) => {
            cognitoUser.forgotPassword({
                onSuccess: resolve(this.setState({changePassword: true, forgottenPassword: true, password: ''})),
                onFailure: function(err) {
                    return reject(err);
                }
            });
        })
    }
    login = () => {
        var authenticationData = {
            Username : this.state.username,
            Password : this.state.password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        const poolData = {
            UserPoolId : 'us-east-1_q9pKicKIe', // Your user pool id here
            ClientId : '2sn1glkrcrem4rvsq41np6g5q' // Your client id here
        }
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : this.state.username,
            Pool : userPool
        };
        var cognitoUser = new CognitoUser(userData);
        new Promise( (resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    var idToken = result.getIdToken().getJwtToken();
                    const tokens = {
                        idToken: idToken
                    }
    
                    const claims = {
                        role: result.idToken.payload['custom:role'],
                        org: result.idToken.payload['custom:org'],
                        name: result.idToken.payload.given_name + ' ' + result.idToken.payload.family_name,
                        id: result.idToken.payload['cognito:username']
                    }
                    return resolve({tokens, claims})
                },
                onFailure: (err) => {
                    console.log(err)
                    if (err.name == "PasswordResetRequiredException") {
                        return resolve(
                            this.setState({
                                changePassword: true,
                                loginError: false,
                                forgottenPassword: true,
                                password: ''
                            })
                        )
                    } else {
                        return reject(err) 
                    }
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    delete userAttributes.email_verified;
                    delete userAttributes['custom:org'];
                    delete userAttributes['custom:role'];
    
                    if (this.state.newPassword) {
                        cognitoUser.completeNewPasswordChallenge(this.state.newPassword, userAttributes, {
                            onSuccess: resolve(this.setState({
                                changePassword: false,
                                changeSuccess: true,
                                loginError: false,
                                password: ''
                            })),
                            onFailure: (err) => {
                                console.log(err)
                                resolve(this.setState({
                                    loginError: true,
                                    changeSuccess: false,
                                    password: ''
                                }))
                            }
                        })
                    } else {
                        return resolve(
                            this.setState({
                                changePassword: true,
                                loginError: false
                            })
                        )
                    }
                }
            })
        }).then( res => {
            return this.props.logIn(res.tokens, res.claims);
        }).catch( e => {
            if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                this.setState({
                    loginError: true,
                    noUsernameError: false
                })
            } else  {
                console.log(e)

            }
        })
    }
    render(){
        return (
            <Segment raised className="survey-registration-container">
                <img src={Logo}></img>
                <Header as="h3">Login to Admin Dashboard</Header>
                <Form size="large" onSubmit={this.login}>
                    <Message visible={this.state.loginError} header="Login Error" content="Incorrect Username or Password" error/>
                    <Form.Field>
                        <label>Username</label>
                        <Form.Input value={this.state.username} onChange={this.handleChange} type="text" name="username"/>
                    </Form.Field>
                    <Message visible={this.state.changeSuccess} header="Password Successfully Changed" content="Please login with your updated password" success/>
                    <Message visible={this.state.forgottenPassword} header="Confirmation Code Emailed" content="Please use the confirmation code that was just emailed to you to choose a new password" success/>
                    <Message visible={this.state.noUsernameError} header="Error" content="Please enter a username" error/>
                    {
                        this.state.forgottenPassword ?
                        <Form.Field>
                            <label>Confirmation Code</label>
                            <Form.Input value={this.state.confirmationCode} name="confirmationCode" onChange={this.handleChange} type="text"/>
                        </Form.Field> :
                        null
                    }
                    {
                        !this.state.changePassword ?
                            <Form.Field>
                                <label>Password</label>
                                <Form.Input value={this.state.password} onChange={this.handleChange} type="password" name="password"/>
                            </Form.Field> :
                            <Fragment>
                                <Form.Field>
                                    <label>Pick a New Password</label>
                                    <Form.Input value={this.state.newPassword} onChange={this.handleChange} type="password" name="newPassword"/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Confirm New Password</label>
                                    <Form.Input value={this.state.newPasswordConfirmation} onChange={this.handleChange} type="password" name="newPasswordConfirmation"/>
                                </Form.Field>
                            </Fragment> 
                    }
                    <Form.Field>
                        {
                            !this.state.forgottenPassword ?
                            <Button primary type="submit" content="Login" disabled={!this.state.username && !this.state.password && this.state.newPassword === this.state.newPasswordConfirmation}/> :
                            <Button primary type="button" onClick={this.confirmPasswordReset} content="Update Password" disabled={(this.state.newPassword != this.state.newPasswordConfirmation) && this.state.confirmationCode}/>
                        }
                    </Form.Field>
                    <Form.Field>
                        {
                        this.state.forgottenPassword ? 
                        null :
                        <a onClick={this.setForgottenPassword}>Forgot Password?</a>
                        }
                    </Form.Field>
                </Form>
            </Segment>
        )
    }
}


export default AdminLogin