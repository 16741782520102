import React, {Component, Fragment} from 'react';
import {Card, Button, Modal,Dimmer, Loader, Icon, Header, Form, Checkbox, Table} from 'semantic-ui-react';
import axios from 'axios';
import uuid from 'uuid/v4'

const previewOrganizations = ['3e8af657-91d7-4c96-8156-c1b44b71bac4',
'18b75f2a-0ea0-46c9-9508-294067202fa2',
'aa303936-d0cc-4989-8592-f57d4a3a5a1d',
'2b4056fa-6c66-439c-9b86-f2b66bb75412',
'04cd6ecd-80e2-43ac-a122-dc8aa036243b',
'88336b5d-48df-483b-980b-d7f1bb2dab50',
'77fdc65f-4cae-495c-8a29-e15a389c58e3',
'ef6e5e17-fd6b-4010-ab04-9ec1b3128056',
'a078504e-ac3c-4639-b479-1009cdcacd0b'
]

class ProgramDashboard extends Component{
    state = {
        courses: [],
        courseList: [],
        sortedCourses: {},
        showManageAttendance: false,
        showManageStudents: false,
        showEditStudent: false,
        selectedProgramIndex: -1,
        selectedSite: -1,
        selectedProgram: '',
        students: {},
        student: {},
        selectedStudent: {},
        firstName: '',
        lastName: ''
    }

    toggleShowAttendance = () => {
        this.setState({showManageAttendance: !this.state.showManageAttendance})
    }

    toggleShowManageStudents = () => {
        this.setState({showManageStudents: !this.state.showManageStudents})
    }

    toggleShowEditStudent = () => {
        this.setState({showEditStudent: !this.state.showEditStudent})
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    handleStudentEdit = (event) => {
        let prevState = {...this.state.selectedStudent}
        prevState[event.target.name] = event.target.value

        this.setState({selectedStudent: prevState})
    }

    handleNameChange = (event, key) => {
        let prevState = {...this.state.students}
        if (event.target.name === 'firstName') {
            prevState[key].firstName = event.target.value
        } else {
            prevState[key].lastName = event.target.value

        }
        this.setState({prevState})
    }
    handleAttendanceCheck = (key, session) => {
        let prevState = {...this.state.students}
        // prevState[key].attendance[session] = !prevState.students[key].attendance[session]
        let array = prevState[key].attendance && prevState[key].attendance.length > 0 ? prevState[key].attendance : new Array(10)
        prevState[key].attendance = array
        prevState[key].attendance[session] = !prevState[key].attendance[session]
        this.setState({prevState})
    }

    handleAttendanceSave = () => {
        this.updateAttendance().then( res => {
            this.setState({showManageAttendance: !this.state.showManageAttendance})
        }).catch( e => console.log(e))
    }

    getStudents = (siteId, course) => {
        const courseId = siteId + '~' + course.FacilitatorId + '~' + course.CourseStartDate
        const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course/students/${courseId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        return axios.get(siteUrl, config)
    }

    editStudent = (student, key) => {
        if (student) {
            student.id = key
            this.setState({selectedStudent: student, showEditStudent: true})
        } else {
            this.setState({selectedStudent: {}, showEditStudent: true})
        }
    }
    saveStudent = (deleted) => {

        let students = {...this.state.students}

        let student = {...this.state.selectedStudent}
        if (!student.id) {
            student.id = uuid()
        }

        students[student.id] = {
            firstName: student.firstName,
            lastName: student.lastName,
            email: student.email,
            phone: student.phone,
            attendance: student.attendance ? [] : student.attendance,
            deleted: deleted ? true : false
        }

        const courseId = this.state.selectedProgram
        const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course/students/${courseId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        return axios.put(siteUrl, students, config).then(() => {
            const courseId = this.state.selectedProgram;
            const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course/students/${courseId}`
            const config = {
                headers: {
                    "Authorization": `Bearer ${this.props.token}`
                }
            }
    
            return axios.get(siteUrl, config)
        }).then( res => {
            const students = res.data
    
            this.setState({
                students: students,
                showEditStudent: !this.state.showEditStudent,
                showManageStudents: !this.state.showManageStudents
            })

        }).catch( e => {
            this.setState({
                students: {},
                showEditStudent: !this.state.showEditStudent,
                showManageStudents: !this.state.showManageStudents
            })
        })
    }

    updateAttendance = () => {
        const courseId = this.state.selectedProgram
        const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course/students/${courseId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        return axios.put(siteUrl, this.state.students, config)
    }

    setForStudents = (siteId, course) => {
        const courseId = siteId + '~' + course.FacilitatorId + '~' + course.CourseStartDate

        this.getStudents(siteId, course).then( response => {
            const students = response.data
    
            this.setState({
                selectedProgram: courseId,
                students: students,
                showManageStudents: true
            })

        }).catch( e => {
            this.setState({
                selectedProgram: courseId,
                students: {},
                showManageStudents: true
            })
        })
    }

    setForAttendance = (siteId, course) => {
        const courseId = siteId + '~' + course.FacilitatorId + '~' + course.CourseStartDate

        this.getStudents(siteId, course).then( response => {
            const students = response.data
    
            this.setState({
                selectedProgram: courseId,
                students: students,
                showManageAttendance: true
            })

        }).catch( e => {
            this.setState({
                selectedProgram: courseId,
                students: {},
                showAddStudents: true
            })
        })
    }
    
    addAStudent = () => {
        const courseId = this.state.selectedProgram
        const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/course/students/${courseId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        const payload = {
            id: uuid(),
            firstName: this.state.firstName,
            lastName: this.state.lastName
        }

        axios.post(siteUrl, payload, config).finally( () => {
            
            return axios.get(siteUrl, config).then( response => {
                const students = response.data
                
                this.setState({
                    selectedProgram: courseId,
                    students: students
                })
            })
        })
    }

    fetchCourses = () => {
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        axios.get(siteUrl, config).then( (response) => {
            const sites = response.data.Items
            for (let i in sites) {
                for (let n in sites[i].Courses) {
                    sites[i].Courses[n].SiteName = sites[i].SiteName;
                    sites[i].Courses[n].SiteId = sites[i].SiteId;
                }
            }

            const courses = sites.reduce( (previous, site) => {
                return previous.concat(site.Courses)
            }, [])
            const courseList = courses.map( (course, index) => {
                const courseItem = {
                    index: index,
                    text: `${course.SiteName} on ${course.PrettyStartDate}`,
                    value: `${course.SiteId}${course.FacilitatorId}${course.CourseStartDate}`
                }
                return courseItem;
            })

            let sortedCourses = {}

            for (let course in courses) {
                if (sortedCourses[courses[course].SiteId]) {
                    sortedCourses[courses[course].SiteId].courses.push(courses[course])
                } else {
                    let courseArray = [];
                    courseArray.push(courses[course])
                    sortedCourses[courses[course].SiteId] = {
                        "siteName": courses[course].SiteName,
                        "courses": courseArray
                    }
                }
            }

            this.setState({
                courses: courses,
                courseList: courseList,
                sortedCourses
            })
        })
    }
    componentDidMount(){
        this.fetchCourses()
    }
    render(){
        return (
            <Fragment>
                <Dimmer active={this.state.loading} page>
                    <Loader/>
                </Dimmer>
                {
                    Object.keys(this.state.sortedCourses).sort( (a,b) => {
                        if (this.state.sortedCourses[a].siteName.trim().toUpperCase() < this.state.sortedCourses[b].siteName.trim().toUpperCase()) {
                            return -1
                        }
                        if (this.state.sortedCourses[a].siteName.trim().toUpperCase() > this.state.sortedCourses[b].siteName.trim().toUpperCase()) {
                            return 1
                        }
                        return 0
                    }).map( (site, siteIndex) => {
                        return (
                            <Fragment>
                                <Header>Programs at {this.state.sortedCourses[site].siteName}</Header>
                                <Card.Group key={`Site${siteIndex}`}>
                                    {
                                        this.state.sortedCourses[site].courses.filter( course => course.deleted !== true).map( (course, index) => {
                                            return (
                                                <Card key={`Course${index}`}>
                                                    <Card.Content>
                                                        <Card.Header>Program Starting on {course.PrettyStartDate}</Card.Header>
                                                    </Card.Content>
                                                    { previewOrganizations.indexOf(this.props.org) > -1 || window.location.search === '?preview=true' ? 
                                                        <Card.Content extra key={index}>
                                                                <Button fluid primary onClick={() => this.setForStudents(site, course)} style={{marginBottom: "10px"}}>Manage Students</Button>
                                                                <Button fluid onClick={() => this.setForAttendance(site, course)}>Manage Attendance</Button>
                                                        </Card.Content>
                                                        : null
                                                    }
                                                </Card>
                                            )
                                        })
                                    }
                                </Card.Group>
                            </Fragment>
                        )
                    })
                }

                    <Modal open={this.state.showManageStudents} onClose={this.toggleShowManageStudents} size="fullscreen" className="studentModal">
                        <Modal.Header content="Manage Students"/>
                        <Modal.Content>
                            <Button icon labelPosition='right' positive onClick={() => this.editStudent()}>Add new Student<Icon name="plus"/></Button>
                                {
                                    Object.keys(this.state.students).filter(key => this.state.students[key].deleted !== true).map( key => {
                                        const student = this.state.students[key]
                                        return (
                                            <Button key={key} icon labelPosition='right' onClick={() => this.editStudent(student, key)} style={{marginBottom: '10px'}}>
                                                {student.firstName} {student.lastName}
                                                <Icon name="edit"/>
                                            </Button>
                                        )
                                    })
                                }       
                        </Modal.Content>
                        <Modal open={this.state.showEditStudent} onClose={this.toggleShowEditStudent}>
                            <Modal.Content>
                                <Form>
                                    <Form.Input label="First name" name="firstName" onChange={this.handleStudentEdit} value={this.state.selectedStudent.firstName} type="text"/>
                                    <Form.Input label="Last name" name="lastName" onChange={this.handleStudentEdit} value={this.state.selectedStudent.lastName} type="text"/>
                                    <Form.Input label="Email" name="email" onChange={this.handleStudentEdit} value={this.state.selectedStudent.email} type="email"/>
                                    <Form.Input label="Mobile Number" name="mobile" onChange={this.handleStudentEdit} value={this.state.selectedStudent.phone} type="text"/>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button icon="save" content="Save" onClick={() => this.saveStudent()}/>
                                <Button icon="delete" content="Delete" onClick={() => this.saveStudent(true)}/>
                            </Modal.Actions>
                        </Modal>
                    </Modal>
                    <Modal open={this.state.showManageAttendance} onClose={this.toggleShowAttendance} size="fullscreen" className="studentModal">
                        <Modal.Header content={`Take Attendance`}/>
                        <Modal.Content>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>First Name</Table.HeaderCell>
                                        <Table.HeaderCell>Last Name</Table.HeaderCell>
                                        <Table.HeaderCell>Session 1</Table.HeaderCell>
                                        <Table.HeaderCell>Session 2</Table.HeaderCell>
                                        <Table.HeaderCell>Session 3</Table.HeaderCell>
                                        <Table.HeaderCell>Session 4</Table.HeaderCell>
                                        <Table.HeaderCell>Session 5</Table.HeaderCell>
                                        <Table.HeaderCell>Session 6</Table.HeaderCell>
                                        <Table.HeaderCell>Session 7</Table.HeaderCell>
                                        <Table.HeaderCell>Session 8</Table.HeaderCell>
                                        <Table.HeaderCell>Session 9</Table.HeaderCell>
                                        <Table.HeaderCell>Session 10</Table.HeaderCell>
                                        <Table.HeaderCell>Total Attendance</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {
                                    Object.keys(this.state.students).filter(key => this.state.students[key].deleted !== true).map( key => {
                                        const student = this.state.students[key]
                                        return (
                                            <Table.Row key={key}>
                                                <Table.Cell>{student.firstName}</Table.Cell>
                                                <Table.Cell>{student.lastName}</Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 0)} checked={student.attendance && student.attendance[0]} value={student.attendance && student.attendance[0]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 1)} value={student.attendance && student.attendance[1]} checked={student.attendance && student.attendance[1]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 2)} value={student.attendance && student.attendance[2]} checked={student.attendance && student.attendance[2]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 3)} value={student.attendance && student.attendance[3]} checked={student.attendance && student.attendance[3]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 4)} value={student.attendance && student.attendance[4]} checked={student.attendance && student.attendance[4]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 5)} value={student.attendance && student.attendance[5]} checked={student.attendance && student.attendance[5]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 6)} value={student.attendance && student.attendance[6]} checked={student.attendance && student.attendance[6]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 7)} value={student.attendance && student.attendance[7]} checked={student.attendance && student.attendance[7]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 8)} value={student.attendance && student.attendance[8]} checked={student.attendance && student.attendance[8]}/></Table.Cell>
                                                <Table.Cell textAlign="center"><Checkbox onChange={() => this.handleAttendanceCheck(key, 9)} value={student.attendance && student.attendance[9]} checked={student.attendance && student.attendance[9]}/></Table.Cell>
                                                <Table.Cell textAlign="center">{ student.attendance ? student.attendance.reduce( (acc, current) => { if (current) { return acc + 1} else { return acc}}, 0) : 0}</Table.Cell>
                                            </Table.Row>
                                        )

                                    })                                
                                }

                                {   this.state.students && Object.keys(this.state.students).length > 0 ?
                                <Table.Row>
                                    <Table.Cell colSpan={2}>Attendance Totals</Table.Cell>
                                    <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[0]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                    <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[1]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                    <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[2]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[3]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[4]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[5]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[6]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[7]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[8]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                                                        <Table.Cell textAlign="center"> 
                                        {
                                        Object.values(this.state.students).reduce( (acc, student) => {
                                        const day = student.attendance && student.attendance[9]
                                        if (student.deleted == true) {
                                            return acc
                                        }
                                        if (day) {
                                            return acc + 1
                                        } else {
                                            return acc
                                        }
                                    }, 0) || 0} </Table.Cell>
                                    <Table.Cell></Table.Cell>
                                </Table.Row>
                                : null
                                }
                                {

                                this.state.students && Object.keys(this.state.students).length < 1 ?
                                <Table.Row>
                                    <Table.Cell colSpan={13} textAlign="center">Please add students</Table.Cell>
                                    </Table.Row> : null
                                }
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                        <Modal.Content extra>
                            <Form>
                                <Form.Group inline>
                                    <Form.Field>
                                        <Button primary onClick={this.handleAttendanceSave}>Save Attendance</Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button positive type="button" onClick={this.toggleShowAttendance}>Cancel</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                    </Modal>
            </Fragment>
        )
    }
}

export default ProgramDashboard;