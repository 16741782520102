import React, {Component} from 'react';
import {Container, Header, Button, Segment, Form} from 'semantic-ui-react';
import AdminLogin from './AdminLogin';
import AdminDash from './AdminDash';
import axios from 'axios';

class AdminDashboard extends Component{
    state = {
        showCohortReport: false,
        parentingScores: {},
        earlyLearningScores: {},
        languageDevelopmentScores: {},
        loggedIn: false,
        idToken: '',
        role: '',
        id: '',
        org: '',
        name: '',
        orgName: '',
        pTill: 0
    }


    logIn = (tokens, claims) => {
        const InfoUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/account'
        const config = {
            headers: {
                "Authorization": `Bearer ${tokens.idToken}`
            }
        }
        axios.get(InfoUrl, config).then( res => {
            this.setState({
                loggedIn: true,
                idToken: tokens.idToken,
                role: claims.role,
                name: claims.name,
                id: claims.id,
                org: claims.org,
                orgName: res.data.Item.name,
                pTill: res.data.Item.pTill ? res.data.Item.pTill : 0
            })

        }).catch( (e) => {
            console.log(e)
        })

    }

    render() {
        return (
                !this.state.loggedIn ?
                <AdminLogin logIn={this.logIn}/> :
                <AdminDash token={this.state.idToken} name={this.state.name} role={this.state.role} org={this.state.org} orgName={this.state.orgName} pTill={this.state.pTill}/>
        )
    }
}

export default AdminDashboard;