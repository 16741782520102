const postSurvey = {
    "testId": "PostSurveyV3",
    "questions": [
        {
            "id": "V3Q41",
            "type": "multipleChoicePlusOther",
            "section": "Your Background",
            "prompt": {
                "en": "What is your primary home language?",
                "es": "¿Cuál es su idioma nativo primario?"
            },
            "responses": [
                {"response": {
                    "en": "English",
                    "es": "Inglés"}},
                {"response": {
                    "en": "Spanish",
                    "es": "Español"}},
                {"response": {
                    "en": "Chinese",
                    "es": "Chino"}},
                {"response": {
                    "en": "Tagalog",
                    "es": "Tagalo"}},
                {"response": {
                    "en": "Vietnamese",
                    "es": "Vietnamita"}},
                {"response": {
                    "en": "Arabic",
                    "es": "Árabe"}},
                {"response": {
                    "en": "French",
                    "es": "Francés"}},
                {"response": {
                    "en": "Korean",
                    "es": "Coreano"}}
            ]
        },
                {
            "id": "V3Q42",
            "type": "multipleChoicePlusOther",
            "section": "Your Background",
            "prompt": {
                "en": "Which of the following best represents your race/ethnicity? (check all that apply)",
                "es": "¿Cuál de las siguientes opciones representa mejor su herencia racial o étnica? (Por favor, marque todas las que correspondan)"
            },
            "responses": [
                {"response": {
                    "en": "Non-Hispanic White or Euro-American",
                    "es": "Blanco no Hispánico o Euroamericano"}},
                {"response": {
                    "en": "Black, Afro-Caribbean, or African American",
                    "es": "Negro, Afrocaribeño, o Afroamericano"}},
                {"response": {
                    "en": "Latino or Hispanic American",
                    "es": "Latino o Hispanoamericano"}},
                {"response": {
                    "en": "East Asian or Asian American",
                    "es": "Asiático del Este o Asiático-Americano"}},
                {"response": {
                    "en": "South Asian or Indian American",
                    "es": "Sud-asiático o Indio-Americano"}},
                {"response": {
                    "en": "Middle Eastern or Arab American",
                    "es": "del Medio Oriente o Árabe-Americano"}},
                {"response": {
                    "en": "Native American or Alaskan Native",
                    "es": "Nativo Americano o Nativo de Alaska"}}
            ]
        },
                {
            "id": "V3Q43",
            "type": "multipleChoice",
            "section": "Your Background",
            "prompt": {
                "en": "How many years did you go to school? (please check one)",
                "es": "¿Cuál es su nivel educacional? (Por favor, marque solo una respuesta)"
            },
            "responses": [
                {"response": {
                    "en": "completed grade school or less",
                    "es": "completé la escuela primaria o menos"}},
                {"response": {
                    "en": "some high school",
                    "es": "algo de preparatoria"}},
                {"response": {
                    "en": "high school graduate/GED or more",
                    "es": "completé la preparatoria o más"}}
            ]
        }
    ]
}

export default postSurvey;