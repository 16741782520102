import React, {Component, Fragment} from 'react';
import {Segment, Form, Header, Select, Button, Message, Checkbox, Icon, Modal, Container} from 'semantic-ui-react';
import StateList from './StateList';
import Logo from './apLogo.svg';

import axios from 'axios';

const emptyMessage = "All fields are required";
const facilitatorMessage = "Could not find a facilitor with this name. Please try agian";

class Registration extends Component {

    state = {
        state: '',
        site: '',
        facilitator: '',
        facilitatorId: '',
        dobMonth: '',
        dobDate: '',
        dobYear: '',
        courseStartDate: '',
        acceptConditions: false,
        sites: [],
        siteList: [],
        validationError: false,
        clientSideError: false,
        errorMessage: '',
        submitAttempted: false,
        registrationStep: 0,
        currentCourseSet: [],
        language: 'en',
        consentModal: false
    }

    componentDidMount(){
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/site'
        axios.get(siteUrl).then( (response) => {
            const items = JSON.parse(response.data.body).Items;
            const siteList = items.map((site, index) => {
                let siteObject = {}
                siteObject.key = index;
                siteObject.value = site.SiteId;
                siteObject.text = site.SiteName;
                siteObject.state = site.StateCode;
                siteObject.courses = site.Courses;
                return siteObject;
            }).filter( site => site.courses && site.courses.length > 0);

            const sites = items.map((site, index) => {
                let siteObject = {}
                siteObject.key = index;
                siteObject.value = site.SiteId;
                siteObject.text = site.SiteName;
                siteObject.courses = site.Courses && site.Courses.length && site.Courses.filter( (course) => {
                    return course.deleted != true;
                }).map( course => { return {prettyStartDate: course.PrettyStartDate, courseStartDate: course.CourseStartDate}});
                siteObject.state = site.StateCode;
                return siteObject;
            }).filter( site => site.courses && site.courses.length > 0).sort((a,b) => {
                if (a.text.trim().toUpperCase() < b.text.trim().toUpperCase()) {
                    return -1
                }
                if (a.text.trim().toUpperCase() > b.text.trim().toUpperCase()) {
                    return 1
                }
                return 0
            });
            this.setState({sites: sites, siteList: siteList});
        })
    }

    toggleConsentModal = () => {
        this.setState({
            consentModal: !this.state.consentModal
        })
    }

    filterByState = (stateCode) => {
        const filteredList = this.state.sites.filter(site => site.state === stateCode)
        this.setState({
            siteList: filteredList
        })
    }

    fieldsFull = () => {
        if (this.state.state.length && 
            this.state.facilitator.length &&
            this.state.site &&
            this.state.dobDate &&
            this.state.dobMonth &&
            this.state.dobYear &&
            this.state.courseDate &&
            this.state.courseMonth &&
            this.state.courseYear &&
            this.state.acceptConditions) {
                this.setState({validationError: '', clientSideError: false});
                return true;
            } else {
                this.setState({validationError: 'empty', errorMessage: emptyMessage, clientSideError: true});
                return false;
            }
    }
    validateForm = () => {
        if (!this.state.acceptConditions) {
            this.setState({validationError: 'conditions', clientSideError: true});
            return false;
        } else {
            this.fieldsFull();
        }
        
    }

    validateRegistration = () => {
        const validationUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/register';
        const registrationUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/registration';
        const month = this.state.dobMonth.length < 2 ? '0' + this.state.dobMonth : this.state.dobMonth;
        const day = this.state.dobDate.length < 2 ? '0' + this.state.dobDate : this.state.dobDate;

        if (this.state.registrationStep === 2) {
            const payload = {
                siteId: this.state.site,
                courseStart: this.state.courseStartDate,
                birthday: `${month}-${day}-${this.state.dobYear}`,
                facilitatorId: this.state.facilitatorId
            }
            axios.post(registrationUrl, payload).then( (res) => {
                if (res.data.survey === 'no record') {
                    this.props.register(this.state.language, false, payload);
                } else if (res.data.survey === 'found'){
                    this.props.register(this.state.language, true, payload);
                } else if ( res.data.survey === 'complete'){
                    this.props.register(this.state.language, true, payload, true)
                }
            });
        } else {
            const payload = {
                siteId: this.state.site,
                courseStartDate: this.state.courseStartDate,
                facilitator: this.state.facilitator
            }
            axios.post(validationUrl, payload).then( res => {
                if (res.data.valid) {
                    this.proceed(res.data.id);
                } else {
                    this.setState({
                        errorMessage: 'Can not find this combination',
                        validationError: true,
                        submitAttempted: true
                    })
                }
            })
        }
    }
    registerSurvey = (event) => {
        event.preventDefault();
        this.setState({submitAttempted: true})
        this.validateForm()
        if (this.state.validationError) {
        } else {
            const registrationUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/registration';
            const month = this.state.dobMonth.length < 2 ? '0' + this.state.dobMonth : this.state.dobMonth;
            const day = this.state.dobDate.length < 2 ? '0' + this.state.dobDate : this.state.dobDate;

            const payload = {
                siteId: this.state.site,
                courseStart: this.state.courseStartDate,
                birthday: `${month}-${day}-${this.state.dobYear}`,
                facilitatorId: this.state.facilitatorId
            }
            axios.post(registrationUrl, payload).then( (res) => {
                if (res.data.error === 'faciliator not found') {
                    this.setState({validationError: 'facilitator', errorMessage: facilitatorMessage});
                } else if (res.data.survey === 'no record') {
                    this.props.register(this.state.language, false, payload);
                } else if (res.data.survey === 'found'){
                    this.props.register(this.state.language, true, payload);
                } else if ( res.data.survey === 'complete'){
                    this.props.register(this.state.language, true, payload, true)
                }
            });
        }
    }

    setLanguage = (language) => {
        this.setState({
            language: language
        })
        this.proceed();
    }

    proceed = (id) => {
        this.setState({
            registrationStep: this.state.registrationStep + 1,
            facilitatorId: id
        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
        if (this.state.submitAttempted) {
            this.setState({
                validationError: false
            })
        }
    }

    handleStateChange = (event, {name, value}) => {
        this.filterByState(value);
    }

    handleSiteChange = (event, {name, value}) => {
        let courseSet = [];
        for (let i in this.state.sites) {
            if (this.state.sites[i].value === value) {
                courseSet = this.state.sites[i].courses;
            }
        }
        if (this.state.submitAttempted) {
            this.fieldsFull();
        }
        if (courseSet) {
            const currentCourseSet = courseSet.map( course => { 
                return {value: course.courseStartDate, text: course.prettyStartDate}
            })
            this.setState({[name]: value, currentCourseSet})
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    handleCheckbox = () => {
        this.setState({acceptConditions: !this.state.acceptConditions});
    }

    registerHome = () => {
        return (
            <Fragment>
                <Form.Field>
                    <Button basic size="huge" className="language-button" content="Proceed in English" onClick={() => this.setLanguage('en')}></Button>
                </Form.Field>
                <Form.Field>
                    <Button basic size="huge" className="language-button" content="Proceda en Español" onClick={() => this.setLanguage('es')}></Button>
                </Form.Field>
                <Form.Field className="right-aligned" onClick={this.props.setAdmin} style={{cursor: 'pointer'}}>
                Administrative Access <Icon name='wrench'></Icon>
                </Form.Field>
            </Fragment>
        )
    }

    registerCourse = () => {
        return (
            <Fragment>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>{this.state.language === 'en' ? 'State' : 'Estado'}</label>
                        <Select placeholder={this.state.language === 'en' ? 'state' : 'estado'}
                        name="state" options={StateList} 
                        onChange={this.handleStateChange}/>
                    </Form.Field>
                <Form.Field>
                    <label>{this.state.language === 'en' ? 'Site' : 'Sitio'}</label>
                    <Select required value={this.state.site} name="site" 
                    placeholder={this.state.language === 'en' ? 'site' : 'sitio'} options={this.state.siteList} 
                    onChange={this.handleSiteChange}/>
                </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>{this.state.language === 'en' ? 'Session 1 Start Date': 'Fecha de Inicio de la Sesión 1'}</label>
                        <Select required name="courseStartDate" value={this.state.courseStartDate} options={this.state.currentCourseSet} onChange={this.handleChange}></Select>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                <Form.Field>
                    <label>{this.state.language === 'en' ? "Facilitator's name" : "Nombre del Facilitador"}</label>
                    <Form.Input required name="facilitator" value={this.state.facilitator} onChange={this.handleChange}/>
                    <Message error 
                    header="Error"
                    content={this.state.errorMessage}
                    />
                </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button basic type="submit" size="huge" content={this.state.language === 'en' ? 'Continue' : 'Continuar'} positive></Button>
                </Form.Field>
            </Fragment>
        )
    }

    validateBirthDate = () => {
        if (this.state.dobMonth && this.state.dobDate && this.state.dobYear && this.state.acceptConditions) {
            return false
        } else {
            return true
        }
    }

    addBirthday = () => {
        return (
            <Fragment>
                <Header as="h3">{this.state.language === 'en' ? 'Please Enter Your Birthdates' : 'Fecha de Nacimiento'}</Header>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label={this.state.language === 'en' ? 'Month' : 'Mes'} name="dobMonth" value={this.state.dobMonth} placeholder="mm" type="number" maxLength="2" max="12" pattern="\d{2}" onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label={this.state.language === 'en' ? 'Day' : 'Día'} name="dobDate" value={this.state.dobDate} placeholder="dd" type="number" maxLength= "2" max="31" pattern="\d{2}" onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label={this.state.language === 'en' ? 'Year' : 'Año'} name="dobYear" value={this.state.dobYear} type="number" maxLength= "4" pattern="\d{4}" placeholder={this.state.language === 'en' ? 'yyyy' : 'aaaa' } onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <a onClick={this.toggleConsentModal}>{this.state.language === 'en' ? 'Notice of Informed Consent' : 'Aviso de Consentimiento Informado'}</a>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field control={Checkbox} name="acceptConditions" 
                    checked={this.state.acceptConditions} onChange={this.handleCheckbox}
                    label={this.state.language === 'en' ? "I understand the consent form and consent to participate in the study" : "Entiendo esta forma y doy mi consentimiento para participar en esta investigación."}
                    error={this.state.validationError === 'conditions'}/>
                </Form.Group>
                <Form.Field>
                    <Message error header="Error" content={this.state.errorMessage}/>
                    <Button type="submit" positive size="large" content={this.state.language === 'en' ? 'Begin Survey' : 'Empezar Encuesta'} disabled={this.validateBirthDate()}></Button>
                </Form.Field>
            </Fragment>
        )
    }

    registrationRouter = () => {
        switch (this.state.registrationStep) {
            case 1:
                return this.registerCourse()
            case 2:
                return this.addBirthday()
            default:
                return this.registerHome()
        }
    }
    render(){
        return (
        <Segment raised className="survey-registration-container">
            <img src={Logo} style={{maxWidth: '100%'}}></img>
            <Header as="h2">{this.state.language === 'en' ? 'Session Survey' : 'Encuesta de la Sesión'}</Header>
            <Form size="large" onSubmit={this.validateRegistration} error={this.state.validationError}>
                {
                    this.registrationRouter()
                }
            </Form>
            <Modal open={this.state.consentModal} onClose={this.toggleConsentModal} closeIcon>
                {
                    this.state.language === 'en' ?
                    <Header>Parent Program Informed Consent Form</Header> :
                    <Header>Consentimiento Informado para Padres para la
                    Evaluación de Abriendo Puertas/Opening Doors</Header>
                }
                <Modal.Content>
                    {
                        this.state.language === 'en' ?

                    <Container text>
                    <p>We would like to invite you to take part in the evaluation of our Abriendo Puertas/Opening Doors Parent program.</p>

<p>Abriendo Puertas is a program co-created by and for parents, so it is essential for us to know about your experiences of, comments on, and suggestions for this 10-session program. The information you provide will help us to improve each local program by making future versions of the program useful and beneficial for all families.</p>

<p>Therefore, we invite you to collaborate in this research by completing two surveys: the first one before the first class (Session 1 Survey), and the second one at the end of the last class (Session 10 Survey). These surveys should not take you more than 20-30 minutes each to complete.</p>

<p>Your participation is entirely voluntary. You do not have to participate in the surveys. Even if you agree to participate, you do not have to answer any questions that may feel private or uncomfortable. Whether or not you participate in this research will not affect your participation in the training program or community now or in the future.</p>

<p>There are no foreseeable risks to you from participating in this research. All of the information that we obtain from you during the research will be kept confidential. We will store all of your information in secure, restricted electronic files. Only the researchers involved in the evaluation will have access to the files. We will not use your name or other identifying information in any reports or research materials.</p>

<p>There is no direct benefit to you, however, we hope that the research will benefit other parents in the community who attend future Abriendo Puertas/Opening Doors programs. There will be no costs to you, other than your time involved.</p>

<p>If you have any questions about this project, you may contact Abriendo Puertas/Opening Doors at info@ap-od.org.</p>

<p>If you are fully aware of your rights, as detailed above, and would like to consent to participate, please click consent box below.</p>
                    </Container> :
                    <Container text>
                    <p>Queremos invitarle a participar en la evaluación del programa de capacitación para padres Abriendo Puertas/Opening Doors.</p>

<p>Abriendo Puertas es un proyecto co-creado por y para padres, por lo que es fundamental para nosotros conocer cómo ha sido la experiencia de quienes han participado y escuchar sus comentarios y sugerencias sobre esta capacitación de 10 sesiones. La información que usted proporcione nos permitirá mejorar el programa local y hacer que las futuras instancias de capacitación sean útiles y beneficiosas para todas las familias.</p>

<p>Por ello, lo invitamos a colaborar en esta investigación a través de completar dos encuestas: la primera antes de la primera clase (Encuesta de la Sesión 1), y la segunda al finalizar las 10 sesiones (Encuesta de la Sesión 10). Estas encuestas  no le tomarán más de 20-30 minutos cada una.</p>

<p>Su colaboración es totalmente voluntaria. No es obligatoria. Incluso si da su consentimiento para participar, no está obligado a contestar aquellas preguntas que le produzca incomodidad o pudor. Su decisión de colaborar o no en esta investigación no afectará su participación en la capacitación o en la comunidad, ni ahora ni en el futuro.</p>

<p>No preveemos ningún riesgo en su participación en esta investigación. Toda la información que usted nos proporcione es confidencial y será guardada en archivos electrónicos seguros y de acceso restringido. Sólo los investigadores involucrados en esta evaluación tendrán acceso a los archivos electrónicos. No usaremos nunca su nombre u otra información personal en reportes sobre la investigación.</p>

<p>No se espera ningún beneficio directo para usted por participar de esta investigación. Sin embargo, esperamos que los resultados obtenidos beneficien a otras familias de la comunidad que participen en el futuro en capacitaciones de Abriendo Puertas/Opening Doors.</p>

<p>Si tiene cualquier pregunta sobre esta investigación, usted puede comunicarse con Abriendo Puertas/Opening Doors (info@ap-od.org).</p>

<p>Si usted está plenamente enterado de sus derechos, que fueron detallados arriba, y desea colaborar, por favor haga clic en el cuadro de consentimiento abajo.</p>
                    </Container>
                    }
                    <Container textAlign="center">
                        <Button onClick={this.toggleConsentModal}>{this.state.language === 'en' ? 'Close' : 'Cerrar'}</Button>
                    </Container>
                </Modal.Content>

            </Modal>
        </Segment>
        )
    }
}

export default Registration;