import React, {Component, Fragment} from 'react';
import {Card, Icon, Header, Button, Modal, Form, Dimmer, Loader, Container, Dropdown} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Moment from 'moment';
import axios from 'axios';
import MaterialTable from "material-table";
import { VictoryGroup, VictoryChart, VictoryBar, VictoryTheme, VictoryAxis} from 'victory'

let CustomTheme = VictoryTheme.material;
CustomTheme.axis.style.tickLabels.fontSize = 4
CustomTheme.axis.style.axisLabel.fontSize = 4
CustomTheme.axis.style.grid.stroke = 'none'

const columns = [
    {field: 'siteName'   , title: 'Site'},
    {field: 'facilitator', title: 'Facilitator'},
    {field: 'startDate'  , title: 'Date', type: 'date'},
    {field: 'preParentScore'  , title: 'Pre Survey Parenting Score', filtering: false},
    {field: 'postParentScore' , title: 'Post Survey Parenting Score', filtering: false},
    {field: 'preEarlyLearningScore'  , title: 'Pre Survey Early Learning Score', filtering: false},
    {field: 'postEarlyLearningScore' , title: 'Post Survey Early Leaning Score', filtering: false},
    {field: 'preLanguageDevelopmentScore'  , title: 'Pre Survey Language Development Score', filtering: false},
    {field: 'postLanguageDevelopmentScore' , title: 'Post Survey Language Development Score', filtering: false},
    {field: 'preNutritionAndPhysicalActivity'  , title: 'Pre Survey Nutrition and Physical Activity Score', filtering: false},
    {field: 'postNutritionAndPhysicalActivity' , title: 'Post Survey Nutrition and Physical Activity Score', filtering: false},
    {field: 'preSocioEmotional'  , title: 'Pre Survey SocioEmotional Development Score', filtering: false},
    {field: 'postSocioEmotional' , title: 'Post Survey SocioEmotional Development Score', filtering: false},
    {field: 'preUseOfTechnology'  , title: 'Pre Survey Use of Technology Score', filtering: false},
    {field: 'postUseOfTechnology' , title: 'Post Survey Use of Technology Score', filtering: false},
    {field: 'preMathematicalDevelopment'  , title: 'Pre Survey Mathematical Development Score', filtering: false},
    {field: 'postMathematicalDevelopment' , title: 'Post Survey Mathematical Development Score', filtering: false},
    {field: 'preSchoolReadiness'  , title: 'Pre Survey School Readiness Score', filtering: false},
    {field: 'postSchoolReadiness' , title: 'Post Survey School Readiness Score', filtering: false},
    {field: 'preAdvocacy'  , title: 'Pre Survey Advocacy Score', filtering: false},
    {field: 'postAdvocacy' , title: 'Post Survey Advocacy Score', filtering: false},
    {field: 'preConfidence'  , title: 'Pre Survey Confidence Score', filtering: false},
    {field: 'postConfidence' , title: 'Post Survey Confidence Score', filtering: false},
]

class AdvancedDashboard extends Component{
    state = {
        loading: true,
        orgName: '',
        reportedSites: {},
        processedScores: [],
        processedCourses: [],
        selectedProgamIndex: -1,
        adminOrgAs: '',
        promptForOrganization: false,
        organizations: [],
        siteQuery: '',
        facilitatorQuery: '',
        dateQuery: [],
        programQueryActive: false,
        customQueryActive: false
    }

    scoreTotals = (scoreArray, preSurveyCount, postSurveyCount) => {
        const Totals = scoreArray.reduce( (acc, current) => {
            const questions = Object.keys(current)
            questions.forEach( question => {
                if (acc[question]) {
                    acc[question] = acc[question] + parseInt(current[question])
                } else {
                    acc[question] = parseInt(current[question])
                }
            })
            return acc
        }, {})

        //Add Cumulative Here

        const PerfectTotals = Object.keys(Totals).reduce( (acc,key) => {
            if( key.includes('pre') && key.includes('Perfect')) {
                acc.prePerfectSectionTotal = acc.prePerfectSectionTotal + parseInt(Totals[key])
            }
            if( key.includes('post') && key.includes('Perfect')) {
                acc.postPerfectSectionTotal = acc.postPerfectSectionTotal + parseInt(Totals[key])
            }

            return acc
        }, {prePerfectSectionTotal: 0, postPerfectSectionTotal: 0})
    
        const averagedScores = Object.keys(Totals).reduce( (acc, key) => {
            if ( key.includes('post') ) {
                acc[key + 'Mean'] = (parseInt(Totals[key]) / postSurveyCount)
                // acc[key + 'Total'] = parseInt(Totals[key])
            } else {
                acc[key + 'Mean'] = (parseInt(Totals[key]) / postSurveyCount)
                // acc[key + 'Total'] = parseInt(Totals[key])
            }
            return acc
        }, {})

        averagedScores.prePerfectPercentageTotal = parseFloat(PerfectTotals.prePerfectSectionTotal / (10 * postSurveyCount)).toFixed(2)
        averagedScores.postPerfectPercentageTotal = parseFloat(PerfectTotals.postPerfectSectionTotal / (10 * postSurveyCount)).toFixed(2)

        const nanScrubbedScores = Object.keys(averagedScores).reduce( (acc, key) => {
                    if ( Number.isNaN( averagedScores[key]) ) {
                        acc[key] = 0
                    } else {
                        acc[key] = parseFloat(averagedScores[key]).toFixed(3)
                    }
            
                    return acc
                }, {} )


        return nanScrubbedScores
    }

    processOrgScores = (adminOrgAs) => {

        
        const condenseSurveyScores = function (scores) {
            const PreParentingQuestions = ['preV3Q1','preV3Q2','preV3Q3','preV3Q4'];
            const PostParentingQuestions = ['postV3Q1','postV3Q2','postV3Q3','postV3Q4'];
            const preEarlyLearningQuestions = ['preV3Q5', 'preV3Q6', 'preV3Q7','preV3Q8'];
            const postEarlyLearningQuestions = ['postV3Q5', 'postV3Q6', 'postV3Q7','postV3Q8'];
            const preLanguageDevelopment= ['preV3Q9','preV3Q10','preV3Q11','preV3Q12'];
            const postLanguageDevelopment= ['postV3Q9','postV3Q10','postV3Q11','postV3Q12'];
            const preNutritionAndPhysicalActivity = ['preV3Q13','preV3Q14','preV3Q15','preV3Q16']
            const postNutritionAndPhysicalActivity = ['postV3Q13','postV3Q14','postV3Q15','postV3Q16']
            const preSocioemotionalDevelopment = ['preV3Q17','preV3Q18','preV3Q19','preV3Q20']
            const postSocioemotionalDevelopment = ['postV3Q17','postV3Q18','postV3Q19','postV3Q20']
            const preUseOfTechnology = ['preV3Q21','preV3Q22','preV3Q23','preV3Q24','preV4Q24']
            const postUseOfTechnology = ['postV3Q21','postV3Q22','postV3Q23','postV3Q24','postV4Q24']
            const preMathematicalDevelopment = ['preV3Q25','preV3Q26','preV3Q27','preV3Q28']
            const postMathematicalDevelopment = ['postV3Q25','postV3Q26','postV3Q27','postV3Q28']
            const preSchoolReadinessAnswerKey = ['preV3Q29','preV3Q30','preV3Q31','preV3Q32','preV4Q32']
            const postSchoolReadinessAnswerKey = ['postV3Q29','postV3Q30','postV3Q31','postV3Q32','postV4Q32']
            const preAdvocacyAnswerKey = ['preV3Q33','preV3Q34','preV3Q35','preV3Q36','preV4Q35', 'preV4Q36']
            const postAdvocacyAnswerKey = ['postV3Q33','postV3Q34','postV3Q35','postV3Q36','postV4Q36']
            const preConfidenceAnswerKey = ['preV3Q37','preV3Q38','preV3Q39','preV3Q40']
            const postConfidenceAnswerKey = ['postV3Q37','postV3Q38','postV3Q39','postV3Q40']
        
            if (scores.length > 0) {
                let condensedScores = scores.map( (current) => {
                    let scoredObject = {
                        preParenting: 0,
                        postParenting: 0,
                        preEarlyLearning: 0,
                        postEarlyLearning: 0,
                        preLanguageDevelopment: 0,
                        postLanguageDevelopment: 0,
                        preNutritionAndPhysicalActivity: 0,
                        postNutritionAndPhysicalActivity: 0,
                        preSocioemotionalDevelopment: 0,
                        postSocioemotionalDevelopment: 0,
                        preUseOfTechnology: 0,
                        postUseOfTechnology: 0,
                        preMathematicalDevelopment: 0,
                        postMathematicalDevelopment: 0,
                        preSchoolReadinessAnswerKey: 0,
                        postSchoolReadinessAnswerKey: 0,
                        preAdvocacyAnswerKey: 0,
                        postAdvocacyAnswerKey: 0,
                        preConfidenceAnswerKey: 0,
                        postConfidenceAnswerKey: 0
                    }
        
                    for (let question in current) {
                        if ( PreParentingQuestions.indexOf(question) > -1 ) {
                            scoredObject.preParenting = scoredObject.preParenting + current[question];
                        } else if ( PostParentingQuestions.indexOf(question) > -1) {
                            scoredObject.postParenting = scoredObject.postParenting + current[question];
                        } else if ( preEarlyLearningQuestions.indexOf(question) > -1 ) {
                            scoredObject.preEarlyLearning = scoredObject.preEarlyLearning + current[question];
                        } else if ( postEarlyLearningQuestions.indexOf(question) > -1 ) {
                            scoredObject.postEarlyLearning = scoredObject.postEarlyLearning + current[question];
                        } else if ( preLanguageDevelopment.indexOf(question) > -1 ) {
                            scoredObject.preLanguageDevelopment = scoredObject.preLanguageDevelopment + current[question];
                        } else if ( postLanguageDevelopment.indexOf(question) > -1 ) {
                            scoredObject.postLanguageDevelopment = scoredObject.postLanguageDevelopment + current[question];
                        } else if ( preNutritionAndPhysicalActivity.indexOf(question) > -1 ) {
                            scoredObject.preNutritionAndPhysicalActivity = scoredObject.preNutritionAndPhysicalActivity + current[question]
                        } else if ( postNutritionAndPhysicalActivity.indexOf(question) > -1 ) {
                            scoredObject.postNutritionAndPhysicalActivity = scoredObject.postNutritionAndPhysicalActivity + current[question]
                        } else if ( preSocioemotionalDevelopment.indexOf(question) > -1 ) {
                            scoredObject.preSocioemotionalDevelopment = scoredObject.preSocioemotionalDevelopment + current[question]
                        } else if ( postSocioemotionalDevelopment.indexOf(question) > -1 ) {
                            scoredObject.postSocioemotionalDevelopment = scoredObject.postSocioemotionalDevelopment + current[question]
                        } else if ( preUseOfTechnology.indexOf(question) > -1 ) {
                            scoredObject.preUseOfTechnology = scoredObject.preUseOfTechnology + current[question]
                        } else if ( postUseOfTechnology.indexOf(question) > -1 ) {
                            scoredObject.postUseOfTechnology = scoredObject.postUseOfTechnology + current[question]
                        } else if ( preMathematicalDevelopment.indexOf(question) > -1 ) {
                            scoredObject.preMathematicalDevelopment = scoredObject.preMathematicalDevelopment + current[question]
                        } else if ( postMathematicalDevelopment.indexOf(question) > -1 ) {
                            scoredObject.postMathematicalDevelopment = scoredObject.postMathematicalDevelopment + current[question]
                        } else if ( preSchoolReadinessAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.preSchoolReadinessAnswerKey = scoredObject.preSchoolReadinessAnswerKey + current[question]
                        } else if ( postSchoolReadinessAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.postSchoolReadinessAnswerKey = scoredObject.postSchoolReadinessAnswerKey + current[question]
                        } else if ( preAdvocacyAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.preAdvocacyAnswerKey = scoredObject.preAdvocacyAnswerKey + current[question]
                        } else if ( postAdvocacyAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.postAdvocacyAnswerKey = scoredObject.postAdvocacyAnswerKey + current[question]
                        } else if ( preConfidenceAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.preConfidenceAnswerKey = scoredObject.preConfidenceAnswerKey + current[question]
                        } else if ( postConfidenceAnswerKey.indexOf(question) > -1 ) {
                            scoredObject.postConfidenceAnswerKey = scoredObject.postConfidenceAnswerKey + current[question]
                        }
                    }
                    return scoredObject
        
                    })
                        
                const enrichedScores = condensedScores.map( row => {
                      //Calculate Perfect scores
                    if (row.preParenting == 4) {
                        row.preParentingPerfect = 1
                    } else {
                        row.preParentingPerfect = 0
                    }
        
                    if (row.postParenting == 4) {
                        row.postParentingPerfect = 1
                    } else {
                        row.postParentingPerfect = 0
                    }
        
                    if (row.preEarlyLearning == 4) {
                        row.preEarlyLearningPerfect = 1
                    } else {
                        row.preEarlyLearningPerfect = 0
                    }
        
                    if (row.postEarlyLearning == 4) {
                        row.postEarlyLearningPerfect = 1
                    } else {
                        row.postEarlyLearningPerfect = 0
                    }
        
                    if (row.preLanguageDevelopment == 4) {
                        row.preLanguageDevelopmentPerfect = 1
                    } else {
                        row.preLanguageDevelopmentPerfect = 0
                    }
        
                    if (row.postLanguageDevelopment == 4) {
                        row.postLanguageDevelopmentPerfect = 1
                    } else {
                        row.postLanguageDevelopmentPerfect = 0
                    }
        
                    if (row.preNutritionAndPhysicalActivity == 4) {
                        row.preNutritionAndPhysicalActivityPerfect = 1
                    } else {
                        row.preNutritionAndPhysicalActivityPerfect = 0
                    }
        
                    if (row.postNutritionAndPhysicalActivity == 4) {
                        row.postNutritionAndPhysicalActivityPerfect = 1
                    } else {
                        row.postNutritionAndPhysicalActivityPerfect = 0
                    }
        
                    if (row.preSocioemotionalDevelopment == 4) {
                        row.preSocioemotionalDevelopmentPerfect = 1
                    } else {
                        row.preSocioemotionalDevelopmentPerfect = 0
                    }
        
                    if (row.postSocioemotionalDevelopment == 4) {
                        row.postSocioemotionalDevelopmentPerfect = 1
                    } else {
                        row.postSocioemotionalDevelopmentPerfect = 0
                    }
        
                    if (row.preUseOfTechnology == 4) {
                        row.preUseOfTechnologyPerfect = 1
                    } else {
                        row.preUseOfTechnologyPerfect = 0
                    }
        
                    if (row.postUseOfTechnology == 4) {
                        row.postUseOfTechnologyPerfect = 1
                    } else {
                        row.postUseOfTechnologyPerfect = 0
                    }
        
                    if (row.preMathematicalDevelopment == 4) {
                        row.preMathematicalDevelopmentPerfect = 1
                    } else {
                        row.preMathematicalDevelopmentPerfect = 0
                    }
        
                    if (row.postMathematicalDevelopment == 4) {
                        row.postMathematicalDevelopmentPerfect = 1
                    } else {
                        row.postMathematicalDevelopmentPerfect = 0
                    }
        
                    if (row.preSchoolReadinessAnswerKey == 4) {
                        row.preSchoolReadinessAnswerKeyPerfect = 1
                    } else {
                        row.preSchoolReadinessAnswerKeyPerfect = 0
                    }
        
                    if (row.postSchoolReadinessAnswerKey == 4) {
                        row.postSchoolReadinessAnswerKeyPerfect = 1
                    } else {
                        row.postSchoolReadinessAnswerKeyPerfect = 0
                    }
        
                    if (row.preAdvocacyAnswerKey == 4) {
                        row.preAdvocacyAnswerKeyPerfect = 1
                    } else {
                        row.preAdvocacyAnswerKeyPerfect = 0
                    }
        
                    if (row.postAdvocacyAnswerKey == 4) {
                        row.postAdvocacyAnswerKeyPerfect = 1
                    } else {
                        row.postAdvocacyAnswerKeyPerfect = 0
                    }
        
                    if (row.preConfidenceAnswerKey == 4) {
                        row.preConfidenceAnswerKeyPerfect = 1
                    } else {
                        row.preConfidenceAnswerKeyPerfect = 0
                    }
        
                    if (row.postConfidenceAnswerKey == 4) {
                        row.postConfidenceAnswerKeyPerfect = 1
                    } else {
                        row.postConfidenceAnswerKeyPerfect = 0
                    }
        
                    return row
                })
        
                return enrichedScores
        
        
            } else {
                return []
            }
        }
        

        const processScores = (reportedSites) => {
            let courses = [];
            for (let id in reportedSites) {
                const siteName = reportedSites[id].name
                for (let course in reportedSites[id].courses) {
                    const FacilitatorName = reportedSites[id].courses[course].FacilitatorName
                    const startDate = reportedSites[id].courses[course].startDate
                    const PreSurveyCount = reportedSites[id].courses[course].preSurveyCount
                    const PostSurveyCount = reportedSites[id].courses[course].postSurveyCount
                
                    const IndividualScores = reportedSites[id].courses[course].scoredSurveys ? condenseSurveyScores(reportedSites[id].courses[course].scoredSurveys) : [];
                    const AverageScores = IndividualScores.length > 0 ? this.scoreTotals(IndividualScores, PreSurveyCount, PostSurveyCount) : [];
                    let row = {
                    Site: siteName,
                    Facilitator: FacilitatorName,
                    StartDate: startDate,
                    PreSurveyCount,
                    PostSurveyCount,
                    Scores: IndividualScores,
                    AverageScores
                    }
                
                courses.push(row)
                }
            }
            
            return courses.filter(value => value.PreSurveyCount > 0)
        }

            const Org = adminOrgAs ? adminOrgAs : this.props.org;
            const advancedURL = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/advanced/${Org}`
            const config = {
                headers: {
                    "Authorization": `Bearer ${this.props.token}`
                }
            }
            axios.get(advancedURL, config).then( res => {
                const processedScores = this.createScoredRows(res.data.sites)
                const processedCourses = processScores(res.data.sites)
    
                
                this.setState({adminOrgAs, loading: false, orgName: res.data.name, reportedSites: res.data.sites, processedScores, processedCourses})
            }).catch( e => console.log(e))
        }

    componentDidMount(){
        
        if (this.props.role === 'admin') {
            const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations'
            const config = {
                headers: {
                    "Authorization": `Bearer ${this.props.token}`
                }
            }
            axios.get(orgUrl, config).then( res => {
                const organizations = res.data.Items.flat(1)
                return this.setState({
                    organizations,
                    loading: false,   
                    promptForOrganization: true 
                })
            })
        } else {
            this.processOrgScores()
        }
    }



    handleProgramSelect = (event, data) => {
        this.setState({selectedProgamIndex: data.value})
    }

    handleProgramQuery = () => {
        this.setState({programQueryActive: true, customQueryActive: false})
    }

    handleCustomQuery = () => {
        this.setState({programQueryActive: false, customQueryActive: true})
    }

    handleSiteSelect = (event, data) => {
        this.setState({siteQuery: data.value})
    }

    handleFacilitatorSelect = (event, data) => {
        this.setState({facilitatorQuery: data.value})
    }

    handleOrgSelect = (event, data) => {
        this.processOrgScores(data.value)
        this.setState({loading: true})
    }

    handleDateChange = (event, data) => {
        if (data.value) {
            this.setState({dateQuery: data.value.map( current => Moment(current).format('x'))})
        } else {
            this.setState({dateQuery: []})
        }
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    renderTableData = () => {
        const currentIndex = this.state.selectedProgamIndex;
        const averages = this.state.processedCourses[currentIndex].AverageScores;
        const row = {
            siteName: this.state.processedCourses[currentIndex].Site,
            facilitator: this.state.processedCourses[currentIndex].Facilitator,
            startDate: this.state.processedCourses[currentIndex].StartDate,
            preParentScore: averages.preParentingMean,
            postParentScore: averages.postParentingMean,
            preEarlyLearningScore: averages.preEarlyLearningMean,
            postEarlyLearningScore: averages.postEarlyLearningMean,
            preLanguageDevelopmentScore: averages.preLanguageDevelopmentMean,
            postLanguageDevelopmentScore: averages.postLanguageDevelopmentMean,
            preNutritionAndPhysicalActivity: averages.preNutritionAndPhysicalActivityMean,
            postNutritionAndPhysicalActivity: averages.postNutritionAndPhysicalActivityMean,
            preSocioEmotional: averages.preSocioemotionalDevelopmentMean,
            postSocioEmotional: averages.postSocioemotionalDevelopmentMean,
            preUseOfTechnology: averages.preUseOfTechnologyMean,
            postUseOfTechnology: averages.postUseOfTechnologyMean,
            preMathematicalDevelopment: averages.preMathematicalDevelopmentMean,
            postMathematicalDevelopment: averages.postMathematicalDevelopmentMean,
            preSchoolReadiness: averages.preSchoolReadinessAnswerKeyMean,
            postSchoolReadiness: averages.postSchoolReadinessAnswerKeyMean,
            preAdvocacy: averages.preAdvocacyAnswerKeyMean,
            postAdvocacy: averages.postAdvocacyAnswerKeyMean,
            preConfidence: averages.preConfidenceAnswerKeyMean,
            postConfidence: averages.postConfidenceAnswerKeyMean
        }
        return [row]
    }

    renderTotalTableData = (custom, chart) => {
        let averages;
        if (custom) {
            const filteredCourses = this.state.processedCourses.filter( course => {
                if (this.state.siteQuery) {
                    console.log('comparing: ', course.Site, this.state.siteQuery )
                    if (this.state.siteQuery === course.Site) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }).filter( course => {
                if (this.state.facilitatorQuery) {
                    if (this.state.facilitatorQuery === course.Facilitator) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }).filter( course => {
                if (this.state.dateQuery & this.state.dateQuery[0]) {
                    if ( parseInt(this.state.dateQuery[0]) < parseInt(course.StartDate) ) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }).filter( course => {
                if (this.state.dateQuery & this.state.dateQuery[1]) {
                    if ( parseInt(this.state.dateQuery[1]) > parseInt(course.StartDate) ) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            })

            const filteredCoursescores = filteredCourses.reduce( (acc, course ) => {
                return acc.concat(course.Scores)
            }, [])
            const filteredCoursePreCount = filteredCourses.reduce( (acc, current) => acc + current.PostSurveyCount, 0);
            const filteredCoursePostCount = filteredCourses.reduce( (acc, current) => acc + current.PostSurveyCount, 0)
            const filteredScore = this.scoreTotals(filteredCoursescores, filteredCoursePreCount, filteredCoursePostCount)
            averages = filteredScore
        } else {
            averages = this.state.processedCourses[this.state.selectedProgamIndex].AverageScores;
        }
        
        let tableData = [];
        if (chart) {
            let preArray = []
            let postArray = []
            preArray.push({x: 'P', y: parseFloat(averages.preParentingMean)})
            preArray.push({x: 'EL', y: parseFloat(averages.preEarlyLearningMean)})
            preArray.push({x: 'LD', y: parseFloat(averages.preLanguageDevelopmentMean)})
            preArray.push({x: 'NPA', y: parseFloat(averages.preNutritionAndPhysicalActivityMean)})
            preArray.push({x: 'SE', y: parseFloat(averages.preSocioemotionalDevelopmentMean)})
            preArray.push({x: 'UoT', y: parseFloat(averages.preUseOfTechnologyMean)})
            preArray.push({x: 'MD', y: parseFloat(averages.preMathematicalDevelopmentMean)})
            preArray.push({x: 'SR', y: parseFloat(averages.preSchoolReadinessAnswerKeyMean)})
            preArray.push({x: 'A', y: parseFloat(averages.preAdvocacyAnswerKeyMean)})
            preArray.push({x: 'C', y: parseFloat(averages.preConfidenceAnswerKeyMean)})

            postArray.push({x: 'P', y: parseFloat(averages.postParentingMean)})
            postArray.push({x: 'EL', y: parseFloat(averages.postEarlyLearningMean)})
            postArray.push({x: 'LD', y: parseFloat(averages.postLanguageDevelopmentMean)})
            postArray.push({x: 'NPA', y: parseFloat(averages.postNutritionAndPhysicalActivityMean)})
            postArray.push({x: 'SE', y: parseFloat(averages.postSocioemotionalDevelopmentMean)})
            postArray.push({x: 'UoT', y: parseFloat(averages.postUseOfTechnologyMean)})
            postArray.push({x: 'MD', y: parseFloat(averages.postMathematicalDevelopmentMean)})
            postArray.push({x: 'SR', y: parseFloat(averages.postSchoolReadinessAnswerKeyMean)})
            postArray.push({x: 'A', y: parseFloat(averages.postAdvocacyAnswerKeyMean)})
            postArray.push({x: 'C', y: parseFloat(averages.postConfidenceAnswerKeyMean)})
            tableData.push(preArray)
            tableData.push(postArray)
        } else {
        const parentRow = {
            category: 'Parenting',
            preMean: averages.preParentingMean,
            // preTotal: averages.preParentingTotal,
            postMean: averages.postParentingMean,
            // postTotal: averages.postParentingTotal,
            prePerfectPercentage: (averages.preParentingPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postParentingPerfectMean * 100).toFixed(2) + '%'
        }
        tableData.push(parentRow);

        const earlyLearningRow = {
            category: 'Early Learning',
            preMean: averages.preEarlyLearningMean,
            // preTotal: averages.preEarlyLearningTotal,
            postMean: averages.postEarlyLearningMean,
            // postTotal: averages.postEarlyLearningTotal,
            prePerfectPercentage: (averages.preEarlyLearningPerfectMean  * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postEarlyLearningPerfectMean * 100).toFixed(2) + '%'
        }
        tableData.push(earlyLearningRow);

        const languageDevelopmentRow = {
            category: 'Language Development',
            preMean: averages.preLanguageDevelopmentMean,
            // preTotal: averages.preLanguageDevelopmentTotal,
            postMean: averages.postLanguageDevelopmentMean,
            // postTotal: averages.postLanguageDevelopmentTotal,
            prePerfectPercentage: (averages.preLanguageDevelopmentPerfectMean  * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postLanguageDevelopmentPerfectMean * 100).toFixed(2) + '%'
        }
        tableData.push(languageDevelopmentRow);

        const nutritionAndPhysicalActivityRow = {
            category: 'Nutrition and Physical Activity',
            preMean: averages.preNutritionAndPhysicalActivityMean,
            // preTotal: averages.preNutritionAndPhysicalActivityTotal,
            postMean: averages.postNutritionAndPhysicalActivityMean,
            // postTotal: averages.postNutritionAndPhysicalActivityTotal,
            prePerfectPercentage: (averages.preNutritionAndPhysicalActivityPerfectMean  * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postNutritionAndPhysicalActivityPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(nutritionAndPhysicalActivityRow)

        const socioEmotionalRow = {
            category: 'SocioEmotional',
            preMean: averages.preSocioemotionalDevelopmentMean,
            // preTotal: averages.preSocioemotionalDevelopmentTotal,
            postMean: averages.postSocioemotionalDevelopmentMean,
            // postTotal: averages.postSocioemotionalDevelopmentTotal,
            prePerfectPercentage: (averages.preSocioemotionalDevelopmentPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postSocioemotionalDevelopmentPerfectMean * 100).toFixed(2)  + '%'
        }
        tableData.push(socioEmotionalRow)

        const useOfTechnologyRow = {
            category: 'Use Of Technology',
            preMean: averages.preUseOfTechnologyMean,
            // preTotal: averages.preUseOfTechnologyTotal,
            postMean: averages.postUseOfTechnologyMean,
            // postTotal: averages.postUseOfTechnologyTotal,
            prePerfectPercentage: (averages.preUseOfTechnologyPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postUseOfTechnologyPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(useOfTechnologyRow)

        const mathematicalDevelopmentRow = {
            category: 'Mathematical Development',
            preMean: averages.preMathematicalDevelopmentMean,
            // preTotal: averages.preMathematicalDevelopmentTotal,
            postMean: averages.postMathematicalDevelopmentMean,
            // postTotal: averages.postMathematicalDevelopmentTotal,
            prePerfectPercentage: (averages.preMathematicalDevelopmentPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postMathematicalDevelopmentPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(mathematicalDevelopmentRow)

        const schoolReadinessRow = {
            category: 'School Readiness',
            preMean: averages.preSchoolReadinessAnswerKeyMean,
            // preTotal: averages.preSchoolReadinessAnswerKeyTotal,
            postMean: averages.postSchoolReadinessAnswerKeyMean,
            // postTotal: averages.postSchoolReadinessAnswerKeyTotal,
            prePerfectPercentage: (averages.preSchoolReadinessAnswerKeyPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postSchoolReadinessAnswerKeyPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(schoolReadinessRow)

        const advocacyRow = {
            category: 'Advocacy',
            preMean: averages.preAdvocacyAnswerKeyMean,
            // preTotal: averages.preAdvocacyAnswerKeyTotal,
            postMean: averages.postAdvocacyAnswerKeyMean,
            // postTotal: averages.postAdvocacyAnswerKeyTotal,
            prePerfectPercentage: (averages.preAdvocacyAnswerKeyPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postAdvocacyAnswerKeyPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(advocacyRow)

        const confidenceRow = {
            category: 'Confidence',
            preMean: averages.preConfidenceAnswerKeyMean,
            // preTotal: averages.preConfidenceAnswerKeyTotal,
            postMean: averages.postConfidenceAnswerKeyMean,
            // postTotal: averages.postConfidenceAnswerKeyTotal,
            prePerfectPercentage: (averages.preConfidenceAnswerKeyPerfectMean * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postConfidenceAnswerKeyPerfectMean  * 100).toFixed(2) + '%'
        }
        tableData.push(confidenceRow)

        const totalRow = {
            category: "Total",
            preMean: ((parseFloat(averages.preConfidenceAnswerKeyMean) + parseFloat(averages.preAdvocacyAnswerKeyMean) + parseFloat(averages.preSchoolReadinessAnswerKeyMean) +
            parseFloat(averages.preMathematicalDevelopmentMean) + parseFloat(averages.preUseOfTechnologyMean) + parseFloat(averages.preSocioemotionalDevelopmentMean) + parseFloat(averages.preNutritionAndPhysicalActivityMean) +
            parseFloat(averages.preLanguageDevelopmentMean) + parseFloat(averages.preEarlyLearningMean)) / 10).toFixed(3),
            // preTotal: averages.preConfidenceAnswerKeyTotal,
            postMean: ((parseFloat(averages.postConfidenceAnswerKeyMean) + parseFloat(averages.postAdvocacyAnswerKeyMean) + parseFloat(averages.postSchoolReadinessAnswerKeyMean) +
            parseFloat(averages.postMathematicalDevelopmentMean) + parseFloat(averages.postUseOfTechnologyMean) + parseFloat(averages.postSocioemotionalDevelopmentMean) + parseFloat(averages.postNutritionAndPhysicalActivityMean) +
            parseFloat(averages.postLanguageDevelopmentMean) + parseFloat(averages.postEarlyLearningMean)) / 10).toFixed(3),
            // postTotal: averages.postConfidenceAnswerKeyTotal,
            prePerfectPercentage: (averages.prePerfectPercentageTotal * 100).toFixed(2) + '%',
            postPerfectPercentage: (averages.postPerfectPercentageTotal * 100).toFixed(2) + '%'
        }
        tableData.push(totalRow)

    }
        return tableData
    }

    mungeSurvey = (survey) => {
        const parent = ['V3Q1', 'V3Q2', 'V3Q3', 'V3Q4'];
        const earlyLearning = ['V3Q5', 'V3Q6', 'V3Q7','V3Q8'];
        const languageDevelopment= ['V3Q9','V3Q10','V3Q11','V3Q12']
        const nutritionAndPhysicalActivity = ['V3Q13','V3Q14','V3Q15','V3Q16']
        const socioemotionalDevelopment = ['V3Q17','V3Q18','V3Q19','V3Q20']
        const useOfTechnology = ['V3Q21','V3Q22','V3Q23','V3Q24','V4Q24']
        const mathematicalDevelopment = ['V3Q25','V3Q26','V3Q27','V3Q28']
        const schoolReadinessAnswerKey = ['V3Q29','V3Q30','V3Q31','V3Q32','V4Q32']
        const advocacyAnswerKey = ['V3Q33','V3Q34','V3Q35','V3Q36','V4Q36']
        const confidenceAnswerKey = ['V3Q37','V3Q38','V3Q39','V3Q40']
        // Calculate pre parent scores
        function reduceScore(answers, key, pre){
            return key.reduce( (acc, value) => {
                const prefix = pre ? 'pre' : 'post';
                const score = answers[`${prefix}${value}`] ? 1 : 0
                return score + acc
            }, 0)
        }
        const preParentScore = reduceScore(survey, parent, true)
        const postParentScore = reduceScore(survey, parent, false)
        const preEarlyLearningScore = reduceScore(survey, earlyLearning, true)
        const postEarlyLearningScore = reduceScore(survey, earlyLearning, false)
        const preLanguageDevelopmentScore = reduceScore(survey, languageDevelopment, true)
        const postLanguageDevelopmentScore = reduceScore(survey, languageDevelopment, false)
        const preNutritionAndPhysicalActivity = reduceScore(survey, nutritionAndPhysicalActivity, true)
        const postNutritionAndPhysicalActivity = reduceScore(survey, nutritionAndPhysicalActivity, false)
        const preSocioEmotional = reduceScore(survey, socioemotionalDevelopment, true)
        const postSocioEmotional= reduceScore(survey, socioemotionalDevelopment, false)
        const preUseOfTechnology = reduceScore(survey, useOfTechnology, true)
        const postUseOfTechnology = reduceScore(survey, useOfTechnology, false)
        const preMathematicalDevelopment = reduceScore(survey, mathematicalDevelopment, true)
        const postMathematicalDevelopment = reduceScore(survey, mathematicalDevelopment, false)
        const preSchoolReadiness = reduceScore(survey, schoolReadinessAnswerKey, true)
        const postSchoolReadiness = reduceScore(survey, schoolReadinessAnswerKey, false)
        const preAdvocacy = reduceScore(survey, advocacyAnswerKey, true)
        const postAdvocacy = reduceScore(survey, advocacyAnswerKey, false)
        const preConfidence = reduceScore(survey, confidenceAnswerKey, true)
        const postConfidence = reduceScore(survey, confidenceAnswerKey, false)
        //Add Cumulative Total

        return {
            preParentScore, 
            postParentScore, 
            preEarlyLearningScore, 
            postEarlyLearningScore, 
            preLanguageDevelopmentScore, 
            postLanguageDevelopmentScore,
            preNutritionAndPhysicalActivity,
            postNutritionAndPhysicalActivity,
            preSocioEmotional,
            postSocioEmotional,
            preUseOfTechnology,
            postUseOfTechnology,
            preMathematicalDevelopment,
            postMathematicalDevelopment,
            preSchoolReadiness,
            postSchoolReadiness,
            preAdvocacy,
            postAdvocacy,
            preConfidence,
            postConfidence
        }


    }

    createScoredRows = (sites) => {
        let surveys = [];
        for (let site in sites) {
            for (let course in sites[site].courses) {
                if (sites[site].courses[course].scoredSurveys && sites[site].courses[course].scoredSurveys.length > 0) {
                    for (let survey of sites[site].courses[course].scoredSurveys) {
                        const row = {siteName: sites[site].name, startDate: Moment(sites[site].courses[course].startDate,'x').format('M/D/YY'), facilitator: sites[site].courses[course].FacilitatorName, ...this.mungeSurvey(survey)}
                        surveys.push(row)
                    }
                }
            }
        }
        return surveys

        //Condense surveys

    }

    orgSelection = () => {
        const OrgOptions = this.state.organizations.sort( (a,b) => {
            if (a['name'].trim().toUpperCase() < b['name'].trim().toUpperCase()) {
                return -1
            }
            if (a['name'].trim().toUpperCase() > b['name'].trim().toUpperCase()) {
                return 1
            }
            return 0
            }
            ).map( (org, index) => {
            return {
                key: index,
                text: org.name,
                value: org.id
            }
        })
        return (
        <Dropdown
            placeholder = "Select Organization"
            fluid
            search
            selection
            options = {OrgOptions}
            onChange = {this.handleOrgSelect}
        />
        )
    }
    render(){
        const ExportName = () => {
            const Course = this.state.processedCourses[this.state.selectedProgamIndex]
            return `${Course.Site}_${Course.Facilitator.trim(' ')}_${Moment(Course.StartDate, 'x').format('M/D/YY')}_Report`
        }
        return (
            <Fragment>
            <Dimmer active={this.state.loading} page>
                <Loader/>
            </Dimmer>
                    <Header>{this.state.orgName}</Header>
                <Container className='premium-container'>
                    {
                        this.state.promptForOrganization ? 
                        this.orgSelection() :
                        null
                    }
                {
                    this.state.reportedSites ? 
                    <Fragment>
                        { this.state.processedCourses.length > 0 ?
                            <>
                            <Form className='premium-dropdown'>
                                <h2>Search by Program</h2>
                                <Form.Field inline>
                                    <label>Display results for: </label>
                                <Dropdown
                                    placeholder='Select Program'
                                    fluid
                                    search
                                    selection
                                    options={this.state.processedCourses.map( (course, index) => {
                                        return {
                                            key: index,
                                            text: `${course.Site} taught by ${course.Facilitator} on ${Moment(course.StartDate, 'x').format('M/D/YY')}`,
                                            value: index
                                        }
                                    })}
                                    onChange = {this.handleProgramSelect}
                                />
                                </Form.Field>
                                <Button primary onClick={this.handleProgramQuery}>Search</Button>
                            </Form>
                            <Form className='premium-dropdown'>
                            <h2>Search by a custom query</h2>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label>Site</label>
                                <Dropdown
                                    placeholder='Select Site'
                                    fluid
                                    search
                                    selection
                                    options={ this.state.processedCourses.reduce((acc, current) => {
                                        if (acc.indexOf(current.Site) < 0 ) {
                                            acc.push(current.Site)
                                        }
                                        return acc
                                    }, []).map( (site, index) => {
                                        return {
                                            key: index,
                                            text: site,
                                            value: site
                                        }
                                    })}
                                    onChange = {this.handleSiteSelect}
                                />
                                </Form.Field>
                                <Form.Field>
                                    <label>Facilitator</label>
                                <Dropdown
                                    placeholder='Select Facilitator'
                                    fluid
                                    search
                                    selection
                                    options={ this.state.processedCourses.reduce((acc, current) => {
                                        if (acc.indexOf(current.Facilitator) < 0 ) {
                                            acc.push(current.Facilitator)
                                        }
                                        return acc
                                    }, []).map( (facilitator, index) => {
                                        return {
                                            key: index,
                                            text: facilitator,
                                            value: facilitator
                                        }
                                    })}
                                    onChange = {this.handleFacilitatorSelect}
                                />
                                </Form.Field>
                                <Form.Field className="date-picker-field">
                                    <label>Date</label>
                                    <SemanticDatepicker type="range" datePickerOnly="true" placeholder="Date Range" className={'ui', 'fluid', 'search', 'selection', 'dropdown'} onChange={this.handleDateChange}></SemanticDatepicker>
                                </Form.Field>
                            </Form.Group>
                            <Button primary onClick={this.handleCustomQuery}>Search</Button>
                            </Form></> : null
                        }
                    {
                    this.state.selectedProgamIndex > -1 & this.state.programQueryActive ?
                        <Fragment>
                            <MaterialTable 
                            columns = {[
                                {field: 'category', title: 'Category'},
                                {field: 'preMean'  , title: 'Mean Pre Score', filtering: false},
                                // {field: 'preTotal'  , title: 'Pre Total Score', filtering: false},
                                {field: 'postMean' , title: 'Mean Post Score', filtering: false},
                                // {field: 'postTotal' , title: 'Post Total Score', filtering: false},
                                {field: 'prePerfectPercentage'  , title: 'Pre Perfect Score %', filtering: false},
                                {field: 'postPerfectPercentage' , title: 'Post Perfect Score %', filtering: false}
                            ]}
                                title="Results by Category"
                                options = {{
                                    filtering: false,
                                    search: false,
                                    exportButton: true,
                                    exportFileName: ExportName(),
                                    pageSize: 10,
                                    paging: false
                                }}
                                data = {this.renderTotalTableData()}
                            />
                            <VictoryChart           
                                theme={CustomTheme}
                                maxDomain={{ y: 4.2}}
                                minDomain={{ y: 0}}
                                height ={250}
                                >
                                <VictoryGroup vertical
                                    offset={10}
                                    style={{ data: { width: 5 }}}
                                    colorScale={["blue", "green"]}
                                >
                                    <VictoryBar
                                        data={this.renderTotalTableData(false, true)[0]}
                                    >

                                    </VictoryBar>
                                    <VictoryBar
                                        data={this.renderTotalTableData(false, true)[1]}
                                    >

                                    </VictoryBar>
                                </VictoryGroup>
                            </VictoryChart>
                        </Fragment> : null
                    }
                    {this.state.customQueryActive ?
                    <Fragment>
                        <MaterialTable 
                            columns = {[
                                {field: 'category', title: 'Category'},
                                {field: 'preMean'  , title: 'Mean Pre Score', filtering: false},
                                {field: 'postMean' , title: 'Mean Post Score', filtering: false},
                                {field: 'prePerfectPercentage'  , title: 'Pre Perfect Score %', filtering: false},
                                {field: 'postPerfectPercentage' , title: 'Post Perfect Score %', filtering: false}
                            ]}
                            title="Results by Category"
                            options = {{
                                filtering: false,
                                search: false,
                                exportButton: true,
                                exportFileName: 'CustomReport',
                                pageSize: 10,
                                paging: false
                            }}
                            data = {this.renderTotalTableData(true)}
                        />
                        <VictoryChart           
                                theme={CustomTheme}
                                maxDomain={{ y: 4.2}}
                                minDomain={{ y: 0}}
                                height ={250}
                                >
                                <VictoryGroup vertical
                                    offset={10}
                                    style={{ data: { width: 5 }}}
                                    colorScale={["blue", "green"]}
                                >
                                    <VictoryBar
                                        data={this.renderTotalTableData(true, true)[0]}
                                    >

                                    </VictoryBar>
                                    <VictoryBar
                                        data={this.renderTotalTableData(true, true)[1]}
                                    >

                                    </VictoryBar>
                                </VictoryGroup>
                            </VictoryChart>
                    </Fragment> : null
                    }
                    { 
                        Object.keys(this.state.reportedSites).map(site => {
                            return (
                            <Container className='premium-container'>

                                <Fragment key={site}>
                                    <Header>{this.state.reportedSites[site].name}</Header>
                                    <Card.Group>
                                        {Object.keys(this.state.reportedSites[site].courses).map( (course, index) => {
                                            return (
                                                <Card key={index}>
                                                <Card.Content>
                                                    <Card.Header>{Moment(this.state.reportedSites[site].courses[course].startDate, 'x').format('MMMM Do YYYY')} <br/> {this.state.reportedSites[site].courses[course].FacilitatorName}</Card.Header>
                                                </Card.Content>
                                                <Card.Description>
                                                PreSurveys:  {this.state.reportedSites[site].courses[course].preSurveyCount} | PostSurveys: {this.state.reportedSites[site].courses[course].postSurveyCount}
                                                </Card.Description>
                                            </Card>
                                            )
                                        })}
                                    </Card.Group>
                                </Fragment>
                            </Container>
                            )
                    })
                    }
                </Fragment> : null
                }
            </Container>



{
/*
                <MaterialTable
                columns={columns}
                data={this.state.processedScores}
                title="Processed Surveys"
                options={{
                    filtering: true,
                    search: false
                  }}
                />
*/
                }
            </Fragment>
        )
    }
}

export default AdvancedDashboard;