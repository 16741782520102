import React, {Component} from 'react';
import {Modal, Button} from 'semantic-ui-react';
import PaymentForm from './PaymentForm';
import axios from 'axios';


class PremiumSignUpModal extends Component{
    state = {
        loaded: false,
        paymentSuccess: false
    }

    sendNonce = (nonce) => {
        const paymentUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/payment/`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        const payload = {
          nonce: nonce
        }
    
        axios.post(paymentUrl, payload, config).then( res => {
          this.setState({
              paymentSuccess: true
          })
        })
      }

    PaymentSuccess = () => {
        return (
            <div>
                <h3>Payment Success!</h3>
            <p>Please log back in to access premium features</p>
            </div>
        )
    }
    componentWillMount(){
        let sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = "https://js.squareupsandbox.com/v2/paymentform";
        sqPaymentScript.type = "text/javascript"
        sqPaymentScript.async = false;
        sqPaymentScript.onload = ()=>{this.setState({
          loaded: true
        })};
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
      }

    render(){
        return(
            <Modal open={this.props.open} onClose={this.props.onClose}>
                <Modal.Header>
                    Sign Up For Premium Today
                </Modal.Header>
                <Modal.Content>
                    {this.state.loaded && !this.state.paymentSuccess &&
                        <PaymentForm
                        paymentForm={ window.SqPaymentForm } sendNonce={this.sendNonce}
                        />
                    }
                    {
                        this.state.loaded && this.state.paymentSuccess && this.PaymentSuccess()
                    }
                </Modal.Content>
            </Modal>
        );
    }
}

export default PremiumSignUpModal;
