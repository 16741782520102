import React, {Component} from 'react';
import {Form, Header, Button} from 'semantic-ui-react';

class TrueOrFalseQuestion extends Component {
    buttonHandler = (index) => {
        this.props.answerQuestion(this.props.id, index);
    }
    render() {
        return(
            <Form size="big">
                <Header as="h3" className="question-prompt">{this.props.language === 'en' ? 'Question ' : 'Pregunta '}{this.props.questionNumber}. &nbsp;{this.props.prompt[this.props.language]}</Header>
                <Form.Field>
                    <Button.Group size="big">
                        <Button type="button" onClick={() => this.buttonHandler(0)}>{this.props.language === 'en' ? 'True' : 'Verdadero'}</Button>
                        <Button.Or/>
                        <Button type="button" onClick={() => this.buttonHandler(1)}>{this.props.language === 'en' ? 'False' : 'Falso'}</Button>
                    </Button.Group>
                </Form.Field>
            </Form>
        )
    }
}

export default TrueOrFalseQuestion;