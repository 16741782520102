import React, {Component} from 'react';
import {Form, Header, Button} from 'semantic-ui-react';

class MultipleChoiceExtendedQuestion extends Component {
    state = {
        extended: false
    }

    firstSet = this.props.responses.slice(0,4);
    secondSet = this.props.responses.slice(4);
    

    buttonHandler = (index) => {
        this.props.answerQuestion(this.props.id, index);
    }

    formSubmit = (e) => {
        e.preventDefault();
    }

    changeOptions = () => {
        this.setState({extended: !this.state.extended})
    }

    setQuestions = () => {
        this.currentSet = this.state.extended ? this.secondSet : this.firstSet;
    }

    render(){
        return(
        <Form size="big">
        <Header as="h3" className="question-prompt">{this.props.language === 'en' ? 'Question ' : 'Pregunta '}{this.props.questionNumber}. &nbsp;{this.props.prompt[this.props.language]}:</Header>
        { this.setQuestions() }
        {
            this.currentSet.map( (response, index) => {
                return (
                    <Form.Field key={index}>
                        <Button basic type="button" fluid size="large" onClick={() => this.buttonHandler(index)} content={response.response[this.props.language]}/>
                    </Form.Field>
                )
            })
        }
        <Form.Field>
            <Button basic type="button" fluid size="large" onClick={() => this.changeOptions()} content="More Options"></Button>
        </Form.Field>
      </Form>
        )
    }
}

export default MultipleChoiceExtendedQuestion;