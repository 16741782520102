import React, {Component, Fragment} from 'react';
import {Card, Icon, Header, Button, Modal, Form, Dimmer, Loader, Checkbox} from 'semantic-ui-react';
import CreateOrg from './CreateOrg';
import UpdateOrg from './UpdateOrg';
import uuid from 'uuid/v4';
import axios from 'axios';

class OrgDashboard extends Component{
    state = {
        organizations: [],
        currentOrganization: '',
        facilitators: [],
        showFacilitatorModal: false,
        showOrgModal: false,
        showAdminModal: false,
        facilitatorEmail: '',
        facilitatorFirstName: '',
        facilitatorLastName: '',
        loading: true,
        createSiteAdmin: false,
        alsoFacilitator: false,
        showDetailModal: false
    }

    getOrganizations = () => {
        const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        axios.get(orgUrl, config).then( res => {
            this.setState({
                organizations: res.data.Items,
                loading: false
            })
        })
    }
    componentDidMount(){
        this.getOrganizations();
    }

    createFacilitator = () => {
        this.setState({
            loading: true
        })
        const accountUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/account'
        const payload = {
            firstName: this.state.facilitatorFirstName,
            lastName: this.state.facilitatorLastName,
            org: this.state.currentOrganization,
            email: this.state.facilitatorEmail.trim().toLowerCase(),
            role: 'facilitator'
        }

        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.post(accountUrl, payload, {headers: headers}).then(res => {
            return res.data.User.Username;
        }).then( id => {
            const facilitatorURl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/facilitators'
            const payload2 = {
                firstName: this.state.facilitatorFirstName,
                lastName: this.state.facilitatorLastName,
                id: id,
                orgId: this.state.currentOrganization,
                email: this.state.facilitatorEmail.trim().toLowerCase()
            }
            return axios.post(facilitatorURl, payload2, {headers: headers})
        }).then( () => {
            this.getOrganizations();
                this.setState({
                    showFacilitatorModal: false,
                    loading: false
                })
        }).catch( e => console.log(e));
    }

    createAdmin = () => {
        const adminUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/account'
        let payload = {
            firstName: this.state.adminFirstName,
            lastName: this.state.adminLastName,
            org: this.state.currentOrganization ? this.state.currentOrganization : 'admin',
            email: this.state.adminEmail.trim().toLowerCase(),
            role: 'orgAdmin'
        }
        if (this.state.createSiteAdmin) {
            payload.role = 'admin'
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.post(adminUrl, payload, {headers: headers}).then(res => {
            if (this.state.alsoFacilitator) {
                let id = res.data.User.Username;
                const facilitatorURl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/facilitators'
                const payload2 = {
                    firstName: this.state.adminFirstName,
                    lastName: this.state.adminLastName,
                    id: id,
                    orgId: this.state.currentOrganization ? this.state.currentOrganization : 'admin',
                    email: this.state.adminEmail.trim().toLowerCase()
                }

                return axios.post(facilitatorURl, payload2, {headers: headers}).then(() => {
                    this.getOrganizations();
                    this.toggleAdminModal();
                })
            }
            this.getOrganizations();
            this.toggleAdminModal();
        });
    }

    createOrganization = () => {
        const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations'
        const payload = {
            firstName: this.state.adminFirstName,
            lastName: this.state.adminLastName,
            id: uuid(),
            orgId: this.state.currentOrganization,
            email: this.state.adminEmail.trim().toLowerCase()
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.post(orgUrl, payload, {headers: headers}).then(res => {
            this.getOrganizations();
        });
    }


    toggleFacilitatorModal = (id) => {
        if (id) {
            this.setState({
                showFacilitatorModal: !this.state.showFacilitatorModal,
                currentOrganization: id,
            })
        } else {
            this.setState({
                showFacilitatorModal: !this.state.showFacilitatorModal,
            })
        }
    }

    toggleAdminModal = (id) => {
        if (id === 'admin') {
            this.setState({
                showAdminModal: !this.state.showAdminModal,
                createSiteAdmin: true
            })
        } else if (id) {
            this.setState({
                currentOrganization: id,
                showAdminModal: !this.state.showAdminModal
            })
        } else {
            this.setState({
                showAdminModal: !this.state.showAdminModal,
                createSiteAdmin: false
            })
        }
    }

    toggleOrgModal = (id) => {
        this.getOrganizations();
        this.setState({
            currentOrganization: id,
            showOrgModal: !this.state.showOrgModal
        })
    }

    toggleDetailModal = (id) => {
        this.setState({
            currentOrganization: id,
            showDetailModal: !this.state.showDetailModal
        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    handleCheckbox = () => {
        this.setState({
            alsoFacilitator: !this.state.alsoFacilitator
        })
    }



    render(){
        const currentDetail = this.state.organizations.find((element) => {
            return element.id === this.state.currentOrganization 
        }) || {}
        return (
            <Fragment>
                <Dimmer active={this.state.loading} page>
                    <Loader/>
                </Dimmer>
                <Card.Group>
                    {
                        this.props.role === 'admin' ?
                        <Fragment>
                            <Card>
                                <Card.Content>
                                    <Card.Header>
                                        Site Admins
                                    </Card.Header>
                                    <Card.Description>
                                        This Creates Additional Super Users
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <Button.Group size="tiny">
                                    <Button positive onClick={() => this.toggleAdminModal('admin')}>Add Admin <Icon name="plus" style={{paddingLeft: '8px'}}/></Button>
                                    </Button.Group>
                                </Card.Content>
                            </Card>
                            <Card style={{textAlign: 'left'}}>
                                <Card.Content>
                                    <Card.Header>
                                        Organizations
                                    </Card.Header>
                                        <Card.Description>
                                            {this.state.organizations.length} Organizations
                                        </Card.Description>
                                </Card.Content>
                                <Card.Content extra style={{textAlign: 'center'}}>
                                    <Button type="button" onClick={this.toggleOrgModal} content="Add Organization"></Button>
                                </Card.Content>
                            </Card> 
                        </Fragment> :
                        null
                    }
                    { 
                        this.state.organizations.sort( (a,b) => {
                            if (a['name'].trim().toUpperCase() < b['name'].trim().toUpperCase()) {
                                return -1
                            }
                            if (a['name'].trim().toUpperCase() > b['name'].trim().toUpperCase()) {
                                return 1
                            }
                            return 0
                            }
                            ).map( (org, index) => {
                        if (org) {
                        return (
                        <Card key={index} style={{textAlign: 'left'}}>
                            <Card.Content>
                                <Card.Header>
                                    {org.name}
                                </Card.Header>
                                <Button onClick={() => this.toggleDetailModal(org.id)}>Details</Button>
                            </Card.Content>
                            <Card.Content extra>
                            {
                                this.props.role != 'admin' ?
                                null :
                                <Button.Group size="tiny">
                                    <Button primary onClick={() => this.toggleFacilitatorModal(org.id)}>Add Facilitators <Icon name="plus" style={{paddingLeft: '8px'}}/></Button>
                                    <Button positive onClick={() => this.toggleAdminModal(org.id)}>Add Admin <Icon name="plus" style={{paddingLeft: '8px'}}/></Button>
                                </Button.Group>
                            }
                            </Card.Content>
                        </Card>
                        )
                        }
                    })
                    }
                </Card.Group>
                <Modal open={this.state.showFacilitatorModal} onClose={this.toggleFacilitatorModal}>
                    <Header content="Add Facilitator"/>
                    <Modal.Content>
                        <Form onSubmit={this.createFacilitator}>
                                <Form.Field>
                                    <label>First Name</label>
                                    <Form.Input type="text" name="facilitatorFirstName" value={this.state.facilitatorFirstName} onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Last Name</label>
                                    <Form.Input type="text" name="facilitatorLastName" value={this.state.facilitatorLastName} onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Email</label>
                                    <Form.Input type="text" name="facilitatorEmail" value={this.state.facilitatorEmail} onChange={this.handleChange}/>
                                </Form.Field>
                            <Form.Field>
                                <Button primary type="submit" content="Add Facilitator" disabled={!this.state.facilitatorFirstName && !this.state.facilitatorLastName && !this.state.facilitatorEmail}/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showAdminModal} onClose={this.toggleAdminModal}>
                    <Header content="Add Org Admin"/>
                    <Modal.Content>
                    <Form onSubmit={this.createAdmin}>
                                <Form.Field>
                                    <label>First Name</label>
                                    <Form.Input type="text" name="adminFirstName" value={this.state.adminFirstName} onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Last Name</label>
                                    <Form.Input type="text" name="adminLastName" value={this.state.adminLastName} onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Email</label>
                                    <Form.Input type="text" name="adminEmail" value={this.state.adminEmail} onChange={this.handleChange}/>
                                </Form.Field>
                                {
                                    this.state.currentOrganization ?
                                    <Form.Field control={Checkbox} name="alsoFacilitator" checked={this.state.alsoFacilitator} onChange={this.handleCheckbox} label="Also Make this admin a Facilitator?"/> :
                                    null
                                }
                            <Form.Field>
                                <Button primary type="submit" content="Add Organization Admin" disabled={!this.state.adminFirstName && !this.state.adminLastName && !this.state.adminEmail}/>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showOrgModal} onClose={this.toggleOrgModal}>
                    <Header content="Add New Organization"/>
                    <Modal.Content>
                        <CreateOrg closeModal={this.toggleOrgModal} token={this.props.token}/>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showDetailModal} onClose={this.toggleDetailModal}>
                    <Header content="Organization Details"/>
                    <Modal.Content>
                        <UpdateOrg closeModal={this.toggleDetailModal} orgId={this.state.currentOrganization} token={this.props.token}/>
                    </Modal.Content>
                </Modal>
            </Fragment>
        )
    }
}

export default OrgDashboard;