import React, {Component} from 'react';
import {Form, Button, Select} from 'semantic-ui-react';
import uuid from 'uuid/v4';
import axios from 'axios';
import StateList from './StateList';

class createSiteForm extends Component{
    state = {
        name: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        orgList: [],
        org: ''
    }

    componentDidMount(){
        this.getOrganizations();
    }

    makeOrgList = (orgs) => {
        const orgList = orgs.map((org, index) => {
            let orgObject = {}
            orgObject.key = index;
            orgObject.value = org.id;
            orgObject.text = org.name;
            return orgObject;
        })
        return orgList;
    }

    getOrganizations = () => {
        const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        axios.get(orgUrl, config).then( res => {
            this.setState({
                orgList: this.makeOrgList(res.data.Items)
            })
        })
    }

    createSite = (event) => {
        event.preventDefault();
        const createSiteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites`;
        const payload = {
            name: this.state.name,
            id: uuid(),
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode,
            org: this.state.org
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.post(createSiteUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal(true);
        }).catch( e => {
            console.log(e);
        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    render(){
        const formEnabled = this.state.name && this.state.state
        return (
            <Form onSubmit={this.createSite}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Site Name (required)" name="name" value={this.state.name} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                {
                    this.props.role === 'admin' ?
                    <Form.Field>
                        <label>Organization</label>
                        <Select value={this.state.org} placeholder="organization" name="org" options={this.state.orgList} onChange={this.handleChange}/>
                    </Form.Field> : null
                }
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 1" name="addressLine1" value={this.state.addressLine1} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 2" name="addressLine2" value={this.state.addressLine2} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="City" name="city" value={this.state.city} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>State (required)</label>
                        <Select required fluid options={StateList} name="state" value={this.state.state} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Zipcode" name="zipcode" value={this.state.zipcode} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button primary type="submit" content="Add Site" disabled={!formEnabled}/>
                </Form.Field>
            </Form>
        )
    }
}

export default createSiteForm;