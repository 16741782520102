import React, { Component } from 'react';

import Survey from './Survey';
import Registration from './Registration';
import AdminDashboard from './AdminDashboard';
import {Document, Paragraph, Packer} from 'docx';
import FileSaver from 'file-saver';

import './App.css';

class App extends Component {

  state = {
    currentPage: '',
    registered: false,
    preSurveyCompleted: false,
    postSurveyCompleted: false,
    birthday: '',
    siteId: '',
    facilitator: '',
    facilitatorId: '',
    courseDate: '',
    language: 'en'
  }

  makeDoc = () => {
    const doc = new Document();
    const paragraph = new Paragraph('Yo');
    doc.addParagraph(paragraph);
    const packer = new Packer();
    packer.toBlob(doc).then(blob => {
      FileSaver.saveAs(blob, 'test.docx')
    })
  }

  register = (language, preSurvey, surveyInfo, postSurvey) => {
    this.setState({
      currentPage: 'survey',
      registered: true, 
      preSurveyCompleted: preSurvey,
      postSurveyCompleted: postSurvey ? true : false,
      birthday: surveyInfo.birthday,
      courseDate: surveyInfo.courseStart,
      siteId: surveyInfo.siteId,
      facilitatorId: surveyInfo.facilitatorId,
      language: language
    })
  }

  reset = () => {
    this.setState({
      currentPage: '',
      registered: false, 
      preSurveyCompleted: false, 
      postSurveyCompleted: false,
      siteId: '',
      birthday: '',
      courseDate: '',
      facilitator: '',
      facilitatorId: ''
    });
  }

  renderMain = () => {
    switch (this.state.currentPage) {
      case 'admin':
        return <AdminDashboard></AdminDashboard>
      case 'survey':
        return (
          <Survey reset={this.reset}
          preSurveyCompleted={this.state.preSurveyCompleted} 
          postSurveyCompleted={this.state.postSurveyCompleted}
          birthday={this.state.birthday}
          courseDate={this.state.courseDate}
          siteId={this.state.siteId}
          facilitatorId={this.state.facilitatorId}
          language={this.state.language}
          /> 
        )
      default:
        return <Registration register={this.register} setAdmin={this.setAdminMode}/>
    }
  }

  setAdminMode = () => {
    this.setState({
      currentPage: 'admin'
    })
  }

  render() {
    return (
      <div className="App">
        { this.renderMain() }
      </div>
    );
  }
}

export default App;
