import React, {Component, Fragment} from 'react';
import {Button, Icon, Card, Modal} from 'semantic-ui-react';
import {Document, Paragraph, Packer, TextRun} from 'docx';
import FileSaver from 'file-saver';
import axios from 'axios';
import * as math from 'mathjs';
import { VictoryGroup, VictoryChart, VictoryBar} from 'victory'
import {Bar} from 'react-chartjs-2';
import moment from 'moment';
import PremiumSignUpModal from './premiumSignUpModal';

const parentAnswerKey = {
    V3Q1: 0,
    V3Q2: 3,
    V3Q3: 0,
    V3Q4: 1
};

const earlyLearningAnswerKey = {
    V3Q5: 0,
    V3Q6: 3,
    V3Q7: 1,
    V3Q8: 0
}

const languageDevelopmentAnswerKey = {
    V3Q9: 4,
    V3Q10: 1,
    V3Q11: 0,
    V3Q12: 0
}

const nutritionAndPhysicalActivityAnswerKey = {
    V3Q13: [0,1,3,4],
    V3Q14: [0,2,3,4],
    V3Q15: 3,
    V3Q16: 1
}

const socioemotionalDevelopmentAnswerKey = {
    V3Q17: 1,
    V3Q18: 3,
    V3Q19: [0,2,3,4],
    V3Q20: 0
}

const useOfTechnologyAnswerKey = {
    V3Q21: [1,2,3],
    V3Q22: 3,
    V3Q23: [0,1,2,4],
    V3Q24: 0,
    V4Q24: 0
}

const mathematicalDevelopmentAnswerKey = {
    V3Q25: 0,
    V3Q26: [0,1,3],
    V3Q27: [0,1,2,3,4],
    V3Q28: 1
}

const schoolReadinessAnswerKey = {
    V3Q29: [0,1,2],
    V3Q30: [0,1,2,3,4],
    V3Q31: 3,
    V3Q32: 0,
    V4Q32: [0,2,3,4]
}

const advocacyAnswerKey = {
    V3Q33: 3,
    V3Q34: [0,2,4],
    V3Q35: 2,
    V3Q36: 0,
    V4Q36: [3],
}

const confidenceAnswerKey = {
    V3Q37: [2,3],
    V3Q38: [2,3],
    V3Q39: [2,3],
    V3Q40: [2,3]
}

class ReportDashboard extends Component{
    state = {
        parentingScores: {},
        earlyLearningScores: {},
        languageDevelopmentScores: {},
        nutritionAndPhysicalActivityScores: {},
        mathematicalDevelopmentScores: {},
        schoolReadinessScores: {},
        advocacyScores: {},
        confidenceScores: {},
        useOfTechnologyScores: {},
        socioemotionalDevelopmentScores: {},
        sites: [],
        selectedSite: 0,
        courseList: [{
            value: '',
            text: 'No Programs Found'
        }],
        courses: [],
        selectedReport: '',
        calculationError: false,
        preScores:[],
        postScores: [],
        sortedCourses: {},
        modalOpen: false,
        numberOfResponses: 0,
        programId: '',
        premiumSignUpModalOpen: false
    }

    togglePremiumSignUpModal = () => {
        this.setState({
            premiumSignUpModalOpen: !this.state.premiumSignUpModalOpen
        })
    }
    getProgramCSV = () => {
        const siteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/reports/${this.state.programId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.get(siteUrl, config).then( res => {
            const blob = new Blob([res.data.join('\n')], {type: "text/csv;charset=utf-8"})
                FileSaver.saveAs(blob, 'OpeningDoorsProgramReport.csv')

        })
    }

    getMacroReport = () => {
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/reports'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.get(siteUrl, config).then( res => {
            const blob = new Blob([res.data.join('\n')], {type: "text/csv;charset=utf-8"})
                FileSaver.saveAs(blob, 'OpeningDoorsReport.csv')

        })
    }

    getProgramReport = () => {
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/programReport'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.get(siteUrl, config).then( res => {
            const blob = new Blob([res.data.join('\n')], {type: "text/csv;charset=utf-8"})
                FileSaver.saveAs(blob, 'OpeningDoorsReport.csv')

        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    handleReportChange = (event, {name, value}) => {
        this.selectedReport(value);
    }

    selectReport = (report) => {
        this.selectedReport(report);
        this.setState({modalOpen: true, programId: report})
    }
    
    handleModalClose = () => {
        this.setState({modalOpen: false})
    }

    handleSiteChange = (event, {name, value}) => {
        if (this.state.sites[value].Courses) {
            const list = this.state.sites[value].Courses.map( (entry, index) => {
                const output = {
                    value: entry.CourseStartDate,
                    text: entry.CourseStartDate,
                    key: index
                }
                return output
            });
            if (list.length > 0) {
                this.setState({[name]: value, courseList: list})
            } else {
                this.setState({[name]: value, courseList: [{
                    value: '',
                    text: 'No Programs Found'
                }]})
            }
        } else {
            this.setState({[name]: value, courseList: [{
                value: '',
                text: 'No Programs Found'
            }]})
        }
    }

    fetchCourses = () => {
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }
        axios.get(siteUrl, config).then( (response) => {
            const sites = response.data.Items
            for (let i in sites) {
                for (let n in sites[i].Courses) {
                    sites[i].Courses[n].SiteName = sites[i].SiteName;
                    sites[i].Courses[n].SiteId = sites[i].SiteId;
                }
            }

            const courses = sites.reduce( (previous, site) => {
                return previous.concat(site.Courses)
            }, [])
            const courseList = courses.map( (course, index) => {
                const courseItem = {
                    index: index,
                    text: `${course.SiteName} on ${course.PrettyStartDate}`,
                    value: `${course.SiteId}${course.FacilitatorId}${course.CourseStartDate}`
                }
                return courseItem;
            })

            let sortedCourses = {}

            for (let course in courses) {
                if (sortedCourses[courses[course].SiteId]) {
                    sortedCourses[courses[course].SiteId].courses.push(courses[course])
                } else {
                    let courseArray = [];
                    courseArray.push(courses[course])
                    sortedCourses[courses[course].SiteId] = {
                        "siteName": courses[course].SiteName,
                        "courses": courseArray
                    }
                }
            }

            this.setState({
                courses: courses,
                courseList: courseList,
                sortedCourses
            })
        })
    }

    selectedReport = (report) => {
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        const cohortReportDataUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/cohort-report?partitionKey=${report}`
        axios.get(cohortReportDataUrl, config).then( (res) => {
            const responses = res.data.Items;
            const responseInfo = res.data.Info;
            const numberOfPreSurveys = responses.reduce( (previous, current) => {
                if (current.preSurveyAnswers && current.preSurveyAnswers.length) {
                    return previous + 1
                } else {
                    return previous
                }
            }, 0);
            const numberOfPostSurveys = responses.reduce( (previous, current) => {
                if (current.postSurveyAnswers && current.postSurveyAnswers.length) {
                    return previous + 1
                } else {
                    return previous
                }
            }, 0);
            const filteredResponses = responses.filter( (value) => {
                return (value && value.preSurveyAnswers && value.postSurveyAnswers);
            })
            const preSurveys = this.transformPreSurveyResponses(filteredResponses);
            const postSurveys = this.transformPostSurveyResponses(filteredResponses);
            const parentingScores = this.calculateScores(preSurveys, postSurveys, parentAnswerKey, responses);
            const earlyLearningScores = this.calculateScores(preSurveys, postSurveys, earlyLearningAnswerKey, responses);
            const languageDevelopmentScores = this.calculateScores(preSurveys, postSurveys, languageDevelopmentAnswerKey, responses);
            const nutritionAndPhysicalActivityScores = this.calculateScores(preSurveys, postSurveys, nutritionAndPhysicalActivityAnswerKey, responses);
            const socioemotionalDevelopmentScores = this.calculateScores(preSurveys, postSurveys, socioemotionalDevelopmentAnswerKey, responses);
            const useOfTechnologyScores = this.calculateScores(preSurveys, postSurveys, useOfTechnologyAnswerKey, responses);
            const mathematicalDevelopmentScores = this.calculateScores(preSurveys, postSurveys, mathematicalDevelopmentAnswerKey, responses);
            const schoolReadinessScores = this.calculateScores(preSurveys, postSurveys, schoolReadinessAnswerKey, responses);
            const advocacyScores = this.calculateScores(preSurveys, postSurveys, advocacyAnswerKey, responses);
            const confidenceScores = this.calculateScores(preSurveys, postSurveys, confidenceAnswerKey, responses);
            const selectedSiteName = responseInfo.SiteName;
            const selectedFacilitatorName = responseInfo.FacilitatorName;
            const selectedStartDate = responseInfo.StartDate;
            const selectedOrganizationName =  responseInfo.OrganizationName;
            this.setState({
                parentingScores, 
                earlyLearningScores, 
                languageDevelopmentScores,
                nutritionAndPhysicalActivityScores,
                socioemotionalDevelopmentScores,
                useOfTechnologyScores,
                mathematicalDevelopmentScores,
                schoolReadinessScores,
                advocacyScores,
                confidenceScores,
                selectedReport: report,
                numberOfResponses: responses.length,
                numberOfPreSurveys,
                numberOfPostSurveys,
                selectedSiteName,
                selectedFacilitatorName,
                selectedStartDate,
                selectedOrganizationName
            })
        })
    }
    componentDidMount() {
        this.fetchCourses();

    }

    biographicData = (postSurveys) => {
        let languageCount = {
            English: 0,
            Spanish: 0,
            Chinese: 0,
            Tagalog: 0,
            Vietnamese: 0,
            Arabic: 0,
            French: 0,
            Korean: 0,
        }

        let educationCount = {
            gradeSchool: 0,
            someHighSchool: 0,
            postHighSchool: 0
        }

        let ethnicCount = {
            white: 0,
            black: 0,
            latino: 0,
            eastAsian: 0,
            southAsian: 0,
            middleEastern: 0,
            nativeAmerican: 0
        }

        const languages = postSurveys.map( survey => {
            return survey['V3Q41']
        });

        const educations = postSurveys.map( survey => {
            return survey['V3Q43']
        });

        const ethnicities = postSurveys.map( survey => {
            if (survey['V3Q42'].length > 1) {
                return survey['V3Q42'].split(',');
            } else {
                return survey['V3Q42']
            }
        });

        function raceMap(race){
            switch (race) {
                case 0:
                    return (
                        ethnicCount.white = ++ethnicCount.white
                    )
                case 1:
                    return (
                        ethnicCount.black = ++ethnicCount.black
                    )
                case 2:
                    return (
                        ethnicCount.latino = ++ethnicCount.latino
                    )
                case 3:
                    return (
                        ethnicCount.eastAsian = ++ethnicCount.eastAsian
                    )
                case 4:
                    return (
                        ethnicCount.southAsian = ++ethnicCount.southAsian
                    )
                case 5:
                    return (
                        ethnicCount.middleEastern = ++ethnicCount.middleEastern
                    )
                case 6:
                    return (
                        ethnicCount.nativeAmerican = ++ethnicCount.nativeAmerican
                    )
            }
        }

        ethnicities.map( entry => {
            if (entry.length > 1) {
                entry.map( race => {
                    return raceMap(race)
                })
            } else {
                return raceMap(entry)
            }
        });

        educations.map( entry => {
            switch (entry) {
                case 0:
                    return (
                        educationCount.gradeSchool = ++ educationCount.gradeSchool
                    )
                case 1:
                    return (
                        educationCount.someHighSchool = ++ educationCount.someHighSchool
                    )
                case 2:
                    return (
                        educationCount.postHighSchool = ++educationCount.postHighSchool
                    )
            }
        })

        languages.map( (entry) => {
            switch (entry) {
                case 0: 
                    return (
                        languageCount.English = ++languageCount.English
                    )
                case 1: 
                    return (
                        languageCount.Spanish = ++languageCount.Spanish
                    )
                case 2:
                    return (
                        languageCount.Chinese = ++languageCount.Chinese
                    )
                case 3:
                    return (
                        languageCount.Tagalog = ++ languageCount.Tagalog
                    )
            }
        });

        return {languageCount, educationCount, ethnicCount};


    }

    transformPreSurveyResponses = (responses) => {
        const preSurveyResponses = responses.map( (response) => {
            return response.preSurveyAnswers.reduce( (obj, answer) => {
                let questionId = Object.keys(answer)[0]
                obj[questionId] = answer[questionId];
                return obj
            }, {})
        });
        return preSurveyResponses;
    }

    transformPostSurveyResponses = (responses) => {
        const postSurveyResponses = responses.map( (response) => {
            return response.postSurveyAnswers.reduce( (obj, answer) => {
                let questionId = Object.keys(answer)[0]
                obj[questionId] = answer[questionId];
                return obj
            }, {})
        });
        return postSurveyResponses;
    }

    calculateScores =  (preSurveys, postSurveys, answerKey, responses) => {

        function score(surveys, answerKey){
            const answerKeys = Object.keys(answerKey);
            return surveys.map( (survey) => {
                return answerKeys.map( (key) => {
                    if ( !(key in survey)) {
                        return 1
                    } else if (survey[key] && survey[key].length > 0) {
                        let multipleAnswers = survey[key].split(',').sort().join(',');
                        if (multipleAnswers === answerKey[key].join(',')){
                            return 1
                        } else {
                            return 0
                        }
                    } else if (survey[key] === answerKey[key]) {
                        return 1
                    } else if (answerKey.length > 1 && answerKey.indexOf(survey[key]) > -1) {
                        return 1
                    } else if (key in confidenceAnswerKey) {
                        if  (survey[key] >= 2) {
                            return 1
                        } else  {
                            return 0
                        }
                    } else {
                        return 0
                    }
                })
            })
        }

        function scoreTotals(scores){
            return scores.map((current) => {
                return math.sum(current);
            })
        }

        function perfectScorePercent(scores, answerKey) {
            const perfectScores = scores.reduce( (prev, current) => {
                let count =  0;
                if ( current.filter( value => value === 1).length === Object.keys(answerKey).length) {
                    count = 1;
                }
                return prev = prev + count;
            }, 0)

            return ( (perfectScores / scores.length) * 100);
        }

        function perfectScores(scores, answerKey) {
            const perfectScores = scores.reduce( (prev, current) => {
                let count =  0;
                if ( current.filter( value => value === 1).length === Object.keys(answerKey).length) {
                    count = 1;
                }
                return prev = prev + count;
            }, 0)

            return perfectScores;
        }

    
        
        const preScores = score(preSurveys, answerKey);
        const postScores = score(postSurveys, answerKey);
        const prePerfectPercent = math.round(perfectScorePercent(preScores, answerKey), 2);
        const postPerfectPercent = math.round(perfectScorePercent(postScores, answerKey), 2);
        const prePerfect = perfectScores(preScores, answerKey);
        const postPerfect = perfectScores(postScores, answerKey);

        return {
            preScores, 
            postScores,
            totalPre: preSurveys.length, 
            totalPost: postSurveys.length,
            prePerfectPercent,
            postPerfectPercent,
            prePerfect,
            postPerfect
        }
    }

    makeDoc = () => {
        const doc = new Document();
        doc.styles.createParagraphStyle('body', 'regular')
        .basedOn("Normal")
        .next("Normal")
        .quickFormat()
        .size(24)
        ;

        doc.styles.createParagraphStyle('red', 'regular')
        .basedOn("Normal")
        .next("Normal")
        .quickFormat()
        .color('ff0000')
        .size(24)
        ;

        const startHeading = new Paragraph().style('body').center()
        const startHeadingA = new Paragraph().style('body').center()
        const startHeading2 = new Paragraph().center().style('body').spacing({after: 360});
        const headingRun1 = new TextRun('Abriendo Puertas/Opening Doors, 3rd Edition Evaluation').bold()
        const headingRun1a = new TextRun(`${this.state.selectedOrganizationName} At ${this.state.selectedSiteName} by ${this.state.selectedFacilitatorName} starting on ${moment(this.state.selectedStartDate).format('MMMM Do YYYY')}`).bold()
        startHeading.addRun(headingRun1);
        startHeadingA.addRun(headingRun1a)
        const communityParagraph = new Paragraph().style('body').spacing({after: 240});
        const communityRun = new TextRun('About Our Community. ').bold()
        communityParagraph.addRun(communityRun)

        const communityParagraph2 = new Paragraph().style('red').spacing({after:240});
        const communityRun2 = new TextRun('Programs insert community description here.');
        communityParagraph2.addRun(communityRun2);

        const methodParagraph = new Paragraph().style('body').center().spacing({after: 360});
        const methodRun = new TextRun('Method').bold();
        methodParagraph.addRun(methodRun);

        const resultsParagraph = new Paragraph().style('body').center().spacing({after: 360});
        const resultsRun = new TextRun('Results').bold();
        resultsParagraph.addRun(resultsRun);

        const participantParagraph = new Paragraph().style('body').spacing({after: 240});
        const participantHeader = new TextRun('Participants. ').bold()
        const participantRun = new TextRun(`Our evaluation reflects the `);
        const participantRun2 = new TextRun(`${this.state.confidenceScores.postScores.length} parents `).color('ff0000');
        const participantRun3 = new TextRun(`who completed both Session 1 Survey and Session 10 Survey. Below, you can see their home languages, race/ethnicity, and education levels.`);
        participantParagraph.addRun(participantHeader).addRun(participantRun).addRun(participantRun2).addRun(participantRun3);
        const paragraph1 = new Paragraph().style('body').spacing({after: 240});
        const opening1 = new TextRun('Abriendo Puertas/Opening Doors (AP/OD). ').bold();
        const opening2 = new TextRun(`is a comprehensive, 10-session parenting skills and advocacy program developed by and for low-income parents with children ages 0 to 5. Since its launch in 2007, it has grown rapidly in scope and impact to meet the needs of parents nationwide. Drawing from the real-life experiences of parents, and local data about their schools and communities, sessions are filled with interactive activities that aim to develop parents’ self-understanding as powerful agents of change to improve the lives of their children. The information is presented in ways that are easy for parents to understand and act on within their daily lives. This unique curriculum builds on participants’ cultural strengths to transform the achievement gap into an opportunity to improve and enrich the lives of their children and families.`);
        paragraph1.addRun(opening1);
        paragraph1.addRun(opening2);
        const procedure = new Paragraph().style('body').spacing({after: 240});
        const procedureHeading = new TextRun('Procedure. ').bold();
        const procedureRun = new TextRun('To understand our program’s effectiveness we measured participants’ knowledge gains after participating in Abriendo Puertas/Opening Doors. We asked parents to complete a survey in the first session (Session 1 Survey), and again in the last session (Session 10 Survey). By comparing participants’ answers to the questions before and after Abriendo Puertas/Opening Doors, we can better understand how much they learned.');
        procedure.addRun(procedureHeading).addRun(procedureRun);
        const procedure2 = new Paragraph('There are 40 questions about the 10 Sessions that cover 10 key dimensions: Parenting, Early learning, Language and literacy development, Nutrition and physical activity, Socioemotional development, Use of technology, Mathematical development, School readiness, Advocacy, and Confidence. Each dimension (and Session) had 4 multiple-choice or true-false questions.').style('body').spacing({after: 240})
        const paragraph2 = new Paragraph().style('body').spacing({after: 240});
        const parentHeading = new TextRun('Parenting. ').bold();
        const run2 = new TextRun(`In this section, parents were assessed on their general knowledge about children’s learning, and setting goals to promote their children’s well-being. Topics included when children’s learning starts and how parents can improve their children’s school success. Before participating in the AP/OD program, `);
        const run2a = new TextRun(`${this.state.parentingScores.prePerfectPercent}% of parents `).color('ff0000');
        const run2b = new TextRun(`correctly answered all the questions in this dimension; after the program, `);
        const run2c = new TextRun(`${this.state.parentingScores.postPerfectPercent}% of the parents `).color('ff0000');
        const run2d = new TextRun(`did`);
        paragraph2.addRun(parentHeading)
        .addRun(run2)
        .addRun(run2a)
        .addRun(run2b)
        .addRun(run2c)
        .addRun(run2d)
        .style('body')

        const paragraph4 = new Paragraph().style('body').spacing({after: 240})
        const earlyLearningHeading = new TextRun('Early learning. ').bold();
        const run4 = new TextRun(`The early learning section of the survey examined parents’ general knowledge about how children’s brains develop, and how young children learn. Specifically, parents were asked about the most important time period for children’s brain development, the most important areas of children’s development, and the consequences of stress on the brain. Before participating in the AP/OD program, `)
        const run4a = new TextRun(`${this.state.earlyLearningScores.prePerfectPercent}% `).color('ff0000');
        const run4b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `);
        const run4c = new TextRun(`${this.state.earlyLearningScores.postPerfectPercent}% `).color('ff0000');
        const run4d = new TextRun(`of the parents did.`);
        paragraph4.addRun(earlyLearningHeading).addRun(run4).addRun(run4a)
        .addRun(run4b).addRun(run4c).addRun(run4d);

        const languageHeading = new TextRun('Language and literacy development. ').bold();
        const paragraph6 = new Paragraph().style('body').spacing({after: 240})
        const run6 = new TextRun(`In the language and literacy development scale, parents were assessed on their knowledge of the foundations for communication and on how to support the development of strong language and literacy skills. Parents were asked, among other questions, about the consequences of using two languages, and about the means to promote language skills. Before participating in the AP/OD program, `);
        const run6a = new TextRun(`${this.state.languageDevelopmentScores.prePerfectPercent}% `).color('ff0000');
        const run6b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `);
        const run6c = new TextRun(`${this.state.languageDevelopmentScores.postPerfectPercent}% `).color('ff0000');
        const run6d = new TextRun(`of the parents did.`);
        paragraph6.addRun(languageHeading).addRun(run6).addRun(run6a)
        .addRun(run6b).addRun(run6c).addRun(run6d);

        const nutritionHeading = new TextRun('Nutrition and physical activity. ').bold();
        const paragraph8 = new Paragraph().style('body').spacing({after: 240})
        const run8 = new TextRun(`Parents were assessed on their knowledge of basic health information pertaining to diet and exercise for themselves and their children. Questions focused on the consequences of spending too much time in front of a screen, the benefits of playtime, and the most effective ways to promote a healthy lifestyle. Before participating in the AP/OD program, `)
        const run8a = new TextRun(`${this.state.nutritionAndPhysicalActivityScores.prePerfectPercent}% `).color('ff0000')
        const run8b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run8c = new TextRun(`${this.state.nutritionAndPhysicalActivityScores.postPerfectPercent}% `).color('ff0000')
        const run8d = new TextRun(`of the parents did.`)
        paragraph8.addRun(nutritionHeading).addRun(run8).addRun(run8a).addRun(run8b)
        .addRun(run8c).addRun(run8d);

        const socioemotionalHeading = new TextRun('Socioemotional Development. ').bold();
        const paragraph10 = new Paragraph().style('body').spacing({after: 240})
        const run10 = new TextRun(`The AP/OD survey tapped parents’ understanding of children’s social-emotional development. Parents were asked four questions about supporting children’s social-emotional development. For example, parents were asked to define positive social-emotional skills, how they can help their child learn to express her/his feelings constructively, and the impact of parental socioemotional health on their child’s development. Before participating in the AP/OD program, `)
        const run10a = new TextRun(`${this.state.socioemotionalDevelopmentScores.prePerfectPercent}% `).color('ff0000')
        const run10b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run10c = new TextRun(`${this.state.socioemotionalDevelopmentScores.postPerfectPercent}% `).color('ff0000')
        const run10d = new TextRun(`of the parents did.`)
        paragraph10.addRun(socioemotionalHeading).addRun(run10).addRun(run10a)
        .addRun(run10b).addRun(run10c).addRun(run10d);

        const technologyHeading = new TextRun('Use of technology. ').bold();
        const paragraph12 = new Paragraph().style('body').spacing({after: 240})
        const run12 = new TextRun(`On this scale, parents were asked how they can ensure their children benefit from what technology has to offer. Topics included good practices around the use of technology, and criteria for choosing shows and apps for children. Before participating in the AP/OD program, `)
        const run12a = new TextRun(`${this.state.useOfTechnologyScores.prePerfectPercent}% `).color('ff0000')
        const run12b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run12c = new TextRun(`${this.state.useOfTechnologyScores.postPerfectPercent}% `).color('ff0000')
        const run12d = new TextRun(`of the parents did.`);
        paragraph12.addRun(technologyHeading).addRun(run12).addRun(run12a)
        .addRun(run12b).addRun(run12c).addRun(run12d);

        const mathematicalHeading = new TextRun('Mathematical development. ').bold();
        const paragraph14 = new Paragraph().style('body').spacing({after: 240});
        const run14 = new TextRun(`The mathematical development section examined parents’ knowledge of children’s mathematical learning, and on the promotion of strong mathematical skills through everyday activities. Questions included the age at which age children start developing mathematical abilities and defining some essential math skills. Before participating in the AP/OD program, `)
        const run14a = new TextRun(`${this.state.mathematicalDevelopmentScores.prePerfectPercent}% `).color('ff0000')
        const run14b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run14c = new TextRun(`${this.state.mathematicalDevelopmentScores.postPerfectPercent}% `).color('ff0000')
        const run14d = new TextRun(`of the parents did.`);
        paragraph14.addRun(mathematicalHeading).addRun(run14).addRun(run14a)
        .addRun(run14b).addRun(run14c).addRun(run14d);

        const schoolHeading = new TextRun('School readiness. ').bold();
        const paragraph16 = new Paragraph().style('body').spacing({after: 240});
        const run16 = new TextRun(`To assess parents’ knowledge and beliefs regarding school readiness, they were asked about what they can do now to help their young children succeed later in school. For example, they were asked about the actions that reflect strong parental involvement or how parents can improve their child’s opportunities to do well in school. Before participating in the AP/OD program, `)
        const run16a = new TextRun(`${this.state.schoolReadinessScores.prePerfectPercent}% `).color('ff0000')
        const run16b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run16c = new TextRun(`${this.state.schoolReadinessScores.postPerfectPercent}% `).color('ff0000')
        const run16d = new TextRun(`of the parents did.`);
        paragraph16.addRun(schoolHeading).addRun(run16).addRun(run16a).addRun(run16b)
        .addRun(run16c).addRun(run16d);

        const advocacyHeading = new TextRun('Advocacy. ').bold();
        const paragraph18 = new Paragraph().style('body').spacing({after: 240});
        const run18 = new TextRun(`In this final scale, parents were asked about their confidence with regards to their parenting skills, their ability to teach their children their home language and basic skills for school, and their ability to advocate for their children’s needs. Before participating in the AP/OD program, `)
        const run18a = new TextRun(`${this.state.advocacyScores.prePerfectPercent}% `).color('ff0000')
        const run18b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run18c = new TextRun(`${this.state.advocacyScores.postPerfectPercent}% `).color('ff0000')
        const run18d = new TextRun(`of the parents did.`);
        paragraph18.addRun(advocacyHeading).addRun(run18).addRun(run18a)
        .addRun(run18b).addRun(run18c).addRun(run18d);

        const confidenceHeading = new TextRun('Confidence. ').bold();
        const paragraph20 = new Paragraph().style('body').spacing({after: 240});
        const run20 = new TextRun(`In this final scale, parents were asked about their confidence with regards to their parenting skills, their ability to teach their children their home language and basic skills for school, and their ability to advocate for their children’s needs. Before participating in the AP/OD program, `)
        const run20a = new TextRun(`${this.state.confidenceScores.prePerfectPercent}% `).color('ff0000')
        const run20b = new TextRun(`of parents correctly answered all the questions in this dimension; after the program, `)
        const run20c = new TextRun(`${this.state.confidenceScores.postPerfectPercent}% `).color('ff0000')
        const run20d = new TextRun(`of the parents did.`);
        paragraph20.addRun(confidenceHeading).addRun(run20).addRun(run20a)
        .addRun(run20b).addRun(run20c).addRun(run20d);

        doc.addParagraph(startHeading);
        doc.addParagraph(startHeadingA);
        doc.addParagraph(startHeading2);
        
        doc.addParagraph(paragraph1);

        doc.addParagraph(communityParagraph);
        doc.addParagraph(communityParagraph2);

        doc.addParagraph(methodParagraph);
        doc.addParagraph(participantParagraph);
        
        doc.addParagraph(procedure);
        doc.addParagraph(procedure2);
        doc.addParagraph(resultsParagraph);

        doc.addParagraph(paragraph2);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[0].toDataURL(), 600, 350);

        doc.addParagraph(paragraph4);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[1].toDataURL(), 600, 350);

        doc.addParagraph(paragraph6);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[2].toDataURL(), 600, 350);

        doc.addParagraph(paragraph8);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[3].toDataURL(), 600, 350);

        doc.addParagraph(paragraph10);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[4].toDataURL(), 600, 350);

        doc.addParagraph(paragraph12);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[5].toDataURL(), 600, 350);

        doc.addParagraph(paragraph14);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[6].toDataURL(), 600, 350);

        doc.addParagraph(paragraph16);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[7].toDataURL(), 600, 350);

        doc.addParagraph(paragraph18);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[8].toDataURL(), 600, 350);

        doc.addParagraph(paragraph20);
        doc.createImage(document.getElementsByClassName('chartjs-render-monitor')[9].toDataURL(), 600, 350);

        const packer = new Packer();
        packer.toBlob(doc).then(blob => {
            FileSaver.saveAs(blob, `report.docx`)
        })
    }

    renderBarGraph = (data, length) => {
        return (
            <Bar className="downloadChart" style={{display: 'none'}} data={
                {
                    labels: [`Pre: ${data[1]}`, `Post: ${data[2]}`], 
                    datasets: [{label: 'Scores', data: [data[1], data[2]], backgroundColor: 'rgb(0, 75, 143)'}]
            }} options={{
                scales: {
                    xAxes: [{
                        barPercentage: 0.2
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            precision:0,
                            suggestedMax: length,
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)"
                        }
                    }]
                },
                title: {
                    display: true,
                    position: 'bottom',
                    text: `${data[0]}`,
                    fontSize: 14,
                    fontColor: 'rgb(37, 37, 37)'
                },
                legend: {
                    display: false
                }
                }} />
        )
    }
    render(){
        const nonDeletedCourses = (courses) =>  {
            return courses.reduce((acc, course) => {
                if (course.deleted) {
                    return acc 
                } else  {
                    return acc + 1
                }
            }, 0 )
        };

        const siteList = this.state.sites.map( (entry, index) => {
            const output = {
                value: index,
                text: entry.SiteName
            }
            return output;
        })

        const chartData2 = [
            ['Parenting', this.state.parentingScores.prePerfect, this.state.parentingScores.postPerfect],
            ['Early Learning', this.state.earlyLearningScores.prePerfect, this.state.earlyLearningScores.postPerfect],
            ['Language and Literacy Development', this.state.languageDevelopmentScores.prePerfect, this.state.languageDevelopmentScores.postPerfect],
            ['Nutritional and Physical Activity', this.state.nutritionAndPhysicalActivityScores.prePerfect, this.state.nutritionAndPhysicalActivityScores.postPerfect],
            ['Socioemotional Development', this.state.socioemotionalDevelopmentScores.prePerfect, this.state.socioemotionalDevelopmentScores.postPerfect],
            ['Use of Technology', this.state.useOfTechnologyScores.prePerfect, this.state.useOfTechnologyScores.postPerfect],
            ['Mathematical Development', this.state.mathematicalDevelopmentScores.prePerfect, this.state.mathematicalDevelopmentScores.postPerfect],
            ['School Readiness', this.state.schoolReadinessScores.prePerfect, this.state.schoolReadinessScores.postPerfect],
            ['Advocacy', this.state.advocacyScores.prePerfect, this.state.advocacyScores.postPerfect],
            ['Confidence', this.state.confidenceScores.prePerfect, this.state.confidenceScores.postPerfect]
        ]
        const chartData = [
            {
                'domain': 'Parenting',
                'prePerfect': this.state.parentingScores.prePerfect,
                'postPerfect': this.state.parentingScores.postPerfect
            },
            {
                'domain': 'Early Learning',
                'prePerfect': this.state.earlyLearningScores.prePerfect,
                'postPerfect': this.state.earlyLearningScores.postPerfect
            },
            {
                'domain': 'Language and Literacy Development',
                'prePerfect': this.state.languageDevelopmentScores.prePerfect,
                'postPerfect': this.state.languageDevelopmentScores.postPerfect
            },
            {
                'domain': 'Nutritional and Physical Activity',
                'prePerfect': this.state.nutritionAndPhysicalActivityScores.prePerfect,
                'postPerfect': this.state.nutritionAndPhysicalActivityScores.postPerfect
            },
            {
                'domain': 'Socioemotional Development',
                'prePerfect': this.state.socioemotionalDevelopmentScores.prePerfect,
                'postPerfect': this.state.socioemotionalDevelopmentScores.postPerfect
            },
            {
                'domain': 'Use of Technology',
                'prePerfect': this.state.useOfTechnologyScores.prePerfect,
                'postPerfect': this.state.useOfTechnologyScores.postPerfect
            },
            {
                'domain': 'Mathematical Development',
                'prePerfect': this.state.mathematicalDevelopmentScores.prePerfect,
                'postPerfect': this.state.mathematicalDevelopmentScores.postPerfect
            },
            {
                'domain': 'School Readiness',
                'prePerfect': this.state.schoolReadinessScores.prePerfect,
                'postPerfect': this.state.schoolReadinessScores.postPerfect
            },
            {
                'domain': 'Advocacy',
                'prePerfect': this.state.advocacyScores.prePerfect,
                'postPerfect': this.state.advocacyScores.postPerfect
            },
            {
                'domain': 'Confidence',
                'prePerfect': this.state.confidenceScores.prePerfect,
                'postPerfect': this.state.confidenceScores.postPerfect
            }
        ]
        return (
            <Fragment>
                <Card.Group>
                {
                    this.props.role === 'admin' ? 
                    <Card key='report' className={'extra'}>
                        <Card.Content>
                            <Card.Header key="main-report">
                                Reporting
                            </Card.Header>
                        </Card.Content>
                        <Card.Content extra>
                            <Button primary onClick={this.getMacroReport}>Raw CSV Data</Button>
                            <Button primary onClick={this.getProgramReport}>Program Report</Button>
                        </Card.Content>
                    </Card> :
                    null
                }
                {
                    /*
                    window.location.search === '?preview=true' && (this.props.role === 'admin' || this.props.role === 'orgAdmin') ? 
                    <Card key='report'>
                        <Card.Content>
                            <Card.Header key="main-report">
                                Premium Reporting
                            </Card.Header>
                        </Card.Content>
                        <Card.Content extra>
                            {
                                this.props.pTill > 0 && this.props.pTill > Date.now() ?
                                <Button positive>Additional Reports</Button> :
                                <Button positive onClick={this.togglePremiumSignUpModal}>More Reports</Button>

                            }
                        </Card.Content>
                    </Card> :
                    null
                    */
                }
                {
                    Object.keys(this.state.sortedCourses).sort( (a,b) => {
                        if (this.state.sortedCourses[a].siteName.trim().toUpperCase() < this.state.sortedCourses[b].siteName.trim().toUpperCase()) {
                            return -1
                        }
                        if (this.state.sortedCourses[a].siteName.trim().toUpperCase() > this.state.sortedCourses[b].siteName.trim().toUpperCase()) {
                            return 1
                        }
                        return 0
                    }).map( (site) => {
                        return (
                            <Card key={this.state.sortedCourses[site].siteName}>
                                <Card.Content key={`${site}card`}>
                                    <Card.Header>
                                        {this.state.sortedCourses[site].siteName}
                                    </Card.Header>
                                    <Card.Description>
                                        {nonDeletedCourses(this.state.sortedCourses[site].courses)} program{nonDeletedCourses(this.state.sortedCourses[site].courses) > 1 || this.state.sortedCourses[site].courses.length === 0 ? 's' : ''}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra key={`${site}InnerCard`}>
                                    {
                                        this.state.sortedCourses[site].courses.filter( course => !course.deleted).map( course => {
                                            return (
                                                <Button primary key={course.CourseStartDate} className="report-button" onClick={() => this.selectReport(`${course.SiteId}${course.FacilitatorId}${course.CourseStartDate}`)}>{course.PrettyStartDate}</Button>
                                            )
                                        })
                                    }
                                </Card.Content>
                            </Card>
                        )
                    })
                }
                </Card.Group>
                <Modal open={this.state.modalOpen} onClose={this.handleModalClose} size="large" closeIcon>
                    <Modal.Header>
                        Abriendo Puertas/Opening Doors, 3rd Edition Evaluation<br/>
                        {this.state.selectedOrganizationName} at {this.state.selectedSiteName} by {this.state.selectedFacilitatorName} starting on {moment(this.state.selectedStartDate).format('MMMM Do YYYY')}
                    </Modal.Header>
                    <Modal.Content>
                        { this.state.selectedReport && this.state.parentingScores.totalPost > 0 ?
                        <Fragment>
                            <h2></h2>
                                <Button onClick={this.makeDoc} className="report-download">
                                    Download Selected Report &nbsp;<Icon name="disk"/>
                                </Button>
                                {
                                this.props.role === 'admin' || this.props.role === 'orgAdmin' ?
                                <Button onClick={this.getProgramCSV} className="report-download">Download CSV</Button> :
                                null
                                }

                                <p className="test1"> <b>About Abriendo Puertas/Opening Doors</b>. Abriendo Puertas/Opening Doors (AP/OD) 
                                    is a comprehensive, 10-session parenting skills and advocacy program 
                                    developed by and for low-income parents with children ages 0 to 5. 
                                    Since its launch in 2007, it has grown rapidly in scope and impact 
                                    to meet the needs of parents nationwide. Drawing from the real-life
                                    experiences of parents, and local data about their schools and communities, 
                                    sessions are filled with interactive activities that aim to develop parents’
                                    self-understanding as powerful agents of change to improve the lives of their
                                    children. The information is presented in ways that are easy for parents
                                    to understand and act on within their daily lives. This unique curriculum
                                    builds on participants’ cultural strengths to transform the achievement
                                    gap into an opportunity to improve and enrich the lives of their children
                                    and families. 
                                </p>
                                <h2>Results</h2>
                                <p><b>Parenting</b> In this section, parents were assessed on 
                                their general knowledge about children’s learning, and setting goals 
                                to promote their children’s well-being. Topics included when children’s 
                                learning starts and how parents can improve their children’s school success.
                                Before participating in the AP/OD program, <span className="report-computed"> {this.state.parentingScores.prePerfectPercent}%</span> of parents correctly
                                answered all the questions in this dimension; after the program, <span className="report-computed"> {this.state.parentingScores.postPerfectPercent}%</span> of the parents did.
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}} id='screenShot1'>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(0,1)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(0,1)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>

                                {this.renderBarGraph(chartData2[0], this.state.confidenceScores.postScores.length)}

                                <p><b>Early learning</b> The early learning section of the survey examined parents’ general knowledge
                                about how children’s brains develop, and how young children learn. 
                                Specifically, parents were asked about the most important time period 
                                for children’s brain development, the most important areas of children’s 
                                development, and the consequences of stress on the brain. Before participating
                                in the AP/OD program, <span className="report-computed"> {this.state.earlyLearningScores.prePerfectPercent}%</span> of parents correctly answered all the questions in
                                this dimension; after the program, <span className="report-computed"> {this.state.earlyLearningScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(1,2)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(1,2)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[1], this.state.confidenceScores.postScores.length)}

                                <p>
                                    <b>Language and literacy development</b> In the language and literacy development scale, parents
                                    were assessed on their knowledge of the foundations for
                                    communication and on how to support the development of strong
                                    language and literacy skills. Parents were asked, among other
                                    questions, about the consequences of using two languages,
                                    and about the means to promote language skills. Before
                                    participating in the AP/OD program, <span className="report-computed"> {this.state.languageDevelopmentScores.prePerfectPercent}%</span> of parents correctly
                                    answered all the questions in this dimension; after the program, <span className="report-computed"> {this.state.languageDevelopmentScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(2,3)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(2,3)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[2], this.state.confidenceScores.postScores.length)}

                                <p><b>Nutrition and physical activity</b> Parents were assessed on their knowledge of basic health information
                                pertaining to diet and exercise for themselves and their children.
                                Questions focused on the consequences of spending too much time in
                                front of a screen, the benefits of playtime, and the most effective
                                ways to promote a healthy lifestyle. Before participating in the
                                AP/OD program, <span className="report-computed"> {this.state.nutritionAndPhysicalActivityScores.prePerfectPercent}%</span> of parents
                                correctly answered all the questions in this dimension; after the program, 
                                <span className="report-computed"> {this.state.nutritionAndPhysicalActivityScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(3,4)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(3,4)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[3], this.state.confidenceScores.postScores.length)}

                                <p><b>Socioemotional development</b> The AP/OD survey tapped parents’ understanding of children’s social-emotional 
                                development. Parents were asked four questions about supporting children’s
                                social-emotional development. For example, parents were asked to define 
                                positive social-emotional skills, how they can help their child learn to 
                                express her/his feelings constructively, and the impact of parental 
                                socioemotional health on their child’s development. Before participating 
                                in the AP/OD program, <span className="report-computed"> {this.state.socioemotionalDevelopmentScores.prePerfectPercent}%</span> of parents correctly answered all the 
                                questions in this dimension; after the program, <span className="report-computed"> {this.state.socioemotionalDevelopmentScores.postPerfectPercent}%</span> of the parents did. 
                                </p>
                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(4,5)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(4,5)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[4], this.state.confidenceScores.postScores.length)}

                                <p><b>Use of technology</b> On this scale, parents were asked how they can ensure their children
                                    benefit from what technology has to offer. Topics included good practices
                                    around the use of technology, and criteria for choosing shows and apps for 
                                    children. Before participating in the AP/OD program, <span className="report-computed"> {this.state.useOfTechnologyScores.prePerfectPercent}%</span> of parents correctly 
                                    answered all the questions in this dimension; after the program, <span className="report-computed"> {this.state.useOfTechnologyScores.postPerfectPercent}%</span> of the 
                                    parents did. 
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(5,6)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(5,6)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[5], this.state.confidenceScores.postScores.length)}

                                <p><b>Mathematical development</b> The mathematical development section examined parents’ knowledge
                                    of children’s mathematical learning, and on the promotion of strong
                                    mathematical skills through everyday activities. Questions included
                                    the age at which age children start developing mathematical abilities
                                    and defining some essential math skills. Before participating in
                                    the AP/OD program, <span className="report-computed"> {this.state.mathematicalDevelopmentScores.prePerfectPercent}%</span> of parents correctly answered all the
                                    questions in this dimension; after the program, <span className="report-computed"> {this.state.mathematicalDevelopmentScores.postPerfectPercent}%</span> of the
                                    parents did. 
                                </p>

                                <div height="300px">
                                    <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(6,7)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(6,7)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[6], this.state.confidenceScores.postScores.length)}

                                <p><b>School readiness</b> To assess parents’ knowledge and beliefs regarding school readiness,
                                    they were asked about what they can do now to help their young 
                                    children succeed later in school. For example, they were asked about 
                                    the actions that reflect strong parental involvement or how parents 
                                    can improve their child’s opportunities to do well in school. Before 
                                    participating in the AP/OD program, <span className="report-computed"> {this.state.schoolReadinessScores.prePerfectPercent}%</span> of parents correctly 
                                    answered all the questions in this dimension; after the program, 
                                    <span className="report-computed"> {this.state.schoolReadinessScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(7,8)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(7,8)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[7], this.state.confidenceScores.postScores.length)}

                                <p><b>Advocacy</b> Parents’ knowledge of their rights in advocating for their
                                    child was evaluated. Specifically, parents were asked about
                                    their rights to be involved in school-related decision-making;
                                    the practices that can help them advocate for their children; 
                                    and the possible consequences of acting on such practices. 
                                    Before participating in the AP/OD program, <span className="report-computed"> {this.state.advocacyScores.prePerfectPercent}%</span> of parents 
                                    correctly answered all the questions in this dimension; after 
                                    the program, <span className="report-computed"> {this.state.advocacyScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(8,9)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(8,9)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[8], this.state.confidenceScores.postScores.length)}

                                <p><b>Confidence</b> In this final scale, parents were asked about their confidence 
                                    with regards to their parenting skills, their ability to teach 
                                    their children their home language and basic skills for school, 
                                    and their ability to advocate for their children’s needs. Before 
                                    participating in the AP/OD program, <span className="report-computed"> {this.state.confidenceScores.prePerfectPercent}%</span> of parents correctly 
                                    answered all the questions in this dimension; after the program, 
                                    <span className="report-computed"> {this.state.confidenceScores.postPerfectPercent}%</span> of the parents did. 
                                </p>

                                <div height="300px">
                                <VictoryChart domainPadding={30} maxDomain={{ y: this.state.parentingScores.totalPost}}>
                                        <VictoryGroup offset={100} colorScale="blue">
                                            <VictoryBar data={chartData.slice(9,10)} x="domain" y="prePerfect" labels={(d) => `Pre: ${d.prePerfect}`}/>
                                            <VictoryBar data={chartData.slice(9,10)} x="domain" y="postPerfect" labels={(d) => `Post: ${d.postPerfect}`}/>
                                        </VictoryGroup>
                                    </VictoryChart>
                                </div>
                                {this.renderBarGraph(chartData2[9], this.state.confidenceScores.postScores.length)}
      
                                <p>Results {this.state.confidenceScores.postScores.length} full participants</p>

                        </Fragment> :
                        <Fragment>
                            <p>{this.state.numberOfPreSurveys} pre survey{this.state.numberOfPreSurveys > 1 || this.state.numberOfPreSurveys === 0 ? 's' : ''} collected | {this.state.numberOfPostSurveys} post survey{this.state.numberOfPostSurveys > 1 || this.state.numberOfPostSurveys === 0 ? 's' : ''} collected</p>
                            <p>Not enough data for analysis</p>
                        </Fragment>
                        }    

                    </Modal.Content>

                </Modal>
                <PremiumSignUpModal open={this.state.premiumSignUpModalOpen} onClose={this.togglePremiumSignUpModal} token={this.props.token}></PremiumSignUpModal>
            </Fragment>
        )
    }
}

export default ReportDashboard;