import React, {Component} from 'react';
import {Form, Header, Button} from 'semantic-ui-react';

class MultipleChoiceQuestion extends Component {
    state = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    }


    buttonHandler = (e, {value}) => {
        this.props.answerQuestion(this.props.id, value);
        e.target.blur()
    }

    formSubmit = (e) => {
        e.preventDefault();
    }
    render(){
        return(
        <Form size="big">
        <Header as="h3" className="question-prompt">{this.props.language === 'en' ? 'Question ' : 'Pregunta '}{this.props.questionNumber}. &nbsp;{this.props.prompt[this.props.language]}</Header>
        {
            this.props.responses.map( (response, index) => {
                return (
                    <Form.Field key={index}>
                        <Button basic type="button" value={index} fluid size="large" onClick={this.buttonHandler} content={response.response[this.props.language]}/>
                    </Form.Field>
                )
            })
        }
        </Form>
        )
    }
}

export default MultipleChoiceQuestion;