import React, {Component, Fragment} from 'react';
import {Card, Icon, Button, Modal,Dimmer, Loader, Input, Radio} from 'semantic-ui-react';
import axios from 'axios';
import qs from 'qs';

class UserDashboard extends Component{
    state = {
        users: [],
        loading: true,
        disableModalOpen: false,
        enableModalOpen: false,
        resetModalOpen: false,
        selectedId: '',
        filter: '',
        activatedFilter: false
    }

    triggerDisable = (id) => {
        this.setState({
            disableModalOpen: true,
            selectedId: id
        })
    }

    triggerEnable = (id) => {
        this.setState({
            enableModalOpen: true,
            selectedId: id
        })
    }

    triggerReset = (id) => {
        this.setState({
            resetModalOpen: true,
            selectedId: id
        })
    }

    toggleDisableModal = ()  => {
        this.setState({
            disableModalOpen: !this.state.disableModalOpen,
            selectedId: ''
        })
    }

    toggleEnableModal = ()  => {
        this.setState({
            enableModalOpen: !this.state.enableModalOpen,
            selectedId: ''
        })
    }

    toggleResetModal = ()  => {
        this.setState({
            resetModalOpen: !this.state.resetModalOpen,
            selectedId: ''
        })
    }

    disableUser = () => {
        const userUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/users/${this.state.selectedId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.delete(userUrl, config).then( res => {
            this.toggleDisableModal();
            this.fetchUsers();
        });
    }

    enableUser = () => {
        const userUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/users/${this.state.selectedId}`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.put(userUrl, {}, config).then( res => {
            this.toggleEnableModal();
            this.fetchUsers();
        });
    }

    resetUser = () => {
        console.log('Enabling...');
        const userUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/users/${this.state.selectedId}/reset`
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        axios.put(userUrl, {}, config).then( res => {
            this.toggleResetModal();
            this.fetchUsers();
        });
    }
    fetchUsers = () => {
        const userUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/users'
        const orgUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/organizations'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        function getMoreUsers(userCount, users, paginationToken){
            return axios.get(userUrl, {...config, params: {pagination: paginationToken}}).then( res => {
                const newPagination = res.data.PaginationToken;
                const newUsers = res.data.Users
                const concatUsers = users.concat(newUsers)

                if (userCount > concatUsers.length) {
                    return getMoreUsers(userCount, concatUsers, newPagination)
                } else {
                    return Promise.resolve(concatUsers)
                }
            });
        }

        axios.get(userUrl, config).then( (response) => {
            const UserCount = response.data.UserCount
            let users = response.data.Users || []
            if ( UserCount >= users.length) {
                getMoreUsers(UserCount, users, response.data.PaginationToken).then( moreUsers => {
                    axios.get(orgUrl, config).then( orgResponse => {
                        const orgs = orgResponse.data.Items

                        const formattedOrgs = orgs.reduce((prev, current) => {
                            prev[current.id] = current.name;
                            return prev
                        }, {})
                        var formattedUsers = moreUsers.map((value, index) => {
                            var user = {}
                            value.Attributes.map((attrib) => {
                                if (attrib.Name === 'custom:org') {
                                    user[attrib.Name] = formattedOrgs[attrib.Value]
                                } else {
                                    user[attrib.Name] = attrib.Value
                                }
                            });
                            user.Enabled = value.Enabled;
                            user.Status = value.UserStatus === 'CONFIRMED' ? 'Confirmed' : 'Not Confirmed'
                            return user
                        })
                        this.setState({
                            users: formattedUsers,
                            loading: false
                        })
                    })
                })
            } else {
                axios.get(orgUrl, config).then( orgResponse => {
                    const orgs = orgResponse.data.Items

                    const formattedOrgs = orgs.reduce((prev, current) => {
                        prev[current.id] = current.name;
                        return prev
                    }, {})
                    var formattedUsers = users.map((value, index) => {
                        var user = {}
                        value.Attributes.map((attrib) => {
                            if (attrib.Name === 'custom:org') {
                                user[attrib.Name] = formattedOrgs[attrib.Value]
                            } else {
                                user[attrib.Name] = attrib.Value
                            }
                        });
                        user.Enabled = value.Enabled;
                        user.Status = value.UserStatus === 'CONFIRMED' ? 'Confirmed' : 'Not Confirmed';
                        return user
                    })
                    this.setState({
                        users: formattedUsers,
                        loading: false
                    })
                })
            }
        })
    }

    componentDidMount(){
        this.fetchUsers();
    }


    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    handleFilterChange = (event, {name, value}) => {
        this.setState({filter: value})
    }

    handleActivatedFilterChange = () => {
        this.setState({activatedFilter: !this.state.activatedFilter})
    }


    userFilter = () => {
        if (this.state.activatedFilter) {
            return this.state.users.length > 1 ? this.state.users.filter( user => !user.Enabled) : this.state.users
        } else
        {
            return this.state.users.length > 1 && this.state.filter ? this.state.users.filter( user => user.email.includes(this.state.filter)) : this.state.users
        }
    }
    render(){
        const filteredUsers = this.userFilter()
        return (
            <Fragment>
                <Dimmer active={this.state.loading} page>
                    <Loader/>
                </Dimmer>
                <Card.Group>
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                User Information
                            </Card.Header>
                            <Card.Description>
                                Currently {this.state.users.length} Users
                            </Card.Description>
                            <Radio toggle fluid onChange={this.handleActivatedFilterChange} checked={this.state.activatedFilter} label="Show only Unactivatted" name="activatedFilter"/>
                        </Card.Content>
                        <Card.Content extra>
                            <Input fluid onChange={this.handleFilterChange} action="Filter By Email" name="emailFilter" disabled={this.state.activatedFilter}/>
                        </Card.Content>
                    </Card>
                    {
                        this.state.activatedFilter && filteredUsers.length > 1 ?
                        <Card>
                            <Card.Header>
                                Organizations with Unactivated Users
                            </Card.Header>
                            <Card.Description>
                                <ul>
                                    {
                                        [...filteredUsers].reduce( (acc, user) => {const org = user['custom:org']
                                    if (acc.indexOf(org) > -1) {
                                        return acc
                                    } else {
                                        acc.push(org)
                                        return acc
                                    }
                                }, []).map( org => {
                                    return <li>{org}</li>
                                })

                                    }
                                </ul>
                            </Card.Description>
                        </Card> :
                        null
                    }

                    { 
                        [...filteredUsers].sort( (a,b) => {
                            if (a['given_name'].toUpperCase() < b['given_name'].toUpperCase()) {
                                return -1
                            }
                            if (a['given_name'].toUpperCase() > b['given_name'].toUpperCase()) {
                                return 1
                            }
                            return 0
                            }
                            ).map( (user, index) => {
                        if (user) {
                        return (
                        <Card key={index} style={{textAlign: 'left'}} color={user.Enabled ? null : 'red'}>
                            <Card.Content>
                                <Card.Header>
                                    {user['given_name']} {user['family_name']} {user.Enabled ? '' : <Icon name="dont" color="red"/>}
                                </Card.Header>
                                <p>{user['custom:org']}</p>
                                <p>{user.email}</p>
                                <p>{user['custom:role']}</p>
                                <p>Status: {user.Status}</p>
                            </Card.Content>
                            <Card.Content extra style={{textAlign: 'center'}}>
                                {
                                    user.Enabled ? 
                                    <Button color="yellow" onClick={() => {this.triggerDisable(user['sub'])}}>Disable</Button>
                                    :
                                    <Button color="green" onClick={() => {this.triggerEnable(user['sub'])}}>Enable</Button>
                                }
                                {/* <Button negative>Delete</Button> */}
                                <Button color="orange" onClick={() => this.triggerReset(user['sub'])}>Reset</Button>
                            </Card.Content>
                        </Card>
                        )
                        }
                    })
                    }
                </Card.Group>
                <Modal open={this.state.disableModalOpen} onClose={this.toggleDisableModal}>
                    <Modal.Header>Are you Sure?</Modal.Header>
                    <Modal.Content>
                        Confirm Disable
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" onClick={this.disableUser}>
                        <Icon name='checkmark' /> Yes, Disable
                        </Button>
                        <Button color="red" onClick={this.toggleDisableModal}>
                        <Icon name='remove' /> No
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.enableModalOpen} onClose={this.toggleEnableModal}>
                    <Modal.Header>Are you Sure?</Modal.Header>
                    <Modal.Content>
                        Confirm Enable
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" onClick={this.enableUser}>
                        <Icon name='checkmark' /> Yes, Restore User
                        </Button>
                        <Button color="red" onClick={this.toggleEnableModal}>
                        <Icon name='remove' /> No
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.resetModalOpen} onClose={this.toggleResetModal}>
                    <Modal.Header>Are you Sure?</Modal.Header>
                    <Modal.Content>
                        Confirm Reset
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" onClick={this.resetUser}>
                        <Icon name='checkmark'/> Yes, Reset
                        </Button>
                        <Button colorr="red" onClick={this.toggleResetModal}>
                        <Icon name='remove' /> No
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default UserDashboard;