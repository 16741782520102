import React, {Component, Fragment} from 'react';
import {Form, Button, Select, Icon, Modal} from 'semantic-ui-react';
import uuid from 'uuid/v4';
import axios from 'axios';
import StateList from './StateList';

class updateSiteForm extends Component{
    state = {
        name: this.props.site.SiteName || '',
        addressLine1: (this.props.site.address1 != "null" && this.props.site.address1) || '',
        addressLine2: (this.props.site.address2 != "null" && this.props.site.address2) || '',
        city: (this.props.site.city != "null" && this.props.site.city) || '',
        state: this.props.site.StateCode || '',
        zipcode: (this.props.site.zipcode != "null" && this.props.site.zipcode) || '',
        selectedProgram: {},
        selectedProgramIndex: '',
        showProgramDeleteConfirmation: false

    }

    updateSite = (event) => {
        event.preventDefault();
        const updateSiteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites`;
        const payload = {
            name: this.state.name,
            siteId: this.props.site.SiteId,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode,
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.put(updateSiteUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal();
        }).catch( e => {
            console.log(e);
        })
    }

    toggleProgramDeleteConfirmation = () => {
        this.setState({
            showProgramDeleteConfirmation: !this.state.showProgramDeleteConfirmation
        })
    }
    
    handleRemoveProgram = (index) => {
        const Programs = this.props.site.Courses;
        const selectedProgram = Programs[index]
        this.setState({
            selectedProgram: selectedProgram,
            selectedProgramIndex: index,
            showProgramDeleteConfirmation: true
        })
    }

    toggleSiteDeleteConfirmation = () => {
        this.setState({showSiteDeleteConfirmation: !this.state.showSiteDeleteConfirmation})
    }

    removeSite = (event) => {
        event.preventDefault();
        const updateSiteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites`;
        const payload = {
            siteId: this.props.site.SiteId,
            deleted: true
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.put(updateSiteUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal();
        }).catch( e => {
            console.log(e);
        })
    }

    deleteCourse = (index) => {
        const updateSiteUrl = `https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/site`;
        const payload = {
            siteId: this.props.site.SiteId,
            index: index
        }
        const headers = {
            "Authorization": `Bearer ${this.props.token}`
        }
        axios.put(updateSiteUrl, payload, {headers: headers}).then( res => {
            this.props.closeModal();
        }).catch( e => {
            console.log(e);
        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    render(){
        const formEnabled = this.state.name && this.state.state;
        const courses = this.props.site.Courses;
        return (
        <Fragment>
            <Form onSubmit={this.updateSite}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Site Name (required)" name="name" value={this.state.name} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 1" name="addressLine1" value={this.state.addressLine1} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="Address Line 2" name="addressLine2" value={this.state.addressLine2} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input fluid label="City" name="city" value={this.state.city} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>State (required)</label>
                        <Select required fluid options={StateList} name="state" value={this.state.state} onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid label="Zipcode" name="zipcode" value={this.state.zipcode} onChange={this.handleChange}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <label>Programs</label>
                    </Form.Field>
                    <Form.Field>
                        { 
                            courses && courses.map( (course, index) => {
                                return course.deleted ? null : <Button size="large" type="button" key={index} onClick={() => this.handleRemoveProgram(index)}>{course.FacilitatorName} on {course.PrettyStartDate} &nbsp;&nbsp;&nbsp; <Icon onClick={() => this.handleRemoveProgram(index)} name="delete calendar"/></Button>
                            })
                        }
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <Button primary type="submit" content="Save Changes" disabled={!formEnabled}/>
                    <Button negative type="button" onClick={this.toggleSiteDeleteConfirmation} content="Remove Site" />
                </Form.Field>
            </Form>
            <Modal open={this.state.showProgramDeleteConfirmation}>
                <Modal.Header content={`${this.state.selectedProgram && this.state.selectedProgram.FacilitatorName} on ${this.state.selectedProgram && this.state.selectedProgram.PrettyStartDate}`}/>
                <Modal.Content>
                    <p>Are you sure you want to disable this program?</p>
                    <Button negative type="button" onClick={() => this.deleteCourse(this.state.selectedProgramIndex)}>Disable</Button>
                    <Button positive type="button" onClick={this.toggleProgramDeleteConfirmation}>No</Button>
                </Modal.Content>
            </Modal>
            <Modal open={this.state.showSiteDeleteConfirmation} onClose={this.toggleSiteDeleteConfirmation}>
                <Modal.Header>
                    Confirm Site Removal
                </Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to disable this program?</p>
                    <Button negative type="button" onClick={this.removeSite}>Disable</Button>
                    <Button positive type="button" onClick={this.toggleSiteDeleteConfirmation}>No</Button>
                </Modal.Content>
            </Modal>
            </Fragment>
        )
    }
}

export default updateSiteForm;