const stateList = [
    {
        text: "Alabama",
        value: "AL",
        key: "AL"
    },
    {
        text: "Alaska",
        value: 'AK',
        key: "AK"
    },
    {
        text: "American Samoa",
        value: 'AS',
        key: "AS"
    },
    {
        text: "Arizona",
        value: 'AZ',
        key: "AZ"
    },
    {
        text: "Arkansas",
        value: "AR",
        key: "AR"
    },
    {
        text: "California",
        value: "CA",
        key: "CA"
    },
    {
        text: "Colorado",
        value: "CO",
        key: "CO"
    },
    {
        text: "Connecticut",
        value: "CT", 
        key: "CT"
    },
    {
        text: "Delaware",
        value: "DE", 
        key: "DE"
    },
    {
        text: "District Of Columbia",
        value: "DC", 
        key: "DC"
    },
    {
        text: "Federated States Of Micronesia",
        value: "FM", 
        key: "FM"
    },
    {
        text: "Florida",
        value: "FL",
        key: "FL"
    },
    {
        text: "Georgia",
        value: "GA",
        key: "GA"
    },
    {
        text: "Guam",
        value: "GU",
        key: "GU"
    },
    {
        text: "Guatemala",
        value: "GT",
        key: "GT"
    },
    {
        text: "Hawaii",
        value: "HI",
        key: "HI"
    },
    {
        text: "Idaho",
        value: "ID",
        key: "ID"
    },
    {
        text: "Illinois",
        value: "IL",
        key: "IL"
    },
    {
        text: "Indiana",
        value: "IN",
        key: "IN"
    },
    {
        text: "Iowa",
        value: 'IA',
        key: "IA"
    },
    {
        text: "Kansas",
        value: "KS",
        key: "KS"
    },
    {
        text: "Kentucky",
        value: "KY",
        key: "KY"
    },
    {
        text: "Louisiana",
        value: "LA", 
        key: "LA"
    },
    {
        text: "Maine",
        value: "ME", 
        key: "ME"
    },
    {
        text: "Marshall Islands",
        value: "MH", 
        key: "MH"
    },
    {
        text: "Maryland",
        value: "MD", 
        key: "MD"
    },
    {
        text: "Massachusetts",
        value: "MA", 
        key: "MA"
    },
    {
        text: "Michigan",
        value: "MI",
        key: "MI"
    },
    {
        text: "Minnesota",
        value: "MN",
        key: "MN"
    },
    {
        text: "Mississippi",
        value: "MS", 
        key: "MS"
    },
    {
        text: "Missouri",
        value: "MO", 
        key: "MO"
    },
    {
        text: "Montana",
        value: "MT",
        key: "MT"
    },
    {
        text: "Nebraska",
        value: "NE",
        key: "NE"
    },
    {
        text: "Nevada",
        value: "NV",
        key: "NV"
    },
    {
        text: "New Hampshire",
        value: "NH",
        key: "NH"
    },
    {
        text: "New Jersey",
        value: "NJ", 
        key: "NJ"
    },
    {
        text: "New Mexico",
        value: "NM", 
        key: "NM"
    },
    {
        text: "New York",
        value: "NY",
        key: "NY"
    },
    {
        text: "North Carolina",
        value: "NC",
        key: "NC"
    },
    {
        text: "North Dakota",
        value: "ND",
        key: "ND"
    },
    {
        text: "Northern Mariana Islands",
        value: "MP",
        key: "MP"
    },
    {
        text: "Ohio",
        value: "OH", 
        key: "OH"
    },
    {
        text: "Oklahoma",
        value: "OK",
        key: "OK"
    },
    {
        text: "Oregon",
        value: "OR",
        key: "OR"
    },
    {
        text: "Palau",
        value: "PW",
        key: "PW"
    },
    {
        text: "Pennsylvania",
        value: "PA",
        key: "PA"
    },
    {
        text: "Puerto Rico",
        value: "PR",
        key: "PR"
    },
    {
        text: "Rhode Island",
        value: "RI",
        key: "RI"
    },
    {
        text: "South Carolina",
        value: "SC",
        key: "SC"
    },
    {
        text: "South Dakota",
        value: "SD",
        key: "SD"
    },
    {
        text: "Tennessee",
        value: "TN",
        key: "TN"
    },
    {
        text: "Texas",
        value: "TX",
        key: "TX"
    },
    {
        text: "Utah",
        value: "UT",
        key: "UT"
    },
    {
        text: "Vermont",
        value: "VT",
        key: "VT"
    },
    {
        text: "Virgin Islands",
        value: "VI",
        key: "VI"
    },
    {
        text: "Virginia",
        value: "VA",
        key: "VA"
    },
    {
        text: "Washington",
        value: "WA",
        key: "WA"
    },
    {
        text: "West Virginia",
        value: "WV",
        key: "WV"
    },
    {
        text: "Wisconsin",
        value: "WI",
        key: "WI"
    },
    {
        text: "Wyoming",
        value: "WY",
        key: "WY"
    }
]

export default stateList;