import React, {Component} from 'react';
import {Form, Header, Button} from 'semantic-ui-react';

class MultipleChoicePlusOtherQuestion extends Component {
    state = {
        extended: false,
        otherValue: '',
        firstSet: this.props.responses.slice(0,4),
        secondSet: this.props.responses.slice(4),
        id: this.props.id
    }    

    buttonHandler = (index) => {
        this.props.answerQuestion(this.props.id, index);
    }

    formSubmit = (e) => {
        e.preventDefault();
    }

    changeOptions = () => {
        this.setState({extended: !this.state.extended})
    }

    setQuestions = () => {
        this.currentSet = this.state.extended ? this.state.secondSet : this.state.firstSet;
    }

    handleOtherClick = () => {
        this.props.answerQuestion(this.props.id, this.state.otherValue);
    }

    otherQuestion = () => {
        return(
            <Form.Field>
                <Form.Input value={this.state.otherValue} type="text" fluid size="large" placeholder="More Options" onChange={this.handleOtherChange}/>
                <Form.Button type="button" content="Enter" onClick={this.handleOtherClick}/>
            </Form.Field>
        )
    }

    handleOtherChange = (event, {value}) => {
        this.setState({otherValue: value});
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.id !== this.state.id) {
          this.setState({ 
            extended: false,
            otherValue: '',
            firstSet: nextProps.responses.slice(0,4),
            secondSet: nextProps.responses.slice(4),
          });
        }
      }

    render(){
        return(
        <Form size="big">
        <Header as="h3" className="question-prompt">{this.props.language === 'en' ? 'Question ' : 'Pregunta '}{this.props.questionNumber}. &nbsp;{this.props.prompt[this.props.language]}</Header>
        { this.setQuestions() }
        {
            this.currentSet.map( (response, index) => {
                return (
                    <Form.Field key={index}>
                        <Button type="button" fluid size="large" onClick={() => this.buttonHandler(index)} content={response.response[this.props.language]}/>
                    </Form.Field>
                )
            })
        }
        {
            this.state.extended ? this.otherQuestion() : null
        }
        <Form.Field>
            <Button basic type="button" fluid size="large" positive onClick={() => this.changeOptions()} content={this.props.language === 'en' ? 'More Options' : 'Más Opciones'}></Button>
        </Form.Field>
      </Form>
        )
    }
}

export default MultipleChoicePlusOtherQuestion;