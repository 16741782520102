import React, {Component, Fragment} from 'react';
import SiteDashboard from './SiteDashboard';
import ReportDashboard from './ReportDashboard';
import OrgDashboard from './OrganizationDashboard';
import UserDashboard from './UserDashboard';
import AdvancedDashboard from './AdvancedDashboard';
import ProgramDashboard from './ProgramDashboard'

import {Menu, Container, Header, Icon} from 'semantic-ui-react';

const previewOrganizations = ['3e8af657-91d7-4c96-8156-c1b44b71bac4',
'18b75f2a-0ea0-46c9-9508-294067202fa2',
'aa303936-d0cc-4989-8592-f57d4a3a5a1d',
'2b4056fa-6c66-439c-9b86-f2b66bb75412',
'04cd6ecd-80e2-43ac-a122-dc8aa036243b',
'88336b5d-48df-483b-980b-d7f1bb2dab50',
'77fdc65f-4cae-495c-8a29-e15a389c58e3',
'ef6e5e17-fd6b-4010-ab04-9ec1b3128056',
'a078504e-ac3c-4639-b479-1009cdcacd0b'
]

class AdminDash extends Component{
    state = {
        currentPage: 'reports'
    }

    setPage = (page) => {
        this.setState({
            currentPage: page
        })
    }

    dashboardRenderer = () => {
        switch (this.state.currentPage) {
            default:
                return <SiteDashboard token={this.props.token} role={this.props.role} org={this.props.org} name={this.props.name}/>
            case 'reports':
                return <ReportDashboard token={this.props.token} role={this.props.role} org={this.props.org} pTill={this.props.pTill}/>
            case 'programs':
                return <ProgramDashboard token={this.props.token} role={this.props.role} org={this.props.org}/>
            case 'organizations':
                return <OrgDashboard token={this.props.token} role={this.props.role} org={this.props.org} pTill={this.props.pTill}/>
            case 'users':
                return <UserDashboard token={this.props.token}/>
            case 'advanced':
                return <AdvancedDashboard token={this.props.token} org={this.props.org} role={this.props.role}/>
        }
    }
    render(){
        const showPreview = previewOrganizations.indexOf(this.props.org) > -1 || window.location.search === '?preview=true';
        return (
            <Fragment>
                <Menu fixed="top" inverted>
                    {
                        this.props.role === 'facilitator' ?
                        null : 
                    <Menu.Item active={this.state.currentPage === 'sites'} onClick={() => this.setPage('sites')}>
                        Sites
                    </Menu.Item>
                    }
                    <Menu.Item onClick={() => this.setPage('reports')} active={this.state.currentPage === 'reports'}>
                        Reports
                    </Menu.Item>                    
                    <Menu.Item onClick={() => this.setPage('programs')} active={this.state.currentPage === 'programs'}>
                        Programs
                    </Menu.Item>
                    {
                        (showPreview && this.props.role !== 'facilitator')? 
                        <Menu.Item active={this.state.currentPage === 'advanced'} onClick={() => this.setPage('advanced')}>Premium Reports</Menu.Item> :
                        null
                    }
                    {
                        this.props.role !== 'admin' ?
                        null :
                        <Fragment>
                            <Menu.Item onClick={() => this.setPage('organizations')} active={this.state.currentPage === 'organizations'}>
                                Organizations
                            </Menu.Item>
                            <Menu.Item onClick={() => this.setPage('users')} active={this.state.currentPage === 'users'}>
                                Users
                            </Menu.Item>
                        </Fragment> 
                    }
                    <Menu.Item onClick={() => window.location.reload()}>
                        Logout
                    </Menu.Item>
                </Menu>
                <Container style={{ marginTop: '5em' }}>
                <Header as="h2">
                    <Icon name="group"/>
                        <Header.Content>
                        {this.props.orgName}
                        </Header.Content>
                    </Header>
                    {this.dashboardRenderer()}
                </Container>
            </Fragment>
        )
    }
}

export default AdminDash;