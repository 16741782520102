import React, {Component} from 'react';
import preSurveyV3 from './mockPreSurvey';
import preSurveyV4 from './preSurveyv4';
import postSurvey from './postSurvey';

import axios from 'axios';
import {Segment, Container, Header, Form, Button, Progress} from 'semantic-ui-react';

import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import MultipleChoiceMultipleAnswerQuestion from './MultipleChoiceMultipleAnswerQuestion';
import TrueOrFalseQuestion from './TrueOrFalseQuestion';
import MultipleChoiceExtendedQuestion from './MultipleChoiceExtendedQuestion';
import MultipleChoicePlusOtherQuestion from './MultipleChoicePlusOtherQuestion';

class Survey extends Component {
    state = {
        currentQuestion: -1,
        saved: false
    }

    survey = preSurveyV4

    componentWillMount(){
        if (this.props.preSurveyCompleted && this.survey.questions.length < 41 ){
            this.survey.questions = this.survey.questions.concat(postSurvey.questions);
        } else {
            this.survey = preSurveyV4
        }
    }

    answerQuestion = (id, index) => {
        this.setState({[id]: index});
        this.incrementQuestionCount();
    }

    handleBack = () => {
        this.decrementQuestionCount();
    }

    createQuestion = () => {
        if (this.props.postSurveyCompleted) {
            return (
                <Form size="big" onSubmit={this.props.reset}>
                <Header as="h2">Thank you for completing the pre and post survey</Header>
                <Form.Field>
                    <Button type="button" onClick={this.props.reset} positive fluid size="large" content="Return Home"/>
                </Form.Field>
              </Form>
            )
        }

        if (this.state.currentQuestion === -1 && !this.props.preSurveyCompleted) {
            return (
                <Form>
                    {this.props.language === 'en'?
                    <p>You are about to begin the Session 1 PRE-survey. If you have already completed this survey, please inform your facilitator.</p> :
                    <p>Usted comenzara la PRE-encuesta de la Sesión 1. Si ya ha completado esta encuesta, informe a su facilitador.</p>
                    }
                    <Button basic type="button" onClick={this.incrementQuestionCount} positive fluid size="large" content={this.props.language === 'en' ? 'Continue' : 'Continuar'}/>
                </Form>
            )
        }

        if (this.state.currentQuestion === -1 && this.props.preSurveyCompleted) {
            return (
                <Form>
                    { this.props.language === 'en' ?
                    <p>You are about to begin the post Session 10 POST-SURVEY.</p> :
                    <p>Usted comenzara la Encuesta POSTERIOR de la Sesión 10.</p>
                    }
                    <Button basic type="button" onClick={this.incrementQuestionCount} positive fluid size="large" content={this.props.language === 'en' ? 'Continue' : 'Continuar'}/>
                </Form>
            )
        }

        if (this.state.currentQuestion >= this.survey.questions.length) {
            return (
                <Form size="big" onSubmit={this.props.reset}>
                {
                    this.state.saved ?
                    <Header as="h2">{this.props.language === 'en' ? 'Thank you for completing the survey' : 'Respuestas Guardadas'}</Header> :
                    null                
                }
                <Form.Field>
                {
                    this.state.saved ?
                    <Button basic type="button" onClick={this.props.reset} positive fluid size="large" content={this.props.language === 'en' ? 'Home' : 'Regressar a Página de Inicio'}/> :
                    <Button basic type="button" onClick={this.savePreSurvey} positive fluid size="large" content={this.props.language === 'en' ? 'Click Here to submit your survey' : 'Enviar La Encuesta'}/>
                }
                </Form.Field>
              </Form>
            )
        }
        const question = this.survey.questions[this.state.currentQuestion];
        const questionType = question.type;
        const questionPrompt = question.prompt;
        const questionResponses = question.responses;
        const questionId = question.id
        switch (questionType) {
            case 'multipleChoice':
                return (
                    <MultipleChoiceQuestion prompt={questionPrompt} 
                    responses={questionResponses} id={questionId} 
                    answerQuestion={this.answerQuestion}
                    language={this.props.language}
                    questionNumber={this.state.currentQuestion + 1}/>
                )
            case 'trueOrFalse':
                return (
                <TrueOrFalseQuestion prompt={questionPrompt} 
                id={questionId} answerQuestion={this.answerQuestion}
                language={this.props.language}
                questionNumber={this.state.currentQuestion + 1}/>
                )
            case 'multipleChoiceMultipleAnswer':
                return (
                    <MultipleChoiceMultipleAnswerQuestion prompt={questionPrompt}
                    responses={questionResponses} id={questionId}
                    answerQuestion={this.answerQuestion}
                    language={this.props.language}
                    questionNumber={this.state.currentQuestion + 1}/>
                )
            case 'multipleChoiceExtended':
                return (
                    <MultipleChoiceExtendedQuestion prompt={questionPrompt}
                    responses={questionResponses} id={questionId}
                    answerQuestion={this.answerQuestion}
                    language={this.props.language}
                    questionNumber={this.state.currentQuestion + 1}/>
                )
            case 'multipleChoicePlusOther':
                return (
                    <MultipleChoicePlusOtherQuestion prompt={questionPrompt}
                    responses={questionResponses} id={questionId}
                    answerQuestion={this.answerQuestion}
                    language={this.props.language}
                    questionNumber={this.state.currentQuestion + 1}/>
                )
        }
    }


    incrementQuestionCount = () => {
        let count = this.state.currentQuestion;
        count++;
        this.setState({currentQuestion: count});
    }

    decrementQuestionCount = () => {
        let count = this.state.currentQuestion;
        count--;
        this.setState({currentQuestion: count});
    }

    compileSurveyAnswers = () => {
        return Object.keys(this.state).map(
            (key) => {
                    let entry = {};
                    entry[key] = this.state[key];
                    return entry
            }
        ).filter((value) => value.hasOwnProperty('currentQuestion') === false)
    }
    savePreSurvey = () => {
        const preSurveyUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/pre-survey';
        const postSurveyUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/post-survey'
        let payload = {
            birthday: this.props.birthday,
            date: this.props.courseDate,
            siteId: this.props.siteId,
            facilitatorId: this.props.facilitatorId,
        };
        const postURL = this.props.preSurveyCompleted ? postSurveyUrl : preSurveyUrl;
        if (this.props.preSurveyCompleted) {
            payload.postSurveyAnswers = this.compileSurveyAnswers();
        } else {
            payload.preSurveyAnswers = this.compileSurveyAnswers();
        }

        axios.post(postURL, payload).then((res) => {
            this.setState({
                saved: true
            })
        })
    }

    backButton = () => {
        if (this.state.currentQuestion < this.survey.questions.length
            && this.state.currentQuestion > 0
            && !this.props.postSurveyCompleted) {
                return (
                    <Form.Field className="back-button">
                        <a onClick={this.handleBack}>({this.props.language === 'en' ? 'back' : 'atrás'})</a>
                    </Form.Field>
                )
            }
    }

    surveyLabel = () => {
        if (this.props.preSurveyCompleted) {
            if (this.props.language === 'en') {
                return 'Session 10 Post Survey'
            } else {
                return 'Sesión 10 Post-Encuesta'
            }
        } else {
            if (this.props.language === 'en') {
                return 'Session 1 Pre-Survey'
            } else {
                return 'Sesión 1 Pre-Encuesta'
            }
        }
    }

    render() {
        return(
            <Container>
                <Header as="h2" className="survey-notice">{this.surveyLabel()}</Header>
                <Segment raised className="survey-container branded">
                    {this.createQuestion()}
                    {this.backButton()}
                <Progress percent={(this.state.currentQuestion / this.survey.questions.length) * 100} color="green"/>
                </Segment>
            </Container>
        )
    }
}

export default Survey;