import React, {Component, Fragment} from 'react';
import {Card, Icon, Header, Button, Modal, Form, Dimmer, Loader} from 'semantic-ui-react';
import CreateCourse from './CreateCourse';
import CreateSite from './CreateSite';
import UpdateSite from './UpdateSite';
import uuid from 'uuid/v4';
import axios from 'axios';

class SiteDashboard extends Component{
    state = {
        sites: [],
        currentSite: {},
        facilitators: [],
        showFacilitatorModal: false,
        showCourseModal: false,
        showSiteModal: false,
        firstName: '',
        lastName: '',
        loading: true,
        showSiteDetailModal: false,
        reFetch: false,
        currentOrg: {}
    }

    fetchSites = () => {
        const siteUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/sites'
        const profileUrl = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/account'
        const facilitatorURL = 'https://zyf7169rj6.execute-api.us-east-1.amazonaws.com/prod/admin/facilitators'
        const config = {
            headers: {
                "Authorization": `Bearer ${this.props.token}`
            }
        }

        function getProfile() {
            return axios.get(profileUrl, config);
        }

        function getSites(){
            return axios.get(siteUrl, config);
        }

        axios.all([getProfile(), getSites()]).then( axios.spread( (Profile, Sites) => {
            const sites = Sites && Sites.data && Sites.data.Items
            const profile = Profile && Profile.data  && Profile.data.Item;
            const filteredSites = sites.filter( (value) => {
                return value.deleted != true
            })
            return axios.get(facilitatorURL, config).then( res2 => {
                this.setState({
                    sites: filteredSites,
                    facilitators: res2.data.Items,
                    loading: false,
                    reFetch: false,
                    currentOrg: profile ? {orgName: profile.name, orgId: profile.id} : {}
                })
            })
        }))
    }

    componentDidMount(){
        this.fetchSites();
    }

    componentDidUpdate(){
        if (this.state.reFetch) {
            this.fetchSites();
        }
    }

    toggleCourseModal = (site) => {
        this.fetchSites();
        if (site) {
            this.setState({
                showCourseModal: !this.state.showCourseModal,
                currentSite: site
            })
        } else {
            this.setState({
                showCourseModal: !this.state.showCourseModal,
            })
        }
    }

    toggleSiteDetailModal = (site) => {
        if (site) {
            this.setState({
                showSiteDetailModal: !this.state.showSiteDetailModal,
                currentSite: site
            })
        } else {
            this.setState({
                showSiteDetailModal: !this.state.showSiteDetailModal,
                reFetch: true
            })
        }
    }

    toggleSiteModal = (refresh) => {
        if (refresh) {
            this.fetchSites();
        }
        this.setState({
            showSiteModal: !this.state.showSiteModal
        })
    }

    handleChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    selectedSiteDetail = () => {
        const detail = this.state.sites.find( (element) => {
        return element.SiteId === this.state.currentSite
        })
        if (detail) {
            return (
                <UpdateSite site={detail} closeModal={this.toggleSiteDetailModal} token={this.props.token}/>
            )
        } 
    }

    render(){
        return (
            <Fragment>
                <Dimmer active={this.state.loading} page>
                    <Loader/>
                </Dimmer>
                <Card.Group>
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {
                                    this.state.currentOrg.orgName ?
                                    `${this.state.currentOrg.orgName} Sites` :
                                    'Site Information'
                                }
                            </Card.Header>
                            <Card.Description>
                                Currently {this.state.sites.length} Sites
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button.Group size="tiny">
                                <Button primary onClick={this.toggleSiteModal} content="Create Site"/>
                            </Button.Group>
                        </Card.Content>
                    </Card>
                    { 
                        this.state.sites.sort( (a,b) => {
                            if (a['SiteName'].trim().toUpperCase() < b['SiteName'].trim().toUpperCase()) {
                                return -1
                            }
                            if (a['SiteName'].trim().toUpperCase() > b['SiteName'].trim().toUpperCase()) {
                                return 1
                            }
                            return 0
                            }
                            ).map( (site, index) => {
                        if (site) {
                            let courses = site.Courses && site.Courses.filter( (course) => {
                                return course.deleted != true
                            })
                        return (
                        <Card key={index} style={{textAlign: 'left'}}>
                            <Card.Content>
                                <Card.Header>
                                    {site.SiteName} <br/>
                                    {site.city}, {site.StateCode}
                                </Card.Header>
                                <Card.Description>
                                    <p>{courses ? courses && courses.length : 0} &nbsp; Programs</p>
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra style={{textAlign: 'center'}}>
                                <Button.Group size="tiny">
                                    <Button positive onClick={() => this.toggleCourseModal(site.SiteId)}>Add Program <Icon name="plus" style={{paddingLeft: '8px'}}/></Button>
                                    <Button color='blue' onClick={() => this.toggleSiteDetailModal(site.SiteId)}>Site Details <Icon name="info circle" style={{paddingLeft: '8px'}}/></Button>
                                </Button.Group>
                            </Card.Content>
                        </Card>
                        )
                        }
                    })
                    }
                </Card.Group>
                <Modal size="mini" open={this.state.showCourseModal} onClose={this.toggleCourseModal}>
                    <Header content="Add Program"/>
                    <Modal.Content>
                        <CreateCourse facilitators={this.state.facilitators} currentSite={this.state.currentSite} closeModal={this.toggleCourseModal} token={this.props.token}/>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showSiteModal} onClose={this.toggleSiteModal}>
                    <Header content="Add Site"/>
                    <Modal.Content>
                        <CreateSite closeModal={this.toggleSiteModal} token={this.props.token} role={this.props.role}/>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.showSiteDetailModal} onClose={this.toggleSiteDetailModal}>
                    <Header content="Site Detail"/>
                    <Modal.Content>
                        {this.selectedSiteDetail()}
                    </Modal.Content>
                </Modal>

            </Fragment>
        )
    }
}

export default SiteDashboard;